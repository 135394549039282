import loginService from '@/services/loginService'
import Cookies from 'js-cookie'

const user = Cookies.get('_userAuth_') || null

export const AuthUser = {
  state: {
    user: user,
    userInfo: user ? user.user : null,
    token: user ? user.token : null,
    status: user ? { loggedIn: true } : null,
    roles: ['ROLE_ADMIN']
  },
  mutations: {
    SET_USER: (state, user) => {
      // state.user = user.authUser
      state.userInfo = user.authUser ? user.authUser.user : null
      state.status = user.authUser ? { loggedIn: true } : null
      state.token = user.authUser ? user.authUser.token : null
    },
    SET_USER_TOKEN: (state, userToken) => {
      state.token = userToken
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    }
  },
  actions: {
    SetAuthUser ({ commit }, data) {
      commit('SET_USER', data)
    },
    setAuthToken ({ commit }, data) {
      commit('SET_USER_TOKEN', data)
    },
    getAuthToken (state) {
      return state.userToken
    },
    setAuthInfo ({ commit }, data) {
      commit('SET_USER_INFO', data.userInfo)
    },
    getUserInfo (state) {
      if (state.userInfo) {
        return state.userInfo
      } else {
        return loginService.getUserData()
      }
    }
  }
}

export default AuthUser
