<template>
  <div id="main-wrapper">
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key"/>
      </transition>
  </div>
</template>

<script>
export default {
  name: 'LayoutError',
  computed: {
    key () {
      return this.$route.fullPath
    }
  }
}
</script>
