import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const ReceiptRouter = {
  path: '/receipt',
  component: Layout,
  children: [
    {
      path: 'generate',
      name: 'ReceiptGenerateIndex',
      component: () => import('@/views/Invoice/Receipt/List'),
      hidden: true,
      meta: {
        titleHeader: false,
        titleText: i18n.t('invoice.receipt.generate-receipt-title')
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default ReceiptRouter
