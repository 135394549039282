/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const RequestAccessRouter = {
  path: '/request-access',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'RequestAccessIndex',
      component: () => import('@/views/RequestAccess/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('form.request-access.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('form.request-access.title'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default RequestAccessRouter
