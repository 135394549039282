import LoginLayout from '@/views/Auth/_LoginLayout'

const FirstLogin = {
  path: '/changePassword/',
  component: LoginLayout,
  children: [
    {
      path: '/',
      name: 'FirstLogin',
      component: function (resolve) { require(['@/views/Auth/FirstLogin.vue'], resolve) },
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'Atualizar a senha'
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default FirstLogin
