<template>
  <div :class="rootClassName">
    <slot name="before">
    </slot>

    <label :class="{'active': this.value}" v-if="label && inputClassName === 'md-form-control'">{{ label }}</label>
    <label :class="{'active': this.value}" v-if="label && inputClassName !== 'md-form-control'">{{ label }}</label>

    <input v-if="!isView" :type="type" :name="name" :value="value"  :maxlength="maxLength" :ref="refName" v-mask="vMask" :placeholder="placeholder" @input="handleInput" @change="handleInput" @blur="$emit('blur')"
    :disabled="disabled" :readonly="readonly" :class="[{ 'royalc-error-input': error }, inputClassName]" style="padding-left:5px">
    <div v-else>{{ value }}</div>

    <div class="helper-text" v-if="helperText">
      <i class="ft-alert-circle"></i> <small>{{ helperText }}</small>
    </div>
    <transition name="slide" mode="">
      <small v-if="error" class="royalc-error-field">{{ error }}</small>
    </transition>

    <slot name="after"></slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'CustonInput',
  data () {
    return {
      refName: this.refInput || 'field_' + Math.random().toString().substr(2),
      activeLabel: false
    }
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    isView: {
      type: Boolean,
      required: false,
      default: false
    },
    indicator: {
      type: Object,
      default: null
    },
    goals: {
      type: Array,
      default: null
    },
    updateValue: {
      type: Function,
      default: null
    },
    refInput: String,
    name: String,
    value: {
      type: null,
      default: null
    },
    maxLength: {
      type: Number,
      default: null
    },
    vMask: {
      type: null,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    helperText: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator: val => {
        return (
          ['url', 'text', 'password', 'email', 'search', 'number'].indexOf(val) !== -1
        )
      }
    },
    rootClassName: {
      type: String,
      default: 'form-group mb-0'
    },
    inputClassName: {
      type: String,
      default: 'form-control'
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value.trim())
      if (this.updateValue !== null) {
        this.updateValue(this.indicator, this.goals)
      }
    }
  }
}
</script>

<style scoped>
.label-tooltip {
  width: 50px;
  border: 1px solid #f0f;
  right: 0 !important;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}

.no-margin {
  margin: 0 !important;
  border: 0;
}

input:disabled {
  cursor: not-allowed;
}
</style>
