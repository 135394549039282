<template>
  <div class="page-content fade-in-up">
    <!-- BEGIN: Page heading-->
    <div class="page-heading">
      <h1 class="page-title page-title-sep" v-if="this.$route.meta.titleHeader">{{ PageTitle }}</h1>
      <breadcrumbs :items="$route.meta.breadcrumbs" :showBreadcrumbHome="$route.meta.showBreadcrumbHome" />
    </div>
    <!-- END: Page heading-->

    <!-- BEGIN: Section Menu -->
    <SectionMenu :items="$route.meta.sectionMenu" />
    <!-- END: Section Menu -->

    <!-- BEGIN: Page content-->
    <div>
      <router-view :key="key"></router-view>
    </div>
    <!-- END: Page content-->
  </div>
</template>

<script>
import Breadcrumbs from '@/views/_shared/_Breadcrumbs.vue'
import SectionMenu from '@/views/_shared/_SectionMenu.vue'

export default {
  name: 'RouterRender',
  computed: {
    key () {
      return Math.random().toString().substr(2)
    },
    PageTitle () {
      return this.$route.meta.titleText
    }
  },
  components: {
    Breadcrumbs,
    SectionMenu
  }
}
</script>

<style>
.page-content {
  padding: 0;
  margin: 0;
}
</style>
