/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'
const CompanyRouter = {
  path: '/company/',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'CompanyList',
      component: () => import('@/views/Company/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: '',
        breadcrumbs: [
          { text: i18n.t('dashboard.title'), disabled: false, href: 'Dashboard' },
          { text: i18n.t('company.title'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default CompanyRouter
