/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import LoginLayout from '@/views/Auth/_LoginLayout'

const TermsRouter = {
  path: '/terms',
  component: LoginLayout,
  children: [
    {
      path: ':type',
      name: 'TermView',
      component: function (resolve) { require(['@/views/Terms/View.vue'], resolve) },
      hidden: true
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default TermsRouter
