/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const MailRouter = {
  path: '/mails',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'MailIndex',
      component: () => import('@/views/Mail/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('mails.title'), disabled: true }
        ]
      }
    },
    {
      path: ':id_mail/log',
      name: 'MailLog',
      component: () => import('@/views/Mail/Log'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          { text: i18n.t('mails.title'), disabled: false, href: 'MailIndex' },
          { text: i18n.t('actions.log'), disabled: true }
        ]
      }
    },
    {
      path: '/template',
      name: 'MailTemplate',
      component: () => import('@/views/Mail/Template'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          { text: i18n.t('mails.title'), disabled: false, href: 'MailIndex' },
          { text: i18n.t('sidebar.mail-template'), disabled: true }
        ]
      }
    },
    {
      path: '/template/:id_template/edit',
      name: 'MailTemplateEdit',
      component: () => import('@/views/Mail/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          { text: i18n.t('sidebar.mail-template'), disabled: true }
        ]
      }
    },
    {
      path: '/template/new',
      name: 'MailTemplateNew',
      component: () => import('@/views/Mail/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          { text: i18n.t('sidebar.mail-template'), disabled: true }
        ]
      }
    },
    {
      path: '/automatic',
      name: 'MailAutomaticIndex',
      component: () => import('@/views/Mail/Automatic/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('mails.title'), disabled: true }
        ]
      }
    },
    {
      path: '/automatic/new',
      name: 'AutomaticNew',
      component: () => import('@/views/Mail/Automatic/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('mails.title'), disabled: true }
        ]
      }
    },
    {
      path: '/automatic/:automatic_mail_id/edit',
      name: 'AutomaticEdit',
      component: () => import('@/views/Mail/Automatic/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('mails.title'), disabled: true }
        ]
      }
    },
    {
      path: 'v2',
      name: 'MailV2Index',
      component: () => import('@/views/MailV2/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('mails.title'), disabled: true }
        ]
      }
    },
    {
      path: 'v2/:id/detail',
      name: 'MailV2Detail',
      component: () => import('@/views/MailV2/Details'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('mails.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('mails.title'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default MailRouter
