import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const CompanyService = {
  getCompanies (filter, page, itemsPerPage) {
    filter.page = page
    filter.per_page = itemsPerPage

    let queryString = httpBuildQuery(filter)
    page = page || 1
    itemsPerPage = itemsPerPage || 5
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customers?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getCompanyUsers (idCompany, page, itemsPerPage) {
    page = page || 1
    itemsPerPage = itemsPerPage || 5
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${idCompany}/users?page=${page}&per_page=${itemsPerPage}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  setCompany (idCompany, dataContent) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/customer/save/${idCompany}`, dataContent).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  setCompanyUser (idCompany, idUser, dataContent) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/customer/${idCompany}/user/save/${idUser}`, dataContent).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  setCompanyUserPassword (idUser, dataContent) {
    // /api/{version}/user/{id}/password
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/user/${idUser}/password`, dataContent).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default CompanyService
