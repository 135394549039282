<template>
  <div class="page-wrapper" :class="{'isDemo': showDemoSection}">
    <div class="top-alert-bar" v-if="showDemoSection">
      <div class="alert alert-warning text-center" role="alert">
        <i class="fa fa-info-circle"></i>
        {{ $t('Atenção! Este é um ambiente de demonstração') }}
      </div>
    </div>
    <div class="content-wrapper">
      <aside-menu />
      <div class="content-area">
        <page-header />
        <cupom-alerts />
        <router-render :key="key" />
        <page-footer />
      </div>
    </div>
    <modals-container/>
  </div>
</template>

<script>
// import Vue from 'vue'
import RouterRender from '@/views/_shared/_RouterRender.vue'
import PageHeader from './_PageHeader'
import AsideMenu from './_AsideMenu'
// import Breadcrumbs from '@/views/_shared/_Breadcrumbs.vue'

// import UserProfile from '@/views/User/UserProfile.vue'
import PageFooter from './_PageFooter'
// import VModal from 'vue-js-modal'

// Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })

import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/assets/vendor/feather-icons/feather.css'
import '@/assets/vendor/themify-icons/themify-icons.css'
import '@/assets/vendor/line-awesome/css/line-awesome.min.css'
import '@/assets/css/icons/rc-icons/style.css'
// import '@/assets/css/fonts/stylesheet.css'
import '@/assets/css/app.css'
import '@/assets/vendor/icons/icons-style.css'
import '@/assets/css/custom.css'
import '@/assets/css/royal-cargo.css'

import '@/assets/js/app.js'
import CupomAlerts from '@/components/CupomAlerts'

export default {
  name: 'Layout',
  data () {
    return {
    }
  },
  metaInfo: {
    title: 'Portal - Royal Cargo do Brasil'
  },
  computed: {
    key () {
      return Math.random().toString().substr(2)
    },
    PageTitle () {
      return this.$route.meta.titleText
    },
    showDemoSection () {
      return process.env.VUE_APP_DEMO || false
    }
  },
  components: {
    CupomAlerts,
    RouterRender,
    PageHeader,
    AsideMenu,
    PageFooter
  },
  beforeMount: function () {
    document.querySelector('body').className = 'mini-sidebar'
    this.$store.dispatch('getUserInfo')
  }// ,
  // methods: {
  //   show_Modal () {
  //     this.$modal.show(UserProfile, {
  //       draggable: false
  //     }, {
  //       width: '95%',
  //       height: '95%'
  //     }, {
  //       adaptive: true
  //     }, {
  //       scrollable: true
  //     })
  //   }
  // },
  // mounted: function () {
  //   this.$nextTick(function () {
  //     setTimeout(() => {
  //       this.show_Modal()
  //     }, 3000)
  //   })
  // }
}
</script>
