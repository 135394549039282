/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const ReportRouter = {
  path: '/report/',
  component: Layout,
  children: [
    {
      path: 'portal-access',
      name: 'ReportPortalAccess',
      component: () => import('@/views/Report/PortalAccess/Report'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.bonus-status.title'),
        breadcrumbs: [
          { text: i18n.t('report.bonus-status.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'bonus-status',
      name: 'ReportBonusStatus',
      component: () => import('@/views/Report/BonusStatus/StatusReport'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.bonus-status.title'),
        breadcrumbs: [
          { text: i18n.t('report.bonus-status.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'indicators/operational',
      name: 'ReportIndicatorsOperational',
      component: () => import('@/views/Report/Indicators/Operational'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.indicators.sections.operational'),
        breadcrumbs: [
          { text: i18n.t('report.indicators.sections.operational'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.indicators.sections.operational'), href: 'ReportIndicatorsOperational', active: true },
          { text: i18n.t('report.indicators.sections.commercial'), href: 'ReportIndicatorsCommercial', active: false },
          { text: i18n.t('report.indicators.sections.financial'), href: 'ReportIndicatorsFinancial', active: false },
          { text: i18n.t('report.indicators.sections.it'), href: 'ReportIndicatorsIt', active: false },
          { text: i18n.t('report.indicators.sections.Talent & Culture'), href: 'ReportIndicatorsTalentCulture', active: false },
          { text: i18n.t('report.indicators.sections.endomarketing'), href: 'ReportIndicatorsEndomarketing', active: false }
        ]
      }
    },
    {
      path: 'indicators/commercial',
      name: 'ReportIndicatorsCommercial',
      component: () => import('@/views/Report/Indicators/Commercial'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.indicators.sections.commercial'),
        breadcrumbs: [
          { text: i18n.t('report.indicators.sections.commercial'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.indicators.sections.operational'), href: 'ReportIndicatorsOperational', active: false },
          { text: i18n.t('report.indicators.sections.commercial'), href: 'ReportIndicatorsCommercial', active: true },
          { text: i18n.t('report.indicators.sections.financial'), href: 'ReportIndicatorsFinancial', active: false },
          { text: i18n.t('report.indicators.sections.it'), href: 'ReportIndicatorsIt', active: false },
          { text: i18n.t('report.indicators.sections.Talent & Culture'), href: 'ReportIndicatorsTalentCulture', active: false },
          { text: i18n.t('report.indicators.sections.endomarketing'), href: 'ReportIndicatorsEndomarketing', active: false }
        ]
      }
    },
    {
      path: 'indicators/financial',
      name: 'ReportIndicatorsFinancial',
      component: () => import('@/views/Report/Indicators/Financial'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.indicators.sections.financial'),
        breadcrumbs: [
          { text: i18n.t('report.indicators.sections.financial'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.indicators.sections.operational'), href: 'ReportIndicatorsOperational', active: false },
          { text: i18n.t('report.indicators.sections.commercial'), href: 'ReportIndicatorsCommercial', active: false },
          { text: i18n.t('report.indicators.sections.financial'), href: 'ReportIndicatorsFinancial', active: true },
          { text: i18n.t('report.indicators.sections.it'), href: 'ReportIndicatorsIt', active: false },
          { text: i18n.t('report.indicators.sections.Talent & Culture'), href: 'ReportIndicatorsTalentCulture', active: false },
          { text: i18n.t('report.indicators.sections.endomarketing'), href: 'ReportIndicatorsEndomarketing', active: false }
        ]
      }
    },
    {
      path: 'indicators/it',
      name: 'ReportIndicatorsIt',
      component: () => import('@/views/Report/Indicators/It'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.indicators.sections.it'),
        breadcrumbs: [
          { text: i18n.t('report.indicators.sections.it'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.indicators.sections.operational'), href: 'ReportIndicatorsOperational', active: false },
          { text: i18n.t('report.indicators.sections.commercial'), href: 'ReportIndicatorsCommercial', active: false },
          { text: i18n.t('report.indicators.sections.financial'), href: 'ReportIndicatorsFinancial', active: false },
          { text: i18n.t('report.indicators.sections.it'), href: 'ReportIndicatorsIt', active: true },
          { text: i18n.t('report.indicators.sections.Talent & Culture'), href: 'ReportIndicatorsTalentCulture', active: false },
          { text: i18n.t('report.indicators.sections.endomarketing'), href: 'ReportIndicatorsEndomarketing', active: false }
        ]
      }
    },
    {
      path: 'indicators/talent-culture',
      name: 'ReportIndicatorsTalentCulture',
      component: () => import('@/views/Report/Indicators/TalentCulture'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.indicators.sections.Talent & Culture'),
        breadcrumbs: [
          { text: i18n.t('report.indicators.sections.Talent & Culture'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.indicators.sections.operational'), href: 'ReportIndicatorsOperational', active: false },
          { text: i18n.t('report.indicators.sections.commercial'), href: 'ReportIndicatorsCommercial', active: false },
          { text: i18n.t('report.indicators.sections.financial'), href: 'ReportIndicatorsFinancial', active: false },
          { text: i18n.t('report.indicators.sections.it'), href: 'ReportIndicatorsIt', active: false },
          { text: i18n.t('report.indicators.sections.Talent & Culture'), href: 'ReportIndicatorsTalentCulture', active: true },
          { text: i18n.t('report.indicators.sections.endomarketing'), href: 'ReportIndicatorsEndomarketing', active: false }
        ]
      }
    },
    {
      path: 'indicators/endomarketing',
      name: 'ReportIndicatorsEndomarketing',
      component: () => import('@/views/Report/Indicators/Endomarketing'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.indicators.sections.endomarketing'),
        breadcrumbs: [
          { text: i18n.t('report.indicators.sections.endomarketing'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.indicators.sections.operational'), href: 'ReportIndicatorsOperational', active: false },
          { text: i18n.t('report.indicators.sections.commercial'), href: 'ReportIndicatorsCommercial', active: false },
          { text: i18n.t('report.indicators.sections.financial'), href: 'ReportIndicatorsFinancial', active: false },
          { text: i18n.t('report.indicators.sections.it'), href: 'ReportIndicatorsIt', active: false },
          { text: i18n.t('report.indicators.sections.Talent & Culture'), href: 'ReportIndicatorsTalentCulture', active: false },
          { text: i18n.t('report.indicators.sections.endomarketing'), href: 'ReportIndicatorsEndomarketing', active: true }
        ]
      }
    },
    // Begin Alertas - Routing Order
    {
      path: 'alertas-routing-order/processos-sem-remessa-agente',
      name: 'ReportAlertsProcessosROSemRemessaAgente',
      component: () => import('@/views/Report/Alerts/RoutingOrder/ProcessosSemRemessaAgente/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.routing-order.processos_sem_remessa_agente'), active: true },
          { text: i18n.t('report.alerts.routing-order.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosROTaxasRemessaAgenteSemFaturamento', active: false }
        ]
      }
    },
    {
      path: 'alertas-routing-order/processos-taxas-remessa-agente-sem-faturamento',
      name: 'ReportAlertsProcessosROTaxasRemessaAgenteSemFaturamento',
      component: () => import('@/views/Report/Alerts/RoutingOrder/ProcessosTaxasRemessaAgenteSemFaturamento/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.routing-order.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosROSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.routing-order.processos_taxas_remessa_agente_sem_faturamento'), active: true }
        ]
      }
    },
    //  Begin Alertas - Operacionais
    {
      path: 'alertas-operacionais/processos-pendencia-seguro',
      name: 'ReportAlertsProcessosPendenciaSeguro',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosPendenciaSeguro/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), active: true },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-ce-pendente',
      name: 'ReportAlertsProcessosCePendente',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosCePendente/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), active: true },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-hbl-prepaid-vat',
      name: 'ReportAlertsProcessosHblPrepaidVat',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosHblPrepaidVat/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), active: true },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-pendencia-redestinacao',
      name: 'ReportAlertsProcessosPendenciaRedestinacao',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosPendenciaRedestinacao/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), active: true },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-mbl-courier-nao-recebido',
      name: 'ReportAlertsProcessosMblCourierNaoRecebido',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosMblCourierNaoRecebido/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), active: true },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-thc-vazio',
      name: 'ReportAlertsProcessosThcVazio',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosThcVazio/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-atencao',
      name: 'ReportAlertsProcessosAtencao',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosAtencao/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-sem-arquivo',
      name: 'ReportAlertsProcessosSemArquivo',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosSemArquivo/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-sem-acompanhamento',
      name: 'ReportAlertsProcessosSemAcompanhamento',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosSemAcompanhamento/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-pendencia-taxas',
      name: 'ReportAlertsProcessosPendenciaTaxas',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosPendenciaTaxas/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), active: true },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-divergencia-debit-notes',
      name: 'ReportAlertsProcessosDivergenciaDebitNote',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosDivergenciaDebitNote/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-sem-remessa-agente',
      name: 'ReportAlertsProcessosSemRemessaAgente',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosSemRemessaAgente/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-taxas-remessa-agente-sem-faturamento',
      name: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosTaxasRemessaAgenteSemFaturamento/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), active: true },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-faturas-abertas-em-aereo',
      name: 'ReportAlertsProcessosFaturasAbertasEmAereo',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosFaturasAbertasEmAereo/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-criticos',
      name: 'ReportAlertsProcessosCriticos',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosCriticos/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-envio-cct',
      name: 'ReportAlertsProcessosEnvioCCT',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosEnvioCCT/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-embarque-desembarque-futuro',
      name: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosEmbarqueDesembarqueFuturo/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-sem-liberacao',
      name: 'ReportAlertsProcessosSemLiberacao',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosSemLiberacao/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-pendencia-mrcu',
      name: 'ReportAlertsProcessosPendenciaMrcu',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosPendenciaMRUC/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-pendentes',
      name: 'ReportAlertsProcessosPendentes',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosPendentes/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), active: true },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), href: 'ReportAlertsProcessosPendenciaMrcuFile', active: false }

        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-pendencia-mrcu-file',
      name: 'ReportAlertsProcessosPendenciaMrcuFile',
      component: () => import('@/views/Report/Alerts/Operacionais/ProcessosPendenciaMRUCFile/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_seguro'), href: 'ReportAlertsProcessosPendenciaSeguro', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_ce_pendente'), href: 'ReportAlertsProcessosCePendente', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_hbl_prepaid_vat'), href: 'ReportAlertsProcessosHblPrepaidVat', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_pendencia_redestinacao'), href: 'ReportAlertsProcessosPendenciaRedestinacao', active: false },
          { text: i18n.t('report.alerts.operacionais.mbl_courier_nao_recebido'), href: 'ReportAlertsProcessosMblCourierNaoRecebido', active: false },
          { text: i18n.t('report.alerts.operacionais.thc_vazio'), href: 'ReportAlertsProcessosThcVazio', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_atencao'), href: 'ReportAlertsProcessosAtencao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_arquivo'), href: 'ReportAlertsProcessosSemArquivo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_acompanhamento'), href: 'ReportAlertsProcessosSemAcompanhamento', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_taxas'), href: 'ReportAlertsProcessosPendenciaTaxas', active: false },
          { text: i18n.t('report.alerts.operacionais.aviso_divergencia_debit_note'), href: 'ReportAlertsProcessosDivergenciaDebitNote', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_remessa_agente'), href: 'ReportAlertsProcessosSemRemessaAgente', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_taxas_remessa_agente_sem_faturamento'), href: 'ReportAlertsProcessosTaxasRemessaAgenteSemFaturamento', active: false },
          { text: i18n.t('report.alerts.operacionais.faturas_abertas_em_aereo'), href: 'ReportAlertsProcessosFaturasAbertasEmAereo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_criticos'), href: 'ReportAlertsProcessosCriticos', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_envio_cct'), href: 'ReportAlertsProcessosEnvioCCT', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_embarque_desembarque_futuro'), href: 'ReportAlertsProcessosEmbarqueDesembarqueFuturo', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_sem_liberacao'), href: 'ReportAlertsProcessosSemLiberacao', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu'), href: 'ReportAlertsProcessosPendenciaMrcu', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendentes'), href: 'ReportAlertsProcessosPendentes', active: false },
          { text: i18n.t('report.alerts.operacionais.processos_pendencia_mrcu_file'), active: true }
        ]
      }
    },
    //  Begin Alertas - Controle de Deadlines
    {
      path: 'alertas-controle-dead-lines/processos-atraso-recebimento-draft',
      name: 'ReportAlertsProcessosAtrasoRecebimentoDraft',
      component: () => import('@/views/Report/Alerts/ControleDeadLines/ProcessosAtrasoRecebimentoDraft/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.controle_dead_lines.atraso_recebimento_draft'), active: true },
          { text: i18n.t('report.alerts.controle_dead_lines.atraso_envio_draft_armador'), href: 'ReportAlertsProcessosEnvioDraftArmador', active: false },
          { text: i18n.t('report.alerts.controle_dead_lines.envio_vgm'), href: 'ReportAlertsProcessosEnvioVgm', active: false }
        ]
      }
    },
    {
      path: 'alertas-controle-dead-lines/processos-atraso-envio-draft-armador',
      name: 'ReportAlertsProcessosEnvioDraftArmador',
      component: () => import('@/views/Report/Alerts/ControleDeadLines/ProcessosEnvioDraftArmador/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.controle_dead_lines.atraso_recebimento_draft'), href: 'ReportAlertsProcessosAtrasoRecebimentoDraft', active: false },
          { text: i18n.t('report.alerts.controle_dead_lines.atraso_envio_draft_armador'), active: true },
          { text: i18n.t('report.alerts.controle_dead_lines.envio_vgm'), href: 'ReportAlertsProcessosEnvioVgm', active: false }
        ]
      }
    },
    {
      path: 'alertas-controle-dead-lines/processos-envio-vgm',
      name: 'ReportAlertsProcessosEnvioVgm',
      component: () => import('@/views/Report/Alerts/ControleDeadLines/ProcessosEnvioVgm/List'),
      hidden: false,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.controle_dead_lines.atraso_recebimento_draft'), href: 'ReportAlertsProcessosAtrasoRecebimentoDraft', active: false },
          { text: i18n.t('report.alerts.controle_dead_lines.atraso_envio_draft_armador'), href: 'ReportAlertsProcessosEnvioDraftArmador', active: false },
          { text: i18n.t('report.alerts.controle_dead_lines.envio_vgm'), active: true }
        ]
      }
    },
    //  Begin Alertas - Comerciais
    {
      path: 'alertas-comerciais/free-time-compra-menor-venda',
      name: 'ReportAlertsProcessosFreeTimeCompraMenorVenda',
      component: () => import('@/views/Report/Alerts/Comerciais/ProcessosFreeTimeCompraMenorVenda/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.comerciais.free_time_compra_menor_venda'), active: true },
          { text: i18n.t('report.alerts.comerciais.sem_free_time_divergente'), href: 'ReportAlertsProcessosSemFreeTime', active: false },
          { text: i18n.t('report.alerts.comerciais.divergencia_taxas_locais'), href: 'ReportAlertsProcessosDivergenciaTaxasLocais', active: false },
          { text: i18n.t('report.alerts.comerciais.taxas_locais_sem_cobranca'), href: 'ReportAlertsProcessosTaxaClienteSemCobranca', active: false },
          { text: i18n.t('report.alerts.comerciais.solicitacoes_revisao_is_pendente'), href: 'ReportAlertsSolicitacoesRevisaoISPendente', active: false },
          { text: i18n.t('report.alerts.comerciais.processos_cmf_frete_prejuizo'), href: 'ReportAlertsProcessosCMFFretePrejuizo', active: false }
        ]
      }
    },
    {
      path: 'alertas-comerciais/sem-free-time',
      name: 'ReportAlertsProcessosSemFreeTime',
      component: () => import('@/views/Report/Alerts/Comerciais/ProcessosSemFreeTime/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.comerciais.free_time_compra_menor_venda'), href: 'ReportAlertsProcessosFreeTimeCompraMenorVenda', active: false },
          { text: i18n.t('report.alerts.comerciais.sem_free_time_divergente'), active: true },
          { text: i18n.t('report.alerts.comerciais.divergencia_taxas_locais'), href: 'ReportAlertsProcessosDivergenciaTaxasLocais', active: false },
          { text: i18n.t('report.alerts.comerciais.taxas_locais_sem_cobranca'), href: 'ReportAlertsProcessosTaxaClienteSemCobranca', active: false },
          { text: i18n.t('report.alerts.comerciais.solicitacoes_revisao_is_pendente'), href: 'ReportAlertsSolicitacoesRevisaoISPendente', active: false },
          { text: i18n.t('report.alerts.comerciais.processos_cmf_frete_prejuizo'), href: 'ReportAlertsProcessosCMFFretePrejuizo', active: false }
        ]
      }
    },
    {
      path: 'alertas-comerciais/divergencia-taxas-locais',
      name: 'ReportAlertsProcessosDivergenciaTaxasLocais',
      component: () => import('@/views/Report/Alerts/Comerciais/ProcessosDivergenciaTaxasLocais/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.comerciais.free_time_compra_menor_venda'), href: 'ReportAlertsProcessosFreeTimeCompraMenorVenda', active: false },
          { text: i18n.t('report.alerts.comerciais.sem_free_time_divergente'), href: 'ReportAlertsProcessosSemFreeTime', active: false },
          { text: i18n.t('report.alerts.comerciais.divergencia_taxas_locais'), active: true },
          { text: i18n.t('report.alerts.comerciais.taxas_locais_sem_cobranca'), href: 'ReportAlertsProcessosTaxaClienteSemCobranca', active: false },
          { text: i18n.t('report.alerts.comerciais.solicitacoes_revisao_is_pendente'), href: 'ReportAlertsSolicitacoesRevisaoISPendente', active: false },
          { text: i18n.t('report.alerts.comerciais.processos_cmf_frete_prejuizo'), href: 'ReportAlertsProcessosCMFFretePrejuizo', active: false }
        ]
      }
    },
    {
      path: 'alertas-comerciais/taxa-cliente-sem-cobranca',
      name: 'ReportAlertsProcessosTaxaClienteSemCobranca',
      component: () => import('@/views/Report/Alerts/Comerciais/ProcessosTaxaClienteSemCobranca/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.comerciais.free_time_compra_menor_venda'), href: 'ReportAlertsProcessosFreeTimeCompraMenorVenda', active: false },
          { text: i18n.t('report.alerts.comerciais.sem_free_time_divergente'), href: 'ReportAlertsProcessosSemFreeTime', active: false },
          { text: i18n.t('report.alerts.comerciais.divergencia_taxas_locais'), href: 'ReportAlertsProcessosDivergenciaTaxasLocais', active: false },
          { text: i18n.t('report.alerts.comerciais.taxas_locais_sem_cobranca'), active: true },
          { text: i18n.t('report.alerts.comerciais.solicitacoes_revisao_is_pendente'), href: 'ReportAlertsSolicitacoesRevisaoISPendente', active: false },
          { text: i18n.t('report.alerts.comerciais.processos_cmf_frete_prejuizo'), href: 'ReportAlertsProcessosCMFFretePrejuizo', active: false }
        ]
      }
    },
    {
      path: 'alertas-comerciais/solicitacoes-revisao-is-pendente',
      name: 'ReportAlertsSolicitacoesRevisaoISPendente',
      component: () => import('@/views/Report/Alerts/Comerciais/SolicitacoesRevisaoISPendente/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.comerciais.free_time_compra_menor_venda'), href: 'ReportAlertsProcessosFreeTimeCompraMenorVenda', active: false },
          { text: i18n.t('report.alerts.comerciais.sem_free_time_divergente'), href: 'ReportAlertsProcessosSemFreeTime', active: false },
          { text: i18n.t('report.alerts.comerciais.divergencia_taxas_locais'), href: 'ReportAlertsProcessosDivergenciaTaxasLocais', active: false },
          { text: i18n.t('report.alerts.comerciais.taxas_locais_sem_cobranca'), href: 'ReportAlertsProcessosTaxaClienteSemCobranca', active: false },
          { text: i18n.t('report.alerts.comerciais.solicitacoes_revisao_is_pendente'), active: true },
          { text: i18n.t('report.alerts.comerciais.processos_cmf_frete_prejuizo'), href: 'ReportAlertsProcessosCMFFretePrejuizo', active: false }
        ]
      }
    },
    {
      path: 'alertas-operacionais/processos-cmf-frete-prejuizo',
      name: 'ReportAlertsProcessosCMFFretePrejuizo',
      component: () => import('@/views/Report/Alerts/Comerciais/ProcessosCMFFretePrejuizo/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.comerciais.free_time_compra_menor_venda'), href: 'ReportAlertsProcessosFreeTimeCompraMenorVenda', active: false },
          { text: i18n.t('report.alerts.comerciais.sem_free_time_divergente'), href: 'ReportAlertsProcessosSemFreeTime', active: false },
          { text: i18n.t('report.alerts.comerciais.divergencia_taxas_locais'), href: 'ReportAlertsProcessosDivergenciaTaxasLocais', active: false },
          { text: i18n.t('report.alerts.comerciais.taxas_locais_sem_cobranca'), href: 'ReportAlertsProcessosTaxaClienteSemCobranca', active: false },
          { text: i18n.t('report.alerts.comerciais.solicitacoes_revisao_is_pendente'), href: 'ReportAlertsSolicitacoesRevisaoISPendente', active: false },
          { text: i18n.t('report.alerts.comerciais.processos_cmf_frete_prejuizo'), active: true }
        ]
      }
    },
    //  Begin Alertas - Contratos
    {
      path: 'alertas-contratos/processos-x-contratos',
      name: 'ReportAlertsContratosProcessosXContratos',
      component: () => import('@/views/Report/Alerts/Contratos/ProcessosXContratos/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.contratos.processos-x-contratos'), active: true }
        ]
      }
    },
    //  Begin Alertas - Pricing
    {
      path: 'alertas-pricing/solicitacoes-pendentes',
      name: 'ReportAlertsPricingSolicitacoesPendentes',
      component: () => import('@/views/Report/Alerts/Pricing/SolicitacoesPendentes/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.pricing.solicitacoes_pendentes'), active: true },
          { text: i18n.t('report.alerts.pricing.solicitacoes_pendentes_sem_data_solicitacao'), href: 'ReportAlertsPricingSolicitacoesPendentesSemData', active: false }
        ]
      }
    },
    {
      path: 'alertas-pricing/solicitacoes-pendentes-sem-data',
      name: 'ReportAlertsPricingSolicitacoesPendentesSemData',
      component: () => import('@/views/Report/Alerts/Pricing/SolicitacoesPendentesSemData/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.pricing.solicitacoes_pendentes'), href: 'ReportAlertsPricingSolicitacoesPendentes', active: false },
          { text: i18n.t('report.alerts.pricing.solicitacoes_pendentes_sem_data_solicitacao'), active: true }
        ]
      }
    },
    //  Begin Alertas - Financeiros
    {
      path: 'alertas-financeiros/demurrage',
      name: 'ReportAlertsProcessosDemurrage',
      component: () => import('@/views/Report/Alerts/Financeiros/ProcessosDemurrage/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.financeiros.demurrage'), active: true },
          { text: i18n.t('report.alerts.financeiros.prejuizo'), href: 'ReportAlertsProcessosPrejuizo', active: false },
          { text: i18n.t('report.alerts.financeiros.faturas_abertas_armador'), href: 'ReportAlertsProcessosFaturasAbertasArmador', active: false },
          { text: i18n.t('report.alerts.financeiros.iof_zerado'), href: 'ReportAlertsProcessosIOFZerado', active: false }
        ]
      }
    },
    {
      path: 'alertas-financeiros/prejuizo',
      name: 'ReportAlertsProcessosPrejuizo',
      component: () => import('@/views/Report/Alerts/Financeiros/ProcessosPrejuizo/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.financeiros.demurrage'), href: 'ReportAlertsProcessosDemurrage', active: false },
          { text: i18n.t('report.alerts.financeiros.prejuizo'), active: true },
          { text: i18n.t('report.alerts.financeiros.faturas_abertas_armador'), href: 'ReportAlertsProcessosFaturasAbertasArmador', active: false },
          { text: i18n.t('report.alerts.financeiros.iof_zerado'), href: 'ReportAlertsProcessosIOFZerado', active: false }
        ]
      }
    },
    {
      path: 'alertas-financeiros/faturas-abertas-armador',
      name: 'ReportAlertsProcessosFaturasAbertasArmador',
      component: () => import('@/views/Report/Alerts/Financeiros/ProcessosFaturasAbertasArmador/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.financeiros.demurrage'), href: 'ReportAlertsProcessosDemurrage', active: false },
          { text: i18n.t('report.alerts.financeiros.prejuizo'), href: 'ReportAlertsProcessosPrejuizo', active: false },
          { text: i18n.t('report.alerts.financeiros.faturas_abertas_armador'), active: true },
          { text: i18n.t('report.alerts.financeiros.iof_zerado'), href: 'ReportAlertsProcessosIOFZerado', active: false }
        ]
      }
    },
    {
      path: 'alertas-financeiros/iof-zerado',
      name: 'ReportAlertsProcessosIOFZerado',
      component: () => import('@/views/Report/Alerts/Financeiros/ProcessosIOFZerado/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alerts.financeiros.demurrage'), href: 'ReportAlertsProcessosDemurrage', active: false },
          { text: i18n.t('report.alerts.financeiros.prejuizo'), href: 'ReportAlertsProcessosPrejuizo', active: false },
          { text: i18n.t('report.alerts.financeiros.faturas_abertas_armador'), href: 'ReportAlertsProcessosFaturasAbertasArmador', active: false },
          { text: i18n.t('report.alerts.financeiros.iof_zerado'), active: true }
        ]
      }
    },
    //  End Alerts
    {
      path: 'seller',
      name: 'ReportSeller',
      component: () => import('@/views/Report/Seller/Quarter'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.seller.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'seller-2022',
      name: 'ReportSeller2022',
      component: () => import('@/views/Report/Seller/2022'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.seller.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'statement/commercial/weekly',
      name: 'ReportStatementCommercialWeekly',
      component: () => import('@/views/Report/Statement/Commercial/Weekly'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.statement.commercial.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'statement/financial/weekly',
      name: 'ReportStatementFinancialWeekly',
      component: () => import('@/views/Report/Statement/Financial/Weekly'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.statement.financial.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'Kpi/Internal',
      name: 'ReportInternal',
      component: () => import('@/views/Report/Kpi/Internal'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.internal.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'seller/process/opened',
      name: 'ReportSellerProcessOpened',
      component: () => import('@/views/Report/Seller/ProcessOpened'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.report_comercial.opened processes RO'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.report_comercial.opened processes RO'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'seller/planning/week',
      name: 'ReportSellerInsight',
      component: () => import('@/views/Report/Seller/Insight'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.report_comercial.insight'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.report_comercial.insight'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'system/usage',
      name: 'ReportSystemUsage',
      component: () => import('@/views/Report/System/Usage'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.report_system.title'),
        breadcrumbs: [
          { text: i18n.t('report.report_system.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'proposal/report',
      name: 'ReportProposal',
      component: () => import('@/views/Report/Proposal/Report'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.proposal.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'tariff/scheduleusage-sent',
      name: 'ReportTariffScheduleUsageSent',
      component: () => import('@/views/Report/Tariffs/ScheduleUsageSent'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.tariff_indicators.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.tariff_indicators.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.tariff_indicators.short-sent'), active: true },
          { text: i18n.t('report.tariff_indicators.short-sent-by-seller'), href: 'ReportTariffScheduleUsageSentBySeller', active: false },
          { text: i18n.t('report.tariff_indicators.short-sent-by-user'), href: 'ReportTariffScheduleUsageSentByUser', active: false },
          { text: i18n.t('report.tariff_indicators.short-not-sent-by-values'), href: 'ReportTariffScheduleUsageNotSentValues', active: false }
        ]
      }
    },
    {
      path: 'tariff/scheduleusage-sent-seller',
      name: 'ReportTariffScheduleUsageSentBySeller',
      component: () => import('@/views/Report/Tariffs/ScheduleUsageSentBySeller'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.tariff_indicators.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.tariff_indicators.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.tariff_indicators.short-sent'), href: 'ReportTariffScheduleUsageSent', active: false },
          { text: i18n.t('report.tariff_indicators.short-sent-by-seller'), active: true },
          { text: i18n.t('report.tariff_indicators.short-sent-by-user'), href: 'ReportTariffScheduleUsageSentByUser', active: false },
          { text: i18n.t('report.tariff_indicators.short-not-sent-by-values'), href: 'ReportTariffScheduleUsageNotSentValues', active: false }
        ]
      }
    },
    {
      path: 'tariff/scheduleusage-sent-user',
      name: 'ReportTariffScheduleUsageSentByUser',
      component: () => import('@/views/Report/Tariffs/ScheduleUsageSentByUser'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.tariff_indicators.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.tariff_indicators.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.tariff_indicators.short-sent'), href: 'ReportTariffScheduleUsageSent', active: false },
          { text: i18n.t('report.tariff_indicators.short-sent-by-seller'), href: 'ReportTariffScheduleUsageSentBySeller', active: false },
          { text: i18n.t('report.tariff_indicators.short-sent-by-user'), active: true },
          { text: i18n.t('report.tariff_indicators.short-not-sent-by-values'), href: 'ReportTariffScheduleUsageNotSentValues', active: false }
        ]
      }
    },
    {
      path: 'tariff/scheduleusage-not-sent',
      name: 'ReportTariffScheduleUsageNotSentValues',
      component: () => import('@/views/Report/Tariffs/ScheduleUsageNotSentValues'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.tariff_indicators.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.tariff_indicators.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.tariff_indicators.short-sent'), href: 'ReportTariffScheduleUsageSent', active: false },
          { text: i18n.t('report.tariff_indicators.short-sent-by-seller'), href: 'ReportTariffScheduleUsageSentBySeller', active: false },
          { text: i18n.t('report.tariff_indicators.short-sent-by-user'), href: 'ReportTariffScheduleUsageSentByUser', active: false },
          { text: i18n.t('report.tariff_indicators.short-not-sent-by-values'), active: true }
        ]
      }
    },
    // Recompra
    {
      path: 'recompra/recompra-hc',
      name: 'ReportRecompraHC',
      component: () => import('@/views/Report/Recompra/RecompraHC/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}

export default ReportRouter
