/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import BlueTopLayout from '@/views/_shared/_BlueTopLayout'

const ProposalRouter = {
  path: '/proposal',
  component: BlueTopLayout,
  children: [
    {
      path: '/',
      name: 'ProposalIndex',
      component: () => import('@/views/Proposal/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('proposal.tariffs'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.proposal'), disabled: true }
        ]
      }
    },
    {
      path: ':id/create',
      name: 'ProposalCreate',
      component: () => import('@/views/Proposal/form-info'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.proposal'),
        breadcrumbs: [
          { text: i18n.t('actions.proposal'), disabled: false, href: 'ProposalIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: ':id/view',
      name: 'ProposalView',
      component: () => import('@/views/Proposal/form-view'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.proposal'),
        breadcrumbs: [
          { text: i18n.t('actions.proposal'), disabled: false, href: 'ProposalIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'search',
      name: 'ProposalSearch',
      component: () => import('@/views/Proposal/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.proposal'),
        breadcrumbs: [
          { text: i18n.t('actions.proposal'), disabled: false, href: 'ProposalIndex' },
          { text: i18n.t('actions.search'), disabled: true }
        ]
      }
    },
    {
      path: 'new',
      name: 'ProposalNew',
      component: () => import('@/views/Proposal/form-info'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.proposal'),
        breadcrumbs: [
          { text: i18n.t('actions.proposal'), disabled: false, href: 'ProposalIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default ProposalRouter
