import store from '@/store'
import Http from '@/services/Http'
import Cookies from 'js-cookie'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const BaseService = {
  validateResponseReturn (res) {
    if (res.data.hasOwnProperty('token')) {
      return true
    } else {
      global.instanceApp.$alertSwal.modalAlert('Erro', global.instanceApp.$t(res.data.message), 'error')
    }
  },
  checkSesssionExpired (err) {
    if (err.code === 401) {
      if (err.message.toLowerCase() === 'expired jwt token') {
        global.instanceApp.$alertSwal.modalAlert('Aviso', 'A sua sessão expirou. Faça login novamente!', 'error', '', { showCancelButton: false, showConfirmButton: true, showCloseButton: false }).then((result) => {
          Cookies.remove('_userAuth_')
          Cookies.remove('_userRememberMe_')
          store.dispatch('SetAuthUser', {
            authUser: null
          })
          global.instanceApp.$router.push({ name: 'Login' })
        })
      } else if (err.message.toLowerCase() === 'account is locked.') {
        global.instanceApp.$alertSwal.modalAlert('Aviso', 'Seu acesso foi desativado!', 'error', '', { showCancelButton: false, showConfirmButton: true, showCloseButton: false }).then((result) => {
          Cookies.remove('_userAuth_')
          Cookies.remove('_userRememberMe_')
          store.dispatch('SetAuthUser', {
            authUser: null
          })
          global.instanceApp.$router.push({ name: 'Login' })
        })
      } else {
        if (err.message === 'Network Error') {
          global.instanceApp.$alertSwal.modalAlert('', global.instanceApp.$t(err.message), 'error')
        } else {
          global.instanceApp.$alertSwal.modalAlert('Erro', 'Ocorreu um erro inesperado!<br />' + global.instanceApp.$t(err.message), 'error')
        }
        return false
      }
    } else if (err.error && err.error.code === 403) {
      global.instanceApp.$alertSwal.modalAlert('', global.instanceApp.$t(err.error.message), 'info')
      return false
    } else if (err.code === 400) {
      return err.errors
    } else if (err instanceof Blob) {
      global.instanceApp.$alertSwal.modalAlert('', 'Não foi possível realizar o download do arquivo!<br />Tente novamente mais tarde<br /> ' + global.instanceApp.$t(err.message), 'error')
      return false
    } else if (err.code === 409) {
      global.instanceApp.$alertSwal.modalAlert(null, global.instanceApp.$t(err.message), 'error')
      // TODO: direcionar para pagina de erro específica
      // global.instanceApp.$router.push({ name: 'Error500' })
      return false
    } else {
      if (err.message === 'Network Error') {
        global.instanceApp.$alertSwal.modalAlert('', global.instanceApp.$t(err.message), 'error')
      } else {
        global.instanceApp.$alertSwal.modalAlert('Erro', 'Ocorreu um erro inesperado!<br />' + global.instanceApp.$t(err.message), 'error')
      }

      // TODO: direcionar para pagina de erro específica
      // global.instanceApp.$router.push({ name: 'Error500' })
      return false
    }
  },
  showMessageError (err) {
    return this.checkSesssionExpired(err)
    // global.instanceApp.$alertSwal.modalAlert('Erro', 'Ocorreu um erro inesperado!<br />' + err.message, 'error')
  },
  getCEP (cep) {
    let queryString = httpBuildQuery({ cep: cep })

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/zipcode?${queryString}`, {})
          .then(function (res) {
            resolve({ data: res.data })
          })
          .catch(function (err) {
            reject(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  checkAccess () {
    return new Promise(function (resolve, reject) {
      Http.post(`${urlAPI}v2/check-access`, {}).then(function (res) {
        resolve({ data: res.data })
      }).catch(function (err) {
        reject(err)
      })
    })
  },
  checkPasswordChangeAccess (token) {
    return new Promise(function (resolve, reject) {
      Http.post(`${urlAPI}v2/password/check-reset/${token}`, {}).then(function (res) {
        resolve({ data: res.data })
      }).catch(function (err) {
        reject(err)
      })
    })
  }
}

export default BaseService
