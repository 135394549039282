/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor manutabilidade **/
import i18n from '@/i18n'
import BlueTopLayout from '@/views/_shared/_BlueTopLayout'

const ValuationRouter = {
  path: '/valuation',
  component: BlueTopLayout,
  children: [
    {
      path: '/',
      name: 'ValuationIndex',
      component: () => import('@/views/Valuation/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.valuation'), disabled: true }
        ]
      }
    },
    {
      path: 'new',
      name: 'ValuationNew',
      component: () => import('@/views/Valuation/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation'),
        breadcrumbs: [
          { text: i18n.t('sidebar.valuation'), disabled: false, href: 'ValuationIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: 'moderate',
      name: 'ValuationModerate',
      component: () => import('@/views/Valuation/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation'),
        breadcrumbs: [
          { text: i18n.t('sidebar.valuation'), disabled: false, href: 'ValuationIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: ':id/view',
      name: 'ValuationView',
      component: () => import('@/views/Valuation/PublicDetail'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation'),
        breadcrumbs: [
          { text: i18n.t('sidebar.valuation'), disabled: false, href: 'ValuationIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: ':id/edit',
      name: 'ValuationEdit',
      component: () => import('@/views/Valuation/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation'),
        breadcrumbs: [
          { text: i18n.t('sidebar.valuation'), disabled: false, href: 'ValuationIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'config',
      name: 'ValuationConfig',
      component: () => import('@/views/Valuation/Reason/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation'),
        breadcrumbs: [
          { text: i18n.t('sidebar.valuation'), disabled: false, href: 'ValuationIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default ValuationRouter
