/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import Layout from '@/views/_shared/_Layout'
const ProcessRouter = {
  path: '/process/',
  component: Layout,
  children: [
    {
      path: ':process?',
      name: 'ProcessList',
      component: () => import('@/views/Kpi/Process/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'Processos'
      }
    },
    {
      path: '/processdetail',
      name: 'ProcessDetails',
      component: () => import('@/views/Kpi/Process/ProcessDetails'),
      params: true
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default ProcessRouter
