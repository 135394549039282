/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import Layout from '@/views/_shared/_SinglePage'

const SPARouter = {
  path: '/spa/',
  component: Layout,
  children: [
    {
      path: 'solicitar-acesso',
      name: 'RequestAccessSPA',
      component: function (resolve) { require(['@/views/Auth/SPA/RequestAccess.vue'], resolve) },
      hidden: true
    },
    {
      path: 'termos-e-condicoes',
      name: 'TermosCondicoesSPA',
      component: function (resolve) { require(['@/views/Terms/View.vue'], resolve) },
      hidden: true
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}

export default SPARouter
