import i18n from '@/i18n'
import BlueTopLayout from '@/views/_shared/_BlueTopLayout'

const DashBoardAdminRouter = {
  path: '/dashboard/admin',
  component: BlueTopLayout,
  children: [
    {
      path: '/',
      name: 'DashboardSlots',
      component: () => import('@/views/Dashboard/Admin/DashboardSlots'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.dashboard-slots'),
        breadcrumbs: [
          { text: i18n.t('sidebar.dashboard-slots'), disabled: true }
        ]
      }
    },
    {
      path: 'carousel',
      name: 'CarouselList',
      component: () => import('@/views/Dashboard/Admin/Carousel/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.carousel'),
        breadcrumbs: [
          { text: i18n.t('sidebar.dashboard-slots'), disabled: false, href: 'DashboardSlots' },
          { text: i18n.t('sidebar.carousel'), disabled: true }
        ]
      }
    },
    {
      path: 'carousel/:id',
      name: 'CarouselDetail',
      component: () => import('@/views/Dashboard/Admin/Carousel/Detail'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.carousel'),
        breadcrumbs: [
          { text: i18n.t('sidebar.dashboard-slots'), disabled: false, href: 'DashboardSlots' },
          { text: i18n.t('sidebar.carousel'), disabled: false, href: 'CarouselList' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'carousel/:id/edit',
      name: 'CarouselEdit',
      component: () => import('@/views/Dashboard/Admin/Carousel/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.carousel'),
        breadcrumbs: [
          { text: i18n.t('sidebar.dashboard-slots'), disabled: false, href: 'DashboardSlots' },
          { text: i18n.t('sidebar.carousel'), disabled: false, href: 'CarouselList' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'hiperlink',
      name: 'HiperlinkList',
      component: () => import('@/views/Dashboard/Admin/Hiperlink/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.hiperlink'),
        breadcrumbs: [
          { text: i18n.t('sidebar.dashboard-slots'), disabled: false, href: 'DashboardSlots' },
          { text: i18n.t('sidebar.hiperlink'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default DashBoardAdminRouter
