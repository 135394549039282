/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor manutabilidade **/
import i18n from '@/i18n'
import BlueTopLayout from '@/views/_shared/_BlueTopLayout'

const TeamRouter = {
  path: '/team',
  component: BlueTopLayout,
  children: [
    {
      path: '/',
      name: 'TeamIndex',
      component: () => import('@/views/Team/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.team'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.team'), disabled: true }
        ]
      }
    },
    {
      path: 'new',
      name: 'TeamNew',
      component: () => import('@/views/Team/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.team'),
        breadcrumbs: [
          { text: i18n.t('sidebar.team'), disabled: false, href: 'TeamIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: ':id/view',
      name: 'TeamView',
      component: () => import('@/views/Team/Detail'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.team'),
        breadcrumbs: [
          { text: i18n.t('sidebar.team'), disabled: false, href: 'TeamIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: ':id/edit',
      name: 'TeamEdit',
      component: () => import('@/views/Team/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.team'),
        breadcrumbs: [
          { text: i18n.t('sidebar.team'), disabled: false, href: 'TeamIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default TeamRouter
