import Vue from 'vue'
import Vuex from 'vuex'
import AuthUser from './modules/AuthUser'
import Filters from './modules/Filters'
import Modals from './modules/Modals'
import Invoice from './modules/Invoice'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    AuthUser,
    Filters,
    Modals,
    Invoice
  },
  getters
})

export default store
