const gettersUser = {
  userToken: state => state.AuthUser.token,
  userAuth: state => state.AuthUser.user,
  userStatus: state => state.AuthUser.status,
  userRoles: state => state.AuthUser.roles,
  userInfo: state => state.AuthUser.userInfo,
  hasFilter: state => state.Filters.hasFilter,
  hasFilterSearch: state => state.Filters.hasFilterSearch
}

const gettersModal = {
  getModalData: state => state.Modals.modalData
}

const gettersFilters = {
  showFilter: state => state.Filters.showFilter,
  showSearch: state => state.Filters.showSearch,
  aplliedFilters: state => state.Filters.aplliedFilters,
  filtersFieldsDate: state => state.Filters.filtersFieldsDate,
  filtersFieldsCheck: state => state.Filters.filtersFieldsCheck,
  filtersFieldsMultiple: state => state.Filters.filtersFieldsMultiple,
  filtersFieldsObject: state => state.Filters.filtersFieldsObject,
  filtersNotEmpty: state => state.Filters.filtersNotEmpty,
  getRefreshFilter: state => state.Filters.refreshFilter
}

const gettersInvoice = {
  getInvoiceToManager: state => state.Invoice.invoiceToManager,
  getInvoiceSelected: state => state.Invoice.selected
}

const getters = Object.assign(
  {},
  gettersUser,
  gettersFilters,
  gettersModal,
  gettersInvoice
)

export default getters
