<template>
  <div class="row justify-content-md-center" :class="{'loading': !loaded}">
    <div class="col-12 col-sm-12 col-md-3 text-left dark-box">
      <div class="row" >
        <div class="col-12 mb-3 img-container">

          <a href="javascript:;" class="text-white" @click="showImageSelect">
            <!-- <img src="//placehold.it/200" class="rounded-circle d-block mx-auto img-profile" /> -->
            <img v-bind:src="showUserImage" class="rounded-circle d-block mx-auto img-profile" />
            <!-- <span :class="{'royalc-error-field': errors.has('user.imagem_perfil')}" v-show="errors.has('user.imagem_perfil')">{{ errors.first('user.imagem_perfil') }}</span> -->

            <div class="middle">
              <i class="ti-camera" style="font-size: 40px;"></i>
            </div>
          </a>
          <!-- <button type="button" :class="{'btn' : true, 'btn-sm': true, 'btn-success': true, 'd-block': btnSaveImage, 'mt-2': true, 'd-none': !btnSaveImage}" @click="onSaveImage">Alterar Imagem</button> -->
          <input type="file" accept="image/*" name="user.imagem_perfil" ref="imagem_perfil" @change="onFileChange" v-validate="{ image: true, maxdimensions:[800,800], size:1500 }" style="display: none;" />
          <span :class="{'royalc-error-field': errors.has('user.imagem_perfil')}" v-show="errors.has('user.imagem_perfil')">{{ errors.first('user.imagem_perfil') }}</span>
        </div>

        <div class="col-12 mb-3">
          <custom-textarea
            v-model="user.bio"
            name="user.bio"
            label="Biografia"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control text-white"
            :rows="5"
            v-validate="{ required: false }"
            :error="errors.first('user.bio')">
          </custom-textarea>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-9 modal-box">
      <div class="close-button mb-4">
        <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
          <span><i class="text-primary rc-Ativo-36"> </i></span>
        </div>
      </div>
      <div class="row ">
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.name"
            name="user.name"
            label="Nome"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: true }"
            :error="errors.first('user.name')">
          </custom-input>
        </div>
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.last_name"
            name="user.last_name"
            label="Sobrenome"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: true }"
            :error="errors.first('user.last_name')">
          </custom-input>
        </div>
        <div class="col-12 col-md-4">
          <label style="margin-right: 20px; margin-top: 25px;">Sexo</label>
          <custom-check-radio
            :listItems="[{text: 'Feminino', value: 'f'}, {text: 'Masculino', value: 'm'}]"
            v-model="user.gender"
            name="user.gender"
            type="radio"
            rootClassName="form-group d-inline"
            inputClassName="p-default p-round"
            v-validate="{ required: false }"
            :error="errors.first('user.gender')">
          </custom-check-radio>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8">
          <custom-input
            v-model="user.customer_name"
            name="user.customer_name"
            label="Empresa"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            :disabled="true"
            v-validate="{ required: true }"
            :error="errors.first('user.customer_name')">
          </custom-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.department"
            name="user.department"
            label="Departamento"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: false }"
            :error="errors.first('user.department')">
          </custom-input>
        </div>
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.office"
            name="user.office"
            label="Cargo"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: false }"
            :error="errors.first('user.office')">
          </custom-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.phone"
            name="user.phone"
            label="Telefone"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: false }"
            vMask="(##) #####-####"
            :error="errors.first('user.phone')">
          </custom-input>
        </div>
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.ramal"
            name="user.ramal"
            label="Ramal"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: false }"
            :error="errors.first('user.ramal')">
          </custom-input>
        </div>
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.linkedin"
            name="user.linkedin"
            label="Linkedin/"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: false, url: true }"
            :error="errors.first('user.linkedin')">
          </custom-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.cell_phone"
            name="user.cell_phone"
            label="Celular"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: false }"
            vMask="(##) #####-####"
            :error="errors.first('user.cell_phone')">
          </custom-input>
        </div>
        <div class="col-12 col-md-4">
          <!-- <input data-index="2" v-model="filter.endDate" id="endDate" readonly class="md-form-control" type="text"> -->
          <custom-input-date
            v-model="user.birth_date"
            name="user.birth_date"
            label="Data Nascimento"
            type="text"
            :readonly="true"
            v-validate="{ required: false }"
            :error="errors.first('user.birth_date')">
          </custom-input-date>
        </div>
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.facebook"
            name="user.facebook"
            label="Facebook/"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: false, url: true }"
            :error="errors.first('user.facebook')">
          </custom-input>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 col-md-8">
          <custom-input
            v-model="user.email"
            name="user.email"
            label="E-mail"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            :disabled="true"
            v-validate="{ required: true }"
            :error="errors.first('user.email')">
          </custom-input>
        </div>
        <div class="col-12 col-md-4">
          <custom-input
            v-model="user.instagram"
            name="user.instagram"
            label="Instagram/"
            type="text"
            rootClassName="md-form mb-0"
            inputClassName="md-form-control"
            v-validate="{ required: false, url: true }"
            :error="errors.first('user.instagram')">
          </custom-input>
        </div>
      </div>

      <div class="row check-input">
        <div class="col-12 col-md-8">
          <custom-check-radio
            :listItems="[{text: 'Quero receber informações por e-mail', value: 1}]"
            v-model="user.email_info"
            name="user.email_info"
            type="checkbox"
            inputClassName="p-switch p-fill pull-right"
            stateClassName="p-danger"
            v-validate="{ required: false }"
            :error="errors.first('user.email_info')">
          </custom-check-radio>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 text-center">
          <button type="button" class="btn btn-primary btn-fill btn-rounded mb-4 mr-3" @click="onSave()">Atualizar</button>
        </div>
      </div>

        <div class="row">
          <div class="col-12 col-md-12 ">
              <p class="subtitle clickable"  @click="showPass = !showPass">Alterar a senha</p>
          </div>
          <transition name="showPass">
            <div v-if="showPass" class="row col-12">
              <div class="col-12 col-md-4">
                <custom-input
                  v-model="password.new"
                  name="password.new"
                  label="Nova Senha"
                  type="password"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('password.new')">
                </custom-input>
              </div>
              <div class="col-12 col-md-4">
                <custom-input
                  v-model="password.repeat"
                  name="password.repeat"
                  label="Confirmar Senha"
                  type="password"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true, confirmed: password.new }"
                  :error="errors.first('password.new')">
                </custom-input>
              </div>
              <div class="col-12 col-md-4 text-center">
                <button type="button" class="btn-password btn btn-primary btn-fill btn-rounded mb-4 mr-3" @click="onSavePassword()">Atualizar</button>
              </div>
            </div>
          </transition>
        </div>
    </div>

    <div class="modal fade" ref="modalCropper">
      <div class="modal-dialog">
        <div class="modal-content text-center">

          <!-- Modal body -->
          <div class="modal-body">
            <vue-cropper
              ref="cropper"
              :src="imgSrc"
              alt="Source Image"
              :cropmove="cropImage"
              :guides="true"
              :view-mode="2"
              drag-mode="crop"
              :auto-crop-area="0.5"
              :min-container-width="300"
              :min-container-height="300"
              :background="true" :aspectRatio="1/1">
            </vue-cropper>

            <button type="button" :disabled="mdlFooterDisable" class="btn btn-primary btn-fill btn-rounded mt-4 mb-4 mr-3" @click="onSaveImage">Salvar</button>
            <button type="button" :disabled="mdlFooterDisable" class="btn btn-danger btn-fill btn-rounded mt-4mb-4 mr-3" data-dismiss="modal" @click="onClearImage">Cancelar</button>

          </div>

          <!--&lt;!&ndash; Modal footer &ndash;&gt;-->
          <!--<div class="modal-footer">-->
            <!--<button type="button" :disabled="mdlFooterDisable" class="btn btn-success" @click="onSaveImage">Salvar</button>-->
            <!--<button type="button" :disabled="mdlFooterDisable" class="btn btn-danger" data-dismiss="modal" @click="onClearImage">Cancelar</button>-->
          <!--</div>-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import UserService from '@/services/UserService'
import CompanyService from '@/services/CompanyService'
import CustomInput from '@/components/Forms/CustomInput.vue'
import CustomCheckRadio from '@/components/Forms/CustomCheckRadio.vue'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import CustomInputDate from '@/components/Forms/CustomInputDate.vue'
import VueCropper from 'vue-cropperjs'

import VeeValidate from 'vee-validate'
import { locale, maxDimensionsRule } from '@/utils/validator'

import 'cropperjs/dist/cropper.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

VeeValidate.Validator.localize({ 'pt_BR': locale })
// Inclusão de cláusula de validação de tamanho máximo para imagem
VeeValidate.Validator.extend('maxdimensions', maxDimensionsRule)
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'UserProfile',
  data () {
    return {
      loaded: true,
      btnSaveImage: false,
      mdlFooterDisable: false,
      imgSrc: '',
      cropImg: '',
      password: { new: null, repeat: null },
      showPass: false
    }
  },
  components: {
    CustomInput,
    CustomCheckRadio,
    CustomTextarea,
    CustomInputDate,
    VueCropper
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    onSave (scopeName) {
      let me = this
      this.$validator.validateAll(scopeName).then((result) => {
        if (result) {
          this.loaded = false
          // this.$emit('close')
          // global.instanceApp.$alertSwal.toast('Dados alterados', 'success')
          // global.instanceApp.$alertSwal.showLoading()
          let user = {
            bio: this.user.bio || '',
            name: this.user.name,
            last_name: this.user.last_name,
            gender: this.user.gender || '',
            department: this.user.department || '',
            office: this.user.office || '',
            phone: this.user.phone || '',
            ramal: this.user.ramal || '',
            linkedin: this.user.linkedin || '',
            cell_phone: this.user.cell_phone || '',
            birth_date: this.user.birth_date,
            facebook: this.user.facebook || '',
            instagram: this.user.instagram || '',
            email_info: this.user.email_info || 0
          }
          UserService.setUserProfile(this.user.id, user)
            .then(function (res) {
              if (res) {
                me.$emit('close')
                global.instanceApp.$alertSwal.toast('Dados alterados', 'success')
              }
            })
            .catch(() => {
            })
        }
      })
    },
    onSavePassword () {
      let me = this

      if (me.password.new === me.password.repeat) {
        let data = {
          'password': me.password.new
        }

        CompanyService.setCompanyUserPassword(me.user.id, data).then(contact => {
          me.$emit('close')
          me.$alertSwal.toast('Senha alterada com sucesso!')
          me.password.new = null
          me.password.repeat = null
        })
      }
    },
    onSaveImage () {
      var me = this
      this.user.imagem_perfil = this.$refs.cropper.getCroppedCanvas().toDataURL()
      me.mdlFooterDisable = true
      UserService.setUserImage(this.user.id, { 'imagem_perfil': this.user.imagem_perfil, 'image_name': this.$refs.imagem_perfil.value }).then(dataJson => {
        // global.instanceApp.$root.$store.dispatch('setAuthInfo', {
        //   userInfo: dataJson.data.user
        // })
        me.btnSaveImage = false
        me.onClearImage()
        me.showModal(false)
        me.mdlFooterDisable = false
      }).catch(() => {})
    },
    onClearImage () {
      this.$refs.imagem_perfil.value = null
    },
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      // this.$refs.modalCropper.open()
      this.createImage(files[0])
    },
    createImage (file) {
      var me = this
      this.$validator.validate('user.imagem_perfil').then(result => {
        if (result) {
          var reader = new FileReader()
          // var vm = this
          reader.onload = (e) => {
            // vm.user.usu_foto = e.target.result
            me.imgSrc = e.target.result
            // me.btnSaveImage = true
            me.$refs.cropper.replace(e.target.result)
            this.showModal(true)
          }
          reader.readAsDataURL(file)
        }
      })
    },
    showImageSelect () {
      this.$refs.imagem_perfil.click()
    },
    showModal (show) {
      let element = this.$refs.modalCropper
      if (show) {
        this.$jQuery(element).modal({ backdrop: 'static', keyboard: false, 'show': true })
      } else {
        this.$jQuery(element).modal('hide')
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    showUserImage () {
      if (this.user.imagem_perfil) {
        if (this.user.imagem_perfil.indexOf('data:image') > -1) {
          return this.user.imagem_perfil
        } else {
          return process.env.VUE_APP_CLOUDINARY_URL + this.user.imagem_url + '/' + this.user.imagem_perfil
        }
      } else {
        return (this.user.imagem_perfil || '') + '/static/images/users/admin-image.png'
      }
    }
  }
}
</script>

<style scoped>
  .loading {
    pointer-events: none;
    opacity: 0.4;
  }

  .justify-content-md-center {
    padding: 20px;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img-container:hover .img-profile {
    opacity: 0.3;
  }

  .img-container:hover .middle {
    opacity: 1;
  }

  .text {
    /* background-color: #4CAF50; */
    color: white;
    font-size: 16px;
    padding: 16px 32px;
    content: "\e64e";
  }

  .modal-box{
    padding: 75px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  .subtitle {
    margin-bottom: 8px;
    color: #999;
    margin-bottom: 0;
    margin-top: 10px;
    font-weight: bold;
    padding-right: 14px;
  }

  .btn-password {
    margin-bottom: 0 !important;
    margin-top: 10px;
  }

  .showPass-leave-active, .showPass-enter-active {
    transition: all 0.5s;
    max-height: 200px;
  }

  .showPass-enter, .showPass-leave-to {
    max-height: 0px;
    opacity: 0;
  }

  @media only screen and (max-width: 480px) {
    .modal-box{
      padding: 40px;
    }
    .pull-right{
      float: none;
    }
  }

</style>

<style>
.cropper-container {
  margin: 0px auto;
}
</style>
