<template>
  <div class="page-wrapper" :class="{'isDemo': showDemoSection}">
    Teste
  </div>
</template>

<script>
// import Vue from 'vue'
// import RouterRender from '@/views/_shared/_RouterRender.vue'

// Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })

import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/assets/vendor/feather-icons/feather.css'
import '@/assets/vendor/themify-icons/themify-icons.css'
import '@/assets/vendor/line-awesome/css/line-awesome.min.css'
import '@/assets/css/icons/rc-icons/style.css'
// import '@/assets/css/fonts/stylesheet.css'
import '@/assets/css/app.css'
import '@/assets/vendor/icons/icons-style.css'
import '@/assets/css/custom.css'
import '@/assets/css/royal-cargo.css'

import '@/assets/js/app.js'

export default {
  name: 'Layout',
  data () {
    return {
    }
  },
  metaInfo: {
    title: 'Portal - Royal Cargo do Brasil'
  },
  computed: {
    key () {
      return Math.random().toString().substr(2)
    },
    PageTitle () {
      return this.$route.meta.titleText
    },
    showDemoSection () {
      return process.env.VUE_APP_DEMO || false
    }
  },
  components: {
    // RouterRender
  },
  beforeMount: function () {
    document.querySelector('body').className = 'mini-sidebar'
    this.$store.dispatch('getUserInfo')
  }// ,
  // methods: {
  //   show_Modal () {
  //     this.$modal.show(UserProfile, {
  //       draggable: false
  //     }, {
  //       width: '95%',
  //       height: '95%'
  //     }, {
  //       adaptive: true
  //     }, {
  //       scrollable: true
  //     })
  //   }
  // },
  // mounted: function () {
  //   this.$nextTick(function () {
  //     setTimeout(() => {
  //       this.show_Modal()
  //     }, 3000)
  //   })
  // }
}
</script>

<style>
  .page-heading, .action-buttons, .filter-container  { display: none}

  .report-content {
    margin-top: 50px;
  }

  g[shape-rendering=auto] {
    display: none;
  }
</style>
