import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const UserService = {
  setUserProfile (idUser, dataProfile) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/user/save/${idUser}`, dataProfile).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            global.instanceApp.$root.$store.dispatch('setAuthInfo', {
              userInfo: res.data.user
            })
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  setUserImage (idUser, dataProfile) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/user/image/save/${idUser}`, dataProfile).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            global.instanceApp.$root.$store.dispatch('setAuthInfo', {
              userInfo: res.data.user
            })
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getUser (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/employee?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getUsers (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/user?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  newAccessRequest (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/register/access/request`, data).then(function (res) {
          if (res.data.hasOwnProperty('status') && res.data.status === 'ok') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          resolve(false)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getAccessRequest (token) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/register/${token}/access/request`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  acceptAccessRequest (token, data) {
    data = data || {}
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/register/${token}/accept`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  refuseAccessRequest (token, data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/register/${token}/refuse`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  confirmAccessRequest (token, data) {
    data = data || {}
    data.token = token
    let queryString = httpBuildQuery(data)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/register/confirm?${queryString}`, {}).then(function (res) {
          if (res.data.hasOwnProperty('status') && res.data.status === 'ok') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          resolve(false)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V1/user/employees
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getEmployeesUser (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/user/employees?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getFuncionario () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/user/funcionario/auth`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default UserService
