<template>
  <div class="row justify-content-md-center announcement" :class="{'loading': !loaded}">
    <div class="col-12 modal-box">
      <div v-if="modalData.show_close_button" class="close-button mb-4">
        <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
          <span><i class="text-primary rc-Ativo-36"> </i></span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <div id="content" class="content" v-html="modalData.content_html"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnnouncementService from '@/services/AnnouncementService'

export default {
  name: 'AnnouncementView',
  data () {
    return {
      loaded: true,
      btnSaveImage: false,
      mdlFooterDisable: false,
      imgSrc: '',
      cropImg: ''
    }
  },
  components: {
  },
  mounted () {
    var el = document.getElementById('close')
    el.addEventListener('click', () => this.onClose(), false)
  },
  methods: {
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    onClose () {
      let meData = this.modalData
      AnnouncementService.postAnnouncementRead(meData.id).then(res => {

      })

      this.$store.dispatch('setModalData', null)
      this.$emit('close')
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    modalData () {
      return this.$store.getters.getModalData || null
    }
  }
}
</script>

<style scoped>
  .loading {
    pointer-events: none;
    opacity: 0.4;
  }

  .justify-content-md-center {
    padding: 20px;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img-container:hover .img-profile {
    opacity: 0.3;
  }

  .img-container:hover .middle {
    opacity: 1;
  }

  .text {
    /* background-color: #4CAF50; */
    color: white;
    font-size: 16px;
    padding: 16px 32px;
    content: "\e64e";
  }

  .modal-box{
    padding: 75px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }
</style>

<style>
  .announcement .content {
    text-align: center;
  }

  .announcement .content p.title {
    font-size: 25px;
    margin-bottom: 32px;
  }

  .announcement .pretty {
    text-transform: capitalize;
    font-size: 100px;
    font-family: "hijrnotes";
    vertical-align: middle;
    padding-right: 20px;
    margin-left: -23px;
    display: inline-block;
    margin-top: -38px;
  }

  .announcement .content p, .announcement .content ul li  {
    font-size: 14px;
    color: #4D4F5C;
    font-family: 'pluto_sanslight';
  }

  .announcement .content p {
    margin-bottom: 30px;
  }

  .announcement .bold {
    font-size: 16px;
    font-family: 'pluto_sansheavy';
  }

  .announcement .content p.actionButton {
    margin-bottom: 0px;
    display: inline-block;
    background-color: #84754E;
    color: #fff;
    padding: 7px 30px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s;
    border: solid 1px #84754E;
  }

  .announcement .content p.actionButton:hover {
    background-color: rgba(132, 117, 78, 0.7);
    border-color: rgba(132, 117, 78, 0.8);
  }

  .announcement .content p a {
    font-size: 14px;
    color: #BC9760 !important;
  }

  .announcement .modal-box {
    padding: 50px;
  }
</style>
