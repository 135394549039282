/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_EmptyLayout'

const PrintRouter = {
  path: '/print',
  component: Layout,
  children: [
    {
      path: 'seller',
      name: 'ReportSellerPrint',
      component: () => import('@/views/Report/Seller/Quarter'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('report.seller.title'), disabled: true }
        ],
        sectionMenu: [],
        hidden: true
      }
    }

  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default PrintRouter
