/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const MaerskRouter = {
  path: '/integrations/maersk',
  component: Layout,
  children: [
    // Taxes
    {
      path: 'taxes',
      name: 'MaerskTaxesIndex',
      component: () => import('@/views/Integrations/Maersk/Taxes/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.integrations'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.integrations'), disabled: true },
          { text: i18n.t('Maersk'), disabled: true }
        ]
      }
    },
    {
      path: 'taxes/new',
      name: 'MaerskNew',
      component: () => import('@/views/Integrations/Maersk/Taxes/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.integrations'),
        breadcrumbs: [
          { text: i18n.t('sidebar.Maersk'), disabled: false, href: 'MaerskIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: 'taxes/:id/edit',
      name: 'MaerskEdit',
      component: () => import('@/views/Integrations/Maersk/Taxes/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.integrations'),
        breadcrumbs: [
          { text: i18n.t('sidebar.Maersk'), disabled: false, href: 'MaerskIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    // OriginsDestinations
    {
      path: 'originsDestinations',
      name: 'MaerskOriginsDestinationsIndex',
      component: () => import('@/views/Integrations/Maersk/OriginDestination/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.integrations'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.integrations'), disabled: true },
          { text: i18n.t('Maersk'), disabled: true }
        ]
      }
    },
    // MaerskTierArmador
    {
      path: 'tier-armador',
      name: 'MaerskTierArmadorIndex',
      component: () => import('@/views/Integrations/Maersk/TierArmador/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.integrations'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.integrations'), disabled: true },
          { text: i18n.t('Maersk'), disabled: true }
        ]
      }
    },
    {
      path: 'tier-armador/:id_tier/edit',
      name: 'MaerskTierArmadorEdit',
      component: () => import('@/views/Integrations/Maersk/TierArmador/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_armador.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier_armador.title'), disabled: true }
        ]
      }
    },
    {
      path: 'tier-armador/new',
      name: 'MaerskTierArmadorNew',
      component: () => import('@/views/Integrations/Maersk/TierArmador/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.tier_armador.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.tier_armador.title'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default MaerskRouter
