import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
/* Layout */
import Layout from '@/views/_shared/_Layout'
import LoginLayout from '@/views/Auth/_LoginLayout'
import LayoutError from '@/views/_shared/errorPage/_LayoutError'

import loginService from '@/services/loginService'

/* Router Modules */
import KpiFinantialRouter from './modules/kpi/FinantialRouter'
import KpiOperationalRouter from './modules/kpi/OperationalRouter'
import KpiProcessRouter from './modules/kpi/ProcessRouter'
import KpiRouter from './modules/kpi/KPIRouter'
import UserRouter from './modules/UserRouter'
import SchedulesRouter from './modules/schedules/SchedulesRouter'
import TariffRouter from '@/router/modules/tariff/TariffRouter'
import CompanyRouter from './modules/CompanyRouter'
import BasicRouter from './modules/basic/BasicRouter'
import ReportRouter from './modules/report/ReportRouter'
import ReportMonitoringRouter from './modules/report/ReportMonitoringRouter'
import ReportProcessControlRouter from './modules/report/ReportProcessControlRouter'
import MailRouter from './modules/mail/MailRouter'
import AnnouncementRouter from './modules/announcement/AnnouncementRouter'
import FirstLoginRouter from './modules/FirstLoginRouter'
import InvoiceRouter from './modules/invoice/InvoiceRouter'
import NotificationRouter from './modules/notification/NotificationRouter'
import RegisterRouter from './modules/user/RegisterRouter'
import TermsRouter from './modules/terms/TermsRouter'
import RequestAccessRouter from './modules/request-access/RequestAccessRouter'
import PrintRouter from './modules/print/PrintRouter'
import ProposalRouter from './modules/proposal/ProposalRouter'
import ToolsRouter from './modules/tools/ToolsRouter'
import MaerskRouter from './modules/integrations/MaerskRouter'
import BookingRouter from './modules/booking/BookingRouter'
import ValuationRouter from './modules/valuation/ValuationRouter'
import ValuationReasonRouter from './modules/valuation/ReasonRouter'
import TeamRouter from './modules/team/TeamRouter'
import AuditRouter from '@/router/modules/audit/AuditRouter'
import OportunidadesRouter from '@/router/modules/oportunidades/OportunidadesRouter'
import IntegrationsRouter from '@/router/modules/integrations/IntegrationsRouter'
import IndexRouter from '@/router/modules/index/IndexRouter'
import ReceiptRouter from '@/router/modules/receipt/ReceiptRouter'
import SoaRouter from '@/router/modules/soa/SoaRouter'
import FaqRouter from '@/router/modules/faq/FaqRouter'
import SPARouter from '@/router/modules/spa/SPARouter'
import DashBoardAdminRouter from '@/router/modules/dashboard/DashBoardAdminRouter'
import EdiRouter from '@/router/modules/edi/EdiRouter'

Vue.use(Router)
Vue.use(Meta)

export const constantRouterMap = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Default',
        hidden: true,
        redirect: '/dashboard'
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: function (resolve) { require(['@/views/Dashboard/DashboardV2'], resolve) },
        hidden: true,
        meta: {
          // roles: ['admin'],
          roles: [],
          titleHeader: true
        }
      },
      {
        path: '/dashboardv2',
        name: 'DashboardV2',
        component: function (resolve) { require(['@/views/Dashboard/DashboardV2'], resolve) },
        hidden: true,
        meta: {
          // roles: ['admin'],
          roles: [],
          titleHeader: true
        }
      }
    ]
  },
  KpiFinantialRouter,
  KpiOperationalRouter,
  KpiProcessRouter,
  KpiRouter,
  UserRouter,
  SchedulesRouter,
  TariffRouter,
  IndexRouter,
  CompanyRouter,
  OportunidadesRouter,
  BasicRouter,
  ReportRouter,
  ReportMonitoringRouter,
  ReportProcessControlRouter,
  MailRouter,
  AnnouncementRouter,
  FirstLoginRouter,
  InvoiceRouter,
  NotificationRouter,
  RegisterRouter,
  TermsRouter,
  RequestAccessRouter,
  PrintRouter,
  ProposalRouter,
  ToolsRouter,
  MaerskRouter,
  BookingRouter,
  IntegrationsRouter,
  ValuationRouter,
  ValuationReasonRouter,
  TeamRouter,
  ReceiptRouter,
  AuditRouter,
  SoaRouter,
  FaqRouter,
  SPARouter,
  DashBoardAdminRouter,
  EdiRouter,
  // UsersRouter,
  // ClientsRouter,
  // PostsRouter,
  {
    path: '/common/',
    component: LoginLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: function (resolve) { require(['@/views/Auth/Login.vue'], resolve) },
        hidden: true
      },
      {
        path: 'resetting/reset/:token',
        name: 'PasswordReset',
        component: function (resolve) { require(['@/views/Auth/PasswordReset.vue'], resolve) },
        hidden: true
      },
      {
        path: 'password-reset-request',
        name: 'PasswordResetRequest',
        component: function (resolve) { require(['@/views/Auth/PasswordResetRequest.vue'], resolve) },
        hidden: true
      },
      {
        path: 'request-access',
        name: 'RequestAccess',
        component: function (resolve) { require(['@/views/Auth/RequestAccess.vue'], resolve) },
        hidden: true
      },
      {
        path: 'confirm',
        name: 'RequestAccessConfirm',
        component: function (resolve) { require(['@/views/Auth/Login.vue'], resolve) },
        hidden: true
      },
      {
        path: 'request-received',
        name: 'AccessRequestReceived',
        component: function (resolve) { require(['@/views/Auth/AccessRequestReceived.vue'], resolve) },
        hidden: true
      },
      {
        path: 'request-aborted',
        name: 'AccessRequestAborted',
        component: function (resolve) { require(['@/views/Auth/AccessRequestAborted.vue'], resolve) },
        hidden: true
      },
      {
        path: 'tools/network',
        name: 'CheckNetwork',
        component: function (resolve) { require(['@/views/Tools/CheckNetwork.vue'], resolve) },
        hidden: true
      },
      {
        path: 'logout',
        name: 'Logout',
        hidden: true,
        beforeEnter (to, from, next) {
          loginService.logout()
          window.location = '/common/login'
        }
      }
    ]
  },
  {
    path: '/error/',
    component: LayoutError,
    children: [
      {
        path: '404',
        name: 'Error404',
        component: function (resolve) { require(['@/views/_shared/errorPage/404.vue'], resolve) },
        hidden: true
      },
      {
        path: '403',
        name: 'Error403',
        component: function (resolve) { require(['@/views/_shared/errorPage/403.vue'], resolve) },
        hidden: true
      },
      {
        path: '500',
        name: 'Error500',
        component: function (resolve) { require(['@/views/_shared/errorPage/500.vue'], resolve) },
        hidden: true
      }
    ]
  },
  {
    path: '/maintenance/',
    component: LayoutError,
    children: [
      {
        path: '/',
        name: 'MaintenancePage',
        component: function (resolve) { require(['@/views/Maintenance/Index'], resolve) },
        hidden: true
      }
    ]
  },

  { path: '*', redirect: '/error/404', hidden: true }
]

export default new Router({
  mode: 'history', // require service support
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else if (to.hash) {
  //     return { selector: to.hash }
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // },
  routes: constantRouterMap
})
