import axios from 'axios'
// import store from '@/store'

const clientHttp = axios.create({
  // baseURL: process.env.API
  baseURL: process.env.VUE_APP_APIENDPOINT
})

// funcao chamada antes de qualquer request ser feito, ela intercepta o request e faz algo com ele, nesse caso insere um token 'x-auth', caso o baseUrl for a nossa API
clientHttp.interceptors.request.use(req => {
  // if (req.baseURL === process.env.API) {
  if (req.baseURL === process.env.VUE_APP_APIENDPOINT) {
    // var token = localStorage.getItem('_userAuth_')
    var token = global.instanceApp.$Cookie.get('_userAuth_')
    if (token) {
      // if (req.method.toLowerCase() === 'post') {
      //   req.headers['Content-Type'] = 'application/x-www-form-urlencoded;'
      //   req.headers.common['Content-Type'] = 'application/x-www-form-urlencoded;'
      // }
      // req.headers.common['Content-type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
      // req.headers.common['__HTTP_ORIGIN'] = process.env.VUE_APP_BaseURL
      req.headers.common['Authorization'] = 'Bearer ' + token
    } else {
      req.headers['Content-Type'] = 'application/json;'
    }
  }
  return req
})

// funcao chamada antes que qualquer resposta passe para o "then" do request, nesse caso, se houver alguma mensagem de erro, ela eh tratada
clientHttp.interceptors.response.use(res => {
  // Update Token User Auth
  if (res.request.responseURL.indexOf('auth') === -1) {
    global.instanceApp.$root.$store.dispatch('setAuthToken', {
      authUser: res.data
    })
    if (res.data.token) {
      // localStorage.setItem('_userAuth_', res.data.token)
      if (global.instanceApp.$Cookie.get('_userRememberMe_')) {
        global.instanceApp.$Cookie.set('_userAuth_', res.data.token, { expires: 7 })
        global.instanceApp.$Cookie.set('_userRememberMe_', true, { expires: 365 })
      } else {
        global.instanceApp.$Cookie.set('_userAuth_', res.data.token)
      }
    }
  }
  return res
}, error => {
  if (error.response && error.response.status === 400) {
    if (error.response.data && error.response.data.errors) {
      Object.keys(error.response.data.errors).forEach(function (key) {
        // alertify.error(error.response.data.errors[key.toString()].message)
        // alertify.error(this.$t(error.response.data.errors[key.toString()].message))
      })
    }
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return Promise.reject(error.response.data)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  // Do something with response error
  return Promise.reject(error)
})

export default clientHttp
