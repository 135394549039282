<template>
  <!-- BEGIN: Header-->
  <div class="container-menu-header">
    <div class="container" v-on:click="onSandwich()">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <nav class="navbar navbar-expand navbar-light fixed-top header">
      <ul class="navbar-nav mr-auto">
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item" v-if="showFilter">
          <a class="nav-link navbar-icon quick-sidebar-toggler" href="javascript:;" @click="showQuickSidebar"><i class="ti-filter"></i></a>
        </li>
        <li class="nav-item" v-show="showSearch">
          <a class="nav-link navbar-icon" href="javascript:;" data-toggle="modal" data-target="#search-modal"><i class="ft-search"></i></a>
        </li>
        <li v-if="true" class="nav-item dropdown">
          <message-alerts />
        </li>
        <li class="nav-divider"></li>
        <li class="nav-item dropdown" v-if="user">
          <a class="nav-link dropdown-toggle no-arrow d-inline-flex align-items-center" data-toggle="dropdown" href="#">
            <span class="d-none d-sm-inline-block mr-2">{{ user.full_name }}</span>
            <span class="position-relative d-inline-block">
              <img class="rounded-circle" :src="showUserImage" alt="image" width="36"/>

            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right pt-0 pb-4" style="min-width: 280px;">
            <div class="p-4 mb-4 media align-items-center text-white dropdown-title">
              <img class="rounded-circle mr-3" :src="showUserImage" alt="image" width="55"/>
              <div class="media-body">
                <h5 class="mb-1">{{ user.full_name }}</h5>
                <div class="font-13"></div>
              </div>
            </div>
            <!-- <a href="javascript:;" class="dropdown-item d-flex align-items-center" @click="showProfile()"><i class="ft-user mr-3 font-18 "></i>{{ $t('header.profile') }}</a> -->
            <a href="javascript:;" class="dropdown-item d-flex align-items-center" @click="show_Modal()"><i class="ft-user mr-3 font-18 "></i>{{ $t('header.profile') }}</a>
            <!--<a class="dropdown-item d-flex align-items-center" href="javascript:;"><i class="ft-settings mr-3 font-18 "></i>{{ $t('header.settings') }}</a>-->
            <div class="dropdown-divider my-3"></div>
            <router-link :to="{name: 'Logout'}" class="dropdown-item d-flex align-items-center"><i class="ft-power mr-3 font-18 "></i>{{ $t('header.logout') }}</router-link>
          </div>
        </li>
      </ul>
      <modals-container />
    </nav>
  </div>
  <!-- END: Header-->
</template>

<script>
import Vue from 'vue'
import menu from '@/services/MenuService'
import UserProfile from '@/views/User/UserProfile.vue'
import MessageAlerts from '@/components/MessageAlerts.vue'
import VModal from 'vue-js-modal'

Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })

export default {
  name: 'PageHeader',
  data () {
    return {
      showModal: false,
      searchActive: false
    }
  },
  metaInfo () {
    return {
      titleTemplate: `%s | ${this.getTitle}`
    }
  },
  components: {
    MessageAlerts
  },
  methods: {
    handleScroll () {
      const sandwichMenu = document.querySelector('#sidebar')
      const sandwich = document.querySelector('.container')
      var windowWidth = window.innerWidth
      var containerMenu = document.querySelector('.container-menu-header')
      var containerMenuNav = document.querySelector('.header')
      var currentScroll = window.pageYOffset
      if (currentScroll > 20 && windowWidth < 600) {
        containerMenu.setAttribute('style', 'margin-top: -90px')
        containerMenuNav.setAttribute('style', 'margin-top: -90px')
        sandwichMenu.setAttribute('style', 'display: none')
        sandwich.classList.remove('change')
      } else if (currentScroll === currentScroll + 1) {
        containerMenu.setAttribute('style', 'margin-top: -90px')
        containerMenuNav.setAttribute('style', 'margin-top: -90px')
      } else {
        containerMenu.setAttribute('style', 'margin-top: 0')
        containerMenuNav.setAttribute('style', 'margin-top: 0')
        containerMenuNav.setAttribute('style', 'transition: 1s')
      }
    },
    onSandwich () {
      var changeSandwich = document.querySelector('.change')
      const sandwich = document.querySelector('.container')
      const sandwichMenu = document.querySelector('#sidebar')
      if (changeSandwich) {
        sandwich.classList.remove('change')
        sandwichMenu.setAttribute('style', 'display: none')
        return
      }
      sandwichMenu.setAttribute('style', 'display: block; padding-top: 40px; z-index: 9999')
      sandwich.classList.add('change')
    },
    showQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.add('has-backdrop')
      sideBar.classList.add('shined')
    },
    show_Modal () {
      this.$modal.show(UserProfile, {}, {
        draggable: false,
        adaptive: true,
        scrollable: true,
        clickToClose: false,
        width: '80%',
        height: 'auto'
      })
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    showFilter () {
      return this.$store.getters.showFilter || null
    },
    showSearch () {
      return this.$store.getters.showSearch || null
    },
    getTitle: function () {
      var me = this
      var title = ''
      menu.forEach(item => {
        if (item.items) {
          let child = item.items.find(i => {
            return i.component === me.$route.name
          })
          if (child) {
            title = me.$t(child.title)
          }
        } else {
          if (item.name === me.$route.name) {
            title = me.$t(item.title)
          }
        }
      })
      return title
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    showUserImage () {
      if (this.user.imagem_perfil) {
        if (this.user.imagem_perfil.indexOf('data:image') > -1) {
          return this.user.imagem_perfil
        } else {
          return process.env.VUE_APP_CLOUDINARY_URL + this.user.imagem_url + '/' + this.user.imagem_perfil
        }
      } else {
        return (this.user.imagem_perfil || '') + '/static/images/users/admin-image.png'
      }
    }
  }
}
</script>
<style>
  .royalc-modal-sm {
    width: 35%;
  }
  .royalc-modal-md {
    width: 60%;
  }
  .royalc-modal-lg {
    width: 95% !important;
    max-width: 95% !important;
  }
  .royalc-modal-header {
    text-align: left;
    padding: 7px;
  }
  #modals-container {
    z-index: 1090;
  }
  .justify-content-md-center {
    padding: 0px !important;
  }
  .v--modal-overlay .v--modal-box {
    border-radius: 10px;
  }

  .dark-box .img-container {
    padding: 30px;
  }
  @media (max-width: 768px){
    .container-menu-header{
      transition: 0.7s;
      position: fixed;
      z-index: 9999;
      min-height: 48px;
      box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
      display: flex;
      top: -1px;
      background-color: #fff;
      width: 100%;
    }
    .container-menu-header .container {
      display: inline-block;
      cursor: pointer;
      margin-left: 0;
      width: 0;
      margin-top: 0.3vh;
    }

    .bar1, .bar2, .bar3 {
      width: 35px;
      z-index: 99999;
      height: 5px;
      background-color: #333;
      margin: 6px 0;
      transition: 0.4s;
    }

    .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
      background-color: rgb(223, 223, 223);
    }
    .change .bar2 {
      opacity: 0;
      }

    .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
      background-color: rgb(223, 223, 223);
    }
    #sidebar::-webkit-scrollbar {
      display: none;
    }
    .header .navbar-nav .nav-link {
      padding-top: 0px;
      padding-bottom: 4px;
    }
  }
</style>
