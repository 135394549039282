<template>
  <div class="page-wrapper" :class="{'isDemo': showDemoSection}">
    <div class="top-alert-bar" v-if="showDemoSection">
      <div class="alert alert-warning text-center" role="alert">
        <i class="fa fa-info-circle"></i>
        {{ $t('Atenção! Este é um ambiente de demonstração') }}
      </div>
    </div>
    <div class="content-wrapper">
      <div class="content-area">
        <router-render :key="key" />
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import RouterRender from '@/views/_shared/_RouterRender.vue'

import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/assets/vendor/feather-icons/feather.css'
import '@/assets/vendor/themify-icons/themify-icons.css'
import '@/assets/vendor/line-awesome/css/line-awesome.min.css'
import '@/assets/css/icons/rc-icons/style.css'

import '@/assets/css/app.css'
import '@/assets/vendor/icons/icons-style.css'
import '@/assets/css/custom.css'
import '@/assets/css/royal-cargo.css'

import '@/assets/js/app.js'

export default {
  name: 'SinglePageTemplate',
  data () {
    return {
    }
  },
  metaInfo: {
    title: 'Portal - Royal Cargo do Brasil'
  },
  computed: {
    key () {
      return Math.random().toString().substr(2)
    },
    PageTitle () {
      return this.$route.meta.titleText
    },
    showDemoSection () {
      return process.env.VUE_APP_DEMO || false
    }
  },
  components: {
    RouterRender
  }
}
</script>

<style scoped>
.page-content {
  align-items: center;
  justify-content: center;
}
</style>

<style>
.page-heading, .action-buttons, .filter-container  { display: none}

.report-content {
  margin-top: 50px;
}

.content-area {
  padding: 0;
}

.card-body {
   padding-top: 0;
   padding-bottom: 0;
}

g[shape-rendering=auto] {
  display: none;
}
</style>
