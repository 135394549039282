export const Invoice = {
  state: {
    invoiceToManager: [],
    selected: []
  },
  mutations: {
    SET_INVOICE_TO_MANGER: (state, value) => {
      state.invoiceToManager = value
    },
    SET_RESET_INVOICE_TO_MANGER: (state, value) => {
      state.invoiceToManager = []
    },
    SET_INVOICE_SELECTED: (state, value) => {
      state.selected = value
    },
    SET_RESET_INVOICE_SELECTED: (state, value) => {
      state.selected = []
    }
  },
  actions: {
    resetInvoiceSelectedManager ({ commit }) {
      commit('SET_RESET_INVOICE_TO_MANGER')
    },
    setInvoiceSelectedManager ({ commit }, data) {
      commit('SET_INVOICE_TO_MANGER', data)
    },
    resetInvoiceSelected ({ commit }) {
      commit('SET_RESET_INVOICE_SELECTED')
    },
    setInvoiceSelected ({ commit }, data) {
      commit('SET_INVOICE_SELECTED', data)
    }
  }
}

export default Invoice
