/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const SoaRouter = {
  path: '/edi',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'EdiIndex',
      component: () => import('@/views/Report/EDI/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('edi.title'),
        breadcrumbs: [
          { text: i18n.t('edi.menu'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: '/download',
      name: 'EdiDownload',
      component: () => import('@/views/Report/EDI/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('edi.title'),
        breadcrumbs: [
          { text: i18n.t('edi.menu'), disabled: false, href: 'EdiIndex' },
          { text: i18n.t('edi.download'), disabled: true }
        ],
        sectionMenu: []
      }
    }
  ]
}

export default SoaRouter
