/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const AuditRouter = {
  path: '/audit/',
  component: Layout,
  children: [
    {
      path: 'processes',
      name: 'AuditProcesses',
      component: () => import('@/views/Report/Audit/Processes'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('audit.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('audit.title') + '' + i18n.t('report.audit.processos.title'), disabled: true }
        ],
        sectionMenu: []
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}

export default AuditRouter
