import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const InvoicesRouter = {
  path: '/invoices',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'InvoiceHome',
      component: () => import('@/views/Invoice/Index'),
      hidden: true,
      meta: {
        titleHeader: false,
        titleText: i18n.t('invoice.title')
      }
    },
    {
      path: 'list',
      name: 'InvoiceListBill',
      component: () => import('@/views/Invoice/Invoice/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('invoice.list.title'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceHome' }
        ]
      }
    },
    {
      path: 'issued',
      name: 'InvoiceListIssued',
      component: () => import('@/views/Invoice/Invoice/Issued'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('invoice.issued.title'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceHome' }
        ]
      }
    },
    {
      path: 'manager',
      name: 'InvoiceManager',
      component: () => import('@/views/Invoice/Invoice/Manager'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'Geração de Nota Fiscal',
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceHome' },
          { text: i18n.t('invoice.list.title'), disabled: false, href: 'InvoiceListBill' }
        ]
      }
    },
    {
      path: 'preference',
      name: 'InvoicePreferenceBilling',
      component: () => import('@/views/Invoice/BillingPreference/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('invoice.preferenceInvoice.title'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceHome' }
        ]
      }
    },
    {
      path: 'types',
      name: 'InvoiceMovementTypes',
      component: () => import('@/views/Invoice/MovementType/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.movementType'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceHome' }
        ]
      }
    },
    {
      path: 'types/:type/edit',
      name: 'InvoiceMovementTypeEdit',
      component: () => import('@/views/Invoice/MovementType/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('actions.edit'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceHome' },
          { text: i18n.t('basic.movementTypes.title'), disabled: false, href: 'InvoiceMovementTypes' }
        ]
      }
    },
    {
      path: 'types/new',
      name: 'InvoiceMovementTypeNew',
      component: () => import('@/views/Invoice/MovementType/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('actions.new'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceHome' },
          { text: 'Tipos de Movimentações', disabled: true }
        ]
      }
    },
    {
      path: 'international/types',
      name: 'InvoiceMovementInternationalTypes',
      component: () => import('@/views/Invoice/MovementType/ListInternational'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.movementTypeInternational'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceInternationalHome' }
        ]
      }
    },
    {
      path: 'international/types/:type/edit',
      name: 'InvoiceMovementTypeInternationalEdit',
      component: () => import('@/views/Invoice/MovementType/FormInternational'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('actions.edit'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceInternationalHome' },
          { text: i18n.t('basic.movementTypes.title'), disabled: false, href: 'InvoiceMovementInternationalTypes' }
        ]
      }
    },
    {
      path: 'international/types/new',
      name: 'InvoiceMovementTypeInternationalNew',
      component: () => import('@/views/Invoice/MovementType/FormInternational'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('actions.new'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceInternationalHome' },
          { text: i18n.t('basic.movementTypes.title'), disabled: false, href: 'InvoiceMovementInternationalTypes' }
        ]
      }
    },
    {
      path: 'international/',
      name: 'InvoiceInternationalHome',
      component: () => import('@/views/Invoice/InternationalInvoice/Index'),
      hidden: true,
      meta: {
        titleHeader: false,
        titleText: i18n.t('invoice.title')
      }
    },
    {
      path: 'international/list',
      name: 'InvoiceListInternationalBill',
      component: () => import('@/views/Invoice/InternationalInvoice/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('internationalInvoice.list.title'),
        breadcrumbs: [
          { text: i18n.t('internationalInvoice.title'), disabled: false, href: 'InvoiceInternationalHome' }
        ]
      }
    },
    {
      path: 'international/manager',
      name: 'InvoiceInternationalManager',
      component: () => import('@/views/Invoice/InternationalInvoice/Manager'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'Geração de Nota Fiscal Internacional',
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceInternationalHome' },
          { text: i18n.t('invoice.list.title'), disabled: false, href: 'InvoiceListInternationalBill' }
        ]
      }
    },
    {
      path: 'international/issued',
      name: 'InvoiceListIssuedInternational',
      component: () => import('@/views/Invoice/InternationalInvoice/Issued'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('invoice.internationalIssued.title'),
        breadcrumbs: [
          { text: i18n.t('invoice.title'), disabled: false, href: 'InvoiceInternationalHome' }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default InvoicesRouter
