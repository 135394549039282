<template>
  <div :class="rootClassName">
    <div v-for="(item, index) in listItems" :key="`div_${index}`" class="pretty" :class="inputClassName">
      <input :type="type" :name="name" :checked="item.value === value" :ref="refName" :id="`input_${currentKey}`" :value="item.value" @input="handleInput" @change="handleInput" @blur="$emit('blur')" :disabled="disabled" :readonly="readonly" />
      <div class="state" :class="stateClassName">
        <label>{{ $t(item.text) }}</label>
      </div>
    </div>
    <transition name="slide" mode="out-in">
      <small v-if="error" class="royalc-error-field">{{ error }}</small>
    </transition>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'CustonCheckRadio',
  data () {
    return {
      refName: this.refInput || 'field_' + Math.random().toString().substr(2),
      currentKey: ''
    }
  },
  mounted () {
    this.currentKey = this.getKey()
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    listItems: {
      type: Array,
      required: true
    },
    refInput: String,
    name: String,
    value: {
      type: null,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null,
      required: false
    },
    type: {
      type: String,
      default: 'checkbox',
      validator: val => {
        return (
          ['checkbox', 'radio'].indexOf(val) !== -1
        )
      }
    },
    rootClassName: {
      type: String,
      default: 'form-group'
    },
    inputClassName: {
      type: String
    },
    stateClassName: {
      type: String
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    },
    getKey () {
      return Math.random().toString().substr(2)
    }
  }
}
</script>

<style lang="scss" scoped>
  $pretty--color-default: #84754E !default;
  @import 'pretty-checkbox/src/pretty-checkbox.scss';

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s;
  }
  .slide-enter,
  .slide-leave-to {
    opacity: 0;
  }

  .pretty.p-switch input:checked ~ .state.p-primary[data-v-2d98f454]:before {
    border-color: #84754E;
  }

  .pretty.p-switch.p-fill input:checked ~ .state.p-primary[data-v-2d98f454]:before {
    background-color: #84754E !important;
  }

</style>
