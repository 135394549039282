// import Vue from 'vue'
import router from './router'
import store from './store'
// import { util } from '@/utils/utils'

// permission judge function
function hasPermission (roles, permissionRoles) {
  if (roles.indexOf('ROLE_ADMIN') >= 0) return true // admin permission passed directly
  if (!permissionRoles) return true
  return roles.some(role => permissionRoles.indexOf(role) >= 0)
}

// Function to check user before change route
function userConfirm (to, from) {
  return new Promise((resolve, reject) => {
    if (from.meta.onLeaveConfirm && !to.params.hasOwnProperty('ignoreOnLeaveConfirm')) {
      if (global.instanceApp) {
        let message = 'Tem certeza que deseja sair?<br/> <div class="mt-1 mb-3 small">Os dados não serão salvos.</div>'
        global.instanceApp.$alertSwal.modalConfirm(null, message, null, 'Sim', 'Não', result => {}).then(result => {
          if (result.value) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      }
    } else {
      resolve(true)
    }
  })
}

// no redirect whitelist
const whiteList = ['/spa/termos-e-condicoes', '/spa/solicitar-acesso', '/terms/service-terms', '/common/resetting/reset/', '/common/confirm', '/common/tools/network', '/maintenance', '/common/request-access', '/common/request-received', '/common/request-aborted', '/common/password-reset-request', '/common/login', '/common/logout', '/error/403', '/error/404', '/error/500']

router.beforeResolve((to, from, next) => {
  if (to.path) {
    if (global.instanceApp) {
      global.instanceApp.$alertSwal.showLoading()
    }
  }
  next()
})

router.beforeEach((to, from, next) => {
  userConfirm(to, from, next).then(result => {
    if (result) {
      if (global.instanceApp) {
        global.instanceApp.$alertSwal.showLoading()
      }
      // Se o usuário estiver acessando a página de manutenção e o sistema retornar o usuário será redirecionado para a tela de dashboard
      if (to.path === '/maintenance' && process.env.VUE_APP_MAINTENANCE === 'false') {
        next({ path: '/', replace: true })
        if (global.instanceApp) {
          global.instanceApp.$alertSwal.hideLoading()
        }
      }

      // Se o usuário estiver acessando qualquer página e o sistema entrar em manutenção será redirecionado para a tela de manutenção
      if (process.env.VUE_APP_MAINTENANCE === 'true' && to.path !== '/maintenance') {
        next({ path: '/maintenance', replace: true })
        if (global.instanceApp) {
          global.instanceApp.$alertSwal.hideLoading()
        }
      }

      if (store.getters.userStatus) {
        /* has token */
        if (to.path === '/common/login') {
          next({ path: '/' })
          if (global.instanceApp) {
            global.instanceApp.$alertSwal.hideLoading()
          }
        } else {
          if (store.getters.userRoles.length === 0) { // Determine whether the current user has pulled the user_info information
            store.dispatch('getUserInfo').then(res => { // Get user_info
              const roles = res.data.roles // note: roles must be a array! such as: ['editor','develop']
              store.dispatch('GenerateRoutes', { roles }).then(() => { // Generate accessible routing tables based on permissions
                router.addRoutes(store.getters.addRouters) // Dynamically add accessible routing tables
                next({ ...to, replace: true }) // hack method make sure addRoutes is complete ,set the replace: true so the navigation will not leave a history record
              })
            }).catch(() => {
              store.dispatch('FedLogOut').then(() => {
                next({ path: '/' })
              })
            })
          } else {
            // No need to dynamically change permissions can be directly next () delete the following permission judgment ↓
            if (hasPermission(store.getters.userRoles, to.meta.roles)) {
              next()
            } else {
              next({ path: '/401', replace: true, query: { noGoBack: true } })
            }
            // Can be deleted
          }
        }
      } else {
        /* has no token */
        if (to.name === 'PasswordReset' || whiteList.indexOf(to.path) !== -1) { // In the free login whitelist, go directly
          next()
        } else {
          let redirectInfo = {
            name: to.name,
            params: to.params,
            query: to.query
          }

          global.instanceApp.$Cookie.set('_request_secure_url_', JSON.stringify(redirectInfo))

          next({ path: '/common/login', replace: true })
          if (global.instanceApp) {
            global.instanceApp.$alertSwal.hideLoading()
          }
        }
      }
    }
  })
})

router.afterEach((to) => {
  if (global.instanceApp) {
    global.instanceApp.$alertSwal.hideLoading()
  }
})
