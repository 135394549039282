/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const SoaRouter = {
  path: '/soa/',
  component: Layout,
  children: [
    {
      path: 'send',
      name: 'SoaSendList',
      component: () => import('@/views/SOA/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.bonus-status.title'),
        breadcrumbs: [
          { text: i18n.t('report.bonus-status.title'), disabled: true }
        ],
        sectionMenu: []
      }
    },
    {
      path: 'send/:id/settings',
      name: 'SoaSendSettings',
      component: () => import('@/views/SOA/SendSettings'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('schedules.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('schedules.tariffs'), disabled: true }
        ]
      }
    }
  ]
}

export default SoaRouter
