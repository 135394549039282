/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor manutabilidade **/
import i18n from '@/i18n'
import BlueTopLayout from '@/views/_shared/_BlueTopLayout'

const ReasonRouter = {
  path: '/valuation',
  component: BlueTopLayout,
  children: [
    {
      path: 'reason',
      name: 'ValuationReasonIndex',
      component: () => import('@/views/Valuation/Reason/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation'),
        breadcrumbs: [
          { text: i18n.t('sidebar.valuation'), disabled: false, href: 'ValuationIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'reason/new',
      name: 'ValuationReasonNew',
      component: () => import('@/views/Valuation/Reason/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuations'),
        breadcrumbs: [
          { text: i18n.t('actions.list'), disabled: false, href: 'ValuationReasonIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: 'reason/:id/view',
      name: 'ValuationReasonView',
      component: () => import('@/views/Valuation/Reason/Detail'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation'),
        breadcrumbs: [
          { text: i18n.t('sidebar.valuation'), disabled: false, href: 'ValuationReasonIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'reason/:id/edit',
      name: 'ValuationReasonEdit',
      component: () => import('@/views/Valuation/Reason/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuations'),
        breadcrumbs: [
          { text: i18n.t('actions.list'), disabled: false, href: 'ValuationReasonIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: 'reason/type',
      name: 'ValuationReasonTypeIndex',
      component: () => import('@/views/Valuation/Reason/Type/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation_reasons'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.valuation'), disabled: true }
        ]
      }
    },
    {
      path: 'reason/type/new',
      name: 'ValuationReasonTypeNew',
      component: () => import('@/views/Valuation/Reason/Type/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuations'),
        breadcrumbs: [
          { text: i18n.t('actions.list'), disabled: false, href: 'ValuationReasonTypeIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: 'reason/type/:id/view',
      name: 'ValuationReasonTypeView',
      component: () => import('@/views/Valuation/Reason/Type/Detail'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuation'),
        breadcrumbs: [
          { text: i18n.t('sidebar.valuation'), disabled: false, href: 'ValuationReasonTypeIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'reason/type/:id/edit',
      name: 'ValuationReasonTypeEdit',
      component: () => import('@/views/Valuation/Reason/Type/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.valuations'),
        breadcrumbs: [
          { text: i18n.t('actions.list'), disabled: false, href: 'ValuationReasonTypeIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default ReasonRouter
