<template>
  <!-- <div class="input-group-icon input-group-icon-right mb-4">
    <div class="md-form mb-4">
      <input class="md-form-control m-0" type="text">
      <label class="">Search</label>
      <span class="input-icon input-icon-right"><i class="ti-search"></i></span>
    </div>
  </div> -->
  <div :class="rootClassName">
    <slot name="before"></slot>

    <div class="md-form">

      <input :type="type" :name="name" :value="$util.formatDate(value)" :ref="refName" :placeholder="placeholder" @input="handleInput" @change="handleInput" @blur="$emit('blur');"
      :disabled="disabled" :readonly="readonly" :class="[{ 'royalc-error-input': error }, inputClassName]">
      <span :class="classSpanIcon"><i :class="classInputIcon"></i></span>
      <label v-bind:class="{active: value}"  v-if="label">{{ label }}</label>
      <transition name="slide" mode="">
        <small v-if="error" class="royalc-error-field">{{ error }}</small>
      </transition>
    </div>

    <slot name="after"></slot>
  </div>
</template>

<script>
import dayjs from 'dayjs'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default {
  name: 'CustomInputDate',
  // data: () => ({
  //   refName: this.refInput || 'field_' + Math.random().toString().substr(2)
  // }),
  data () {
    return {
      refName: this.refInput || 'field_' + Math.random().toString().substr(2)
    }
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    refInput: String,
    name: String,
    value: {
      type: null,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator: val => {
        return (
          ['url', 'text', 'password', 'email', 'search'].indexOf(val) !== -1
        )
      }
    },
    rootClassName: {
      type: String,
      default: 'input-group-icon input-group-icon-right mb-0'
    },
    inputClassName: {
      type: String,
      default: 'md-form-control form-date'
    },
    classSpanIcon: {
      type: String,
      required: false,
      default: 'input-icon input-icon-right'
    },
    classInputIcon: {
      type: String,
      required: false,
      default: 'ti-calendar'
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e)
    }
  },
  mounted () {
    let me = this

    me.$jQuery('input[name="' + this.name + '"]').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    }).on('changeDate', function (e) {
      let formatedValue = dayjs(e.date).toISOString()
      me.handleInput(formatedValue)
    })
  }
}
</script>
