export default {
  'Network Error': 'Ops! Aparentemente estamos com alguma<br/> instabilidade na rede. <br/><br/> Tente novamente. Se o problema persistir,<br/> por favor nos avise',
  'Forbidden': 'Você não tem permissão de acesso',
  'pedido_enviado': 'Pedido Enviado!',
  'Por que precisamos do seu contato?': 'Por que precisamos do seu contato?',
  'sim': 'Sim',
  'nao': 'Não',
  'Account is disabled.': 'Conta inativa',
  'Drop files here or click to upload': 'Arraste ou clique para fazer o upload',
  'Bad credentials': 'Usuário e/ou senha inválidos',
  'Loading': 'Carregando',
  'all': 'Todos',
  'none': 'Nenhum',
  'the': 'à',
  'anticipated': 'Antecipado',
  'overdue': 'Em atraso',
  'receive': 'Receber',
  'email': 'E-mail',
  'in': 'de',
  'legend': 'Legenda',
  'noting to result': 'Nenhuma movimentação',
  'empty': 'Nenhum item encontrado',
  'noting selected': 'Nenhum item adicionado',
  'operation type and modal': 'Tipo de Operação e Modal',
  'operation type': 'Tipo de Operação',
  'origin_and_destination': 'Origem e destino',
  'cargo type': 'Tipo de Carga',
  'equipament type': 'Equipamento',
  'process situation': 'Situação',
  'numero_contrato': 'Número Contrato',
  'quantity': 'Quantidades',
  'total': 'Total',
  'duplicated': {
    'delivery-restriction': 'Já existe uma <strong>Restrições de entrega</strong><br/> cadastrada para esse <strong>Tipo de Disparador!</strong>'
  },
  'alert_messages': {
    'title': {
      'opened': 'Aberto',
      'boarding_forecast_added': 'Previsão de embarque adicionada',
      'boarding_forecast_updated': 'Previsão de embarque alterada',
      'boarded': 'Embarcado',
      'landed': 'Desembarcado',
      'paid': 'Pago',
      'finalized': 'Finalizado',
      'followup_added': ' Adicionado follow'
    },
    'message': {
      'opened': 'O processo %indicator% foi encerrado em %date%',
      'boarding_forecast_added': 'A previsão de embarque do processo %indicator% foi adicionada em %date%',
      'boarding_forecast_updated': 'A previsão de embarque do processo %indicator% foi alterada em %date%',
      'boarded': 'O processo %indicator% foi embarcado em %date%',
      'landed': 'O processo %indicator% foi desembarcado em %date%',
      'paid': 'As pendencias financeiras do processo %indicator% foram quitadas em %date%',
      'finalized': 'O processo %indicator% foi finalizado em %date%',
      'followup_added': '%name% deixou uma mensagem no processo %indicator% em %date%'
    }
  },
  header: {
    'logout': 'Logout',
    'settings': 'Cadastros',
    'profile': 'Profile'
  },
  'request-access': {
    'title': 'Preencha o formulário abaixo para solicitar seu acesso.',
    'has_an_account': 'Se você já possui cadastro, ',
    'send': 'Enviar',
    'approve': 'Aprovar',
    'refuse': 'Reprovar',
    'companyName': 'Empresa',
    'cnpj': 'CNPJ',
    'fone': 'Telefone',
    'zipcode': 'CEP',
    'password': 'Senha',
    'password_confirmation': 'Repetir senha',
    'state': 'Estado',
    'city': 'Muncípio',
    'firstName': 'Nome',
    'lastName': 'Sobrenome',
    'email': 'E-mail Corporativo',
    'accept': 'Eu concordo com os',
    'internalInfo': 'Motivo (Interno)',
    'terms_conditions': 'termos e condições',
    'success_requested': 'Solicitação de acesso realizada!',
    'success_accepted': 'Solicitação de acesso aprovada!',
    'success_refused': 'Solicitação de acesso recusada!'
  },
  login: {
    'title': 'Faça seu login:',
    'title1': 'eRoyal',
    'title2': 'ROYAL CARGO',
    'remember-me': 'Lembrar meu login',
    'login': 'Acessar',
    'forgot-password': 'Esqueceu a senha?',
    'click-here': 'Clique aqui',
    'do-not-have-an-account': 'Não tem uma conta?',
    'register': 'Registre-se',
    'request-access': 'Solicitar acesso',
    'content': {
      'link': 'QUERO SABER MAIS',
      'subtitle': 'das operações!',
      'first': '',
      'hightlight': 'No eRoyal da Royal Cargo, ',
      'last': 'você pode visualizar seus relatórios e ter controle de suas operações com a gente.'
    },
    'user-block': {
      'title': 'Acesso Bloqueado!',
      'text': 'As instruções para o desbloqueio foram enviadas para o seu endereço de e-mail corporativo.'
    }
  },
  first_login: {
    'title': 'Atualize sua senha',
    'login': 'Atualizar',
    'subtitle': 'Bem Vindo ao eRoyal',
    'description': 'Esta é a primeira vez que você entra no eRoyal pedimos que você atualize sua senha.'
  },
  'forgot-password': {
    'title': 'Esqueceu sua senha?',
    'message': 'Digite abaixo seu endereço de e-mail corporativo',
    'submit': 'ENVIAR'
  },
  'reset-password': {
    'title': 'Recuperar senha',
    'submit': 'Alterar senha'
  },
  'register': {
    'title': 'Crie sua conta',
    'submit': 'Solicitar acesso',
    'successful-registration': {
      'title': 'Solicitação enviada!',
      'text': 'Você receberá em até 48h o nosso retorno por e-mail.'
    },
    'reject-registration': {
      'title': 'Sucesso',
      'text': 'Solicitação de acesso recusada.'
    },
    'already_used': {
      'title': 'Usuário já cadastrado!',
      'submit': 'FAZER LOGIN'
    }
  },
  'resetting': {
    'title': 'Recuperar senha',
    'submit': 'Alterar senha',
    'successful-registration': {
      'title': 'Solicitação enviada!',
      'text': 'Você receberá em até 48h o nosso retorno por e-mail.'
    },
    'user_not_recognised': 'Usuário não encontrado',
    'password_already_requested': 'Redefiniçao de senha para este usuário já foi solicitada.',
    'check_email': 'Você receberá um email com as instruções para a redefinição de uma nova senha.<br/>' +
      '<br/><small>Nota: Você apenas poderá requisitar uma nova senha dentro de %tokenLifetime% horas.<br/>' +
      'Se você não receber sua senha, cheque sua pasta de spam ou tente novamente mais tarde.</small>'
  },
  'sidebar': {
    'index': 'RFX',
    'tarifario_lcl': 'Tarifário LCL',
    'tier_armador': 'Armador X Tier',
    'dePara': 'De/Para',
    'tarifarioCotacao': 'Contratos Inativos',
    'cupom': 'Cupons',
    'cupom-campanha': 'Campanha',
    'tools': 'Ferramentas',
    'proposal': 'Cotações',
    'new proposal': 'Nova Cotação',
    'authentication': 'Autenticação',
    'tasks': 'Agendamentos',
    'announcement': 'Informativos',
    'tariffs': 'Tarifários',
    'tariffs_bulk': 'Tarifários em massa',
    'taskCampaign': 'Disparador',
    'agentAlert': 'Alertas para Agentes',
    'goals': 'Metas',
    'goals-year': 'Metas - Anual',
    'goal-bonus': 'Metas - Bonificação',
    'goal-tier': 'Metas x Tier',
    'currencyquote': 'Moedas',
    'tariffImport': 'Importação de Tarifário',
    'movementType': 'Tipos de Movimentações',
    'movementTypeInternational': 'Internacional - Tipos de Movimentações',
    'goalSettings': 'Indicadores',
    'tariff': 'Tarifário',
    'tier': 'Tiers',
    'tier_padrao': 'Tier Padrão',
    'tier_local': 'Tiers Locais',
    'tier_settings': 'Cliente X Tier',
    'mail': 'E-mail',
    'settings': 'Cadastros',
    'integrations': 'Integrações',
    'mail-status': 'Status de envio',
    'mail-automatic': 'Automáticos',
    'mail-template': 'Templates',
    'lcl_tariffs': 'Tarifários LCL',
    'booking': 'Booking',
    'bookings': 'Bookings',
    'volumes': 'Volumes',
    'origins/destinations': 'Origens/ Destinos',
    'importers/exporters': 'Importadores/ Exportadores',
    'carriers': 'Transportadoras',
    'shipping_values': 'Valores Frete',
    'process': 'Processos',
    'receipt': 'Recibos',
    'soa': 'SOA',
    'dashboard-slots': 'Dashboard Slots',
    'carousel': 'Carrosel'
  },
  'index': {
    'de-para': {
      'title': 'Importação dados Log Comex De > Para '
    },
    'tier': {
      'title': 'Tier'
    },
    'import': {
      'title': 'Importação dados Log Comex'
    }
  },
  'integrations': {
    'taxes': 'Taxas',
    'origin_destination': 'Origens/Destinos',
    'tier_armador': 'Tier Armador',
    'log': 'Log de sincronização',
    'webhook': 'Webhook',
    'dePara': 'De Para'
  },
  'actions': {
    'verify': 'Verificar',
    'agroup': 'Agrupar e Transmitir',
    'transmit': 'Transmitir',
    'cancel': 'Cancelar',
    'download_pdf': 'Baixar arquivo PDF',
    'download_xml': 'Baixar arquivo XML',
    'issue': 'Emitir',
    'remove from list': 'Remover da lista',
    'return to list': 'Retornar a lista',
    'info_show': 'Mostrar mais informações',
    'info_hide': 'Ocultar informações',
    'edit': 'Editar',
    'copy': 'Copiar',
    'copy_to_clipboard': 'Copiar para área de transferência',
    'list': 'Ações',
    'view': 'Visualizar',
    'new': 'Novo',
    'all': 'Ver todas',
    'log': 'Ver log',
    'whatsapplog': 'Ver whatsapp log',
    'delete': 'Excluir',
    'send_now': 'Enviar',
    'send_test': 'Verificar',
    'remove_from_alerts': 'Remover dos alertas',
    'add_info': 'Adicionar observação',
    'add_info_to_alerts': 'Adicionar observação ao alerta',
    'add_to_alerts': 'Adicionar aos alertas',
    'change_status': 'Atualizar situação',
    'force_sync': 'Forçar sincronização',
    'add_contacts': 'Enviar cópia',
    'update_status': 'Atualizar status',
    'request': 'Solicitar'
  },
  'notifications': {
    'title': 'Notificações',
    'total_itens_message': '%totalNotifications% novas notificações',
    'total_item_message': '%totalNotifications% nova notificação',
    'total_item_no_message': 'Nenhuma nova notificação',
    'mark_all_as_read': 'Marcar todas como lida',
    'status': {
      'read': 'Lidos',
      'unread': 'Não lidos'
    }

  },
  'template': {
    'title': 'Templates'
  },
  'mails': {
    'status': {
      'title': 'Status de envio'
    },
    'title': 'E-mails',
    'template': {
      'description': 'Descrição',
      'idiom': 'Idioma',
      'cc': 'Com cópia (CC)',
      'cco': 'Com cópia oculta (CCO)',
      'attach': 'Anexar Arquivo(s)',
      'files': 'Anexos',
      'sender': 'Remetente',
      'defaultSender': 'Remetente Padrão',
      'defaultSenderRO': 'Remetente Padrão RO',
      'name': 'Nome',
      'subject': 'Título da Mensagem (Assunto)',
      'empty': 'Nenhum template encontrado',
      'type': 'Tipo',
      'content_html': 'Texto do E-mail',
      'show_signature': 'Mostrar assinatura',
      'content_text': 'Mensagem'
    }
  },
  'users': {
    'title': 'Usuários',
    'view': 'Visualizar',
    'actions': 'Ações',
    'search': 'Buscar ...',
    'sections': {
      'pessoal_info': 'Dados Pessoais',
      'account': 'Dados da Conta',
      'organization': 'Dados da Organização'
    },
    'profile': {
      'title': 'Perfil'
    }
  },
  'modal': {
    'title': 'Modal',
    'aereo': 'Aéreo',
    'maritime': 'Marítimo',
    'terrestre': 'Terrestre',
    'options': {
      '1': 'Aéreo',
      '2': 'Maritimo',
      '3': 'Terrestre'
    }
  },
  'operation': {
    'title': 'Operação',
    'impo': 'Importação',
    'expo': 'Exportação',
    'nac': 'Nacional',
    'options': {
      '1': 'Exportação',
      '2': 'Importação',
      '3': 'Nacional'
    }
  },
  'cargo': {
    'teus': 'TEUS',
    'kg': 'KG'
  },
  'period': {
    'title': 'Período',
    'daily': 'diariamente',
    'weekly': 'Semanal',
    'quarter': 'Trimestre',
    'monthly': 'Mensal',
    'yearly': 'Anual',
    'Day': 'Dia',
    'Week': 'Semana',
    'Month': 'Mês',
    'Year': 'Ano',
    'Days': 'Dias',
    'Weeks': 'Semanas',
    'Months': 'Meses',
    'Years': 'Anos'
  },
  'modal_operation': {
    'exp_aer': 'Exportação aérea',
    'imp_aer': 'Importação aérea',
    'nac_aer': 'Nacional aérea',
    'exp_mar': 'Exportação marítima',
    'imp_mar': 'Importação marítima',
    'nac_mar': 'Nacional marítima',
    'exp_ter': 'Exportação terrestre',
    'imp_ter': 'Importação terrestre',
    'nac_ter': 'Nacional terrestre'
  },
  'filters': {
    'filProcesso': 'Processo',
    'filEmployee': 'Colaborador',
    'filEmployees': 'Colaborador',
    'filUsers': 'Colaborador',
    'filQuarter': 'Semestre',
    'filMonth': 'Mês',
    'filWeek': 'Semana',
    'filYear': 'Ano',
    'filReferenciaCliente': 'Referência Cliente',
    'filCliente': 'Cliente',
    'filClienteNaoFiltrado': 'Cliente',
    'filAgenteOrigem': 'Agente Origem',
    'filHBL': 'HBL',
    'filPeriodoEmbarqueDtInicio': 'Embarque Dt. Inicio',
    'filTypeCargoTons': 'TONS',
    'filTypeCargoTeus': 'TEUS',
    'filPeriodoDtInicio': 'Período Dt. Inicio',
    'filPeriodoDtFim': 'Período Dt. Fim',
    'filPeriodoDtInicioInvoice': 'Período Dt. Inicio',
    'filPeriodoDtFimInvoice': 'Período Dt. Fim',
    'filPeriodoEmbarqueDtFim': 'Embarque Dt. Fim',
    'filPeriodoDesembarqueDtInicio': 'Desembarque Dt. Inicio',
    'filPeriodoDesembarqueDtFim': 'Desembarque Dt. Fim',
    'filPeriodo': 'Período',
    'filTipoRequisicaoAPI': 'API',
    'filTipoRequisicaoPortal': 'Portal',
    'filTipoOperacaoModalImportacao': 'IMPO',
    'filTipoOperacaoModalExportacao': 'EXPO',
    'filTipoOperacaoModalNacional': 'NAC',
    'filTipoOperacaoModalMaritimo': 'Marítimo',
    'filTipoOperacaoModalAereo': 'Áereo',
    'filTipoOperacaoModalTerrestre': 'Terrestre',
    'situacaoAbertos': 'Abertos',
    'situacaoEmbarcados': 'Embarcados',
    'filOrigem': 'Origem',
    'filDestino': 'Destino',
    'filTipoEquipamento': 'Equipamento'
  },
  'invoice': {
    'title': 'Gerenciamento de Nota Fiscal',
    'list': {
      'title': 'Notas Fiscais para Emitir'
    },
    'InternationalBill': {
      'title': 'Gerar Nota Fiscal Internacional',
      'description': 'Emissão de nota fiscal internacional para faturas já liberadas pelo financeiro'
    },
    'internationalIssued': {
      'title': 'Consultar Notas Fiscais Internacionais',
      'description': 'Acompanhe suas notas fiscais já emitidas'
    },
    'bill': {
      'title': 'Gerar Nota Fiscal',
      'description': 'Emissão de nota fiscal para faturas já liberadas pelo financeiro'
    },
    'receipt': {
      'title': 'Gerar Recibo',
      'generate-receipt-title': 'Gerar Recibos',
      'description': 'Emissão de recibo'
    },
    'receipt-issued': {
      'title': 'Consultar Recibos',
      'description': 'Acompanhe seus recibos emitidos'
    },
    'issued': {
      'title': 'Consultar Notas Fiscais',
      'description': 'Acompanhe suas notas fiscais já emitidas'
    },
    'preferenceInvoice': {
      'title': 'Preferência de Faturamento',
      'description': 'Preferência de Faturamento'
    },
    'typeMoviment': {
      'title': 'Tipos de Movimentação',
      'description': 'Tipos de Movimentação'
    },
    'titleNfs': 'NF-s',
    'titleNfsNacional': 'Nacional',
    'titleNfsInternacional': 'Internacional',
    'table': {
      'selected': 'Selecionar'
    },
    'form': {
      'person': 'Pessoa',
      'movement': 'Tipo de Movimentação',
      'tax': 'Taxas',
      'grouped': 'Agrupada',
      'mirror': 'Espelho',
      'process': 'Espelho',
      'description': 'Espelho',
      'value': 'Valor',
      'total': 'Total'
    }
  },
  'internationalInvoice': {
    'title': 'Gerenciamento de Nota Fiscal',
    'list': {
      'title': 'Internacional - Notas Fiscais para Emitir'
    },
    'InternationalBill': {
      'title': 'Gerar Nota Fiscal Internacional',
      'description': 'Emissão de nota fiscal internacional para faturas já liberadas pelo financeiro'
    },
    'bill': {
      'title': 'Gerar Nota Fiscal',
      'description': 'Emissão de nota fiscal para faturas já liberadas pelo financeiro'
    },
    'issued': {
      'title': 'Internacional - Consultar Notas Fiscais',
      'description': 'Acompanhe suas notas fiscais já emitidas'
    },
    'preferenceInvoice': {
      'title': 'Preferência de Faturamento',
      'description': 'Preferência de Faturamento'
    },
    'typeMoviment': {
      'title': 'Tipos de Movimentação',
      'description': 'Tipos de Movimentação'
    },
    'titleNfs': 'NF-s',
    'titleNfsNacional': 'Nacional',
    'titleNfsInternacional': 'Internacional',
    'table': {
      'selected': 'Selecionar'
    },
    'form': {
      'person': 'Pessoa',
      'movement': 'Tipo de Movimentação',
      'tax': 'Taxas',
      'grouped': 'Agrupada',
      'mirror': 'Espelho',
      'process': 'Espelho',
      'description': 'Espelho',
      'value': 'Valor',
      'total': 'Total'
    }
  },
  'company': {
    'title': 'Empresas'
  },
  'oportunidades': {
    'title': 'Oportunidades'
  },
  'dropfile': 'Clique aqui para adicionar um arquivo.',
  'equipment': {
    'description': 'Nome'
  },
  'basic': {
    'task-campaign-delivery-restriction': {
      'title': 'Restrições de entrega'
    },
    'task-campaign-type': {
      'title': 'Tipo de Disparador'
    },
    'mail-group': {
      'title': 'Grupos de e-mail'
    },
    'tier': {
      'title': 'Tier'
    },
    'tier_local': {
      'title': 'Tier Locais'
    },
    'goal': {
      'title': 'Metas',
      'titleSettings': 'Configurações das Metas',
      'send_email': 'Acompanhar por e-mail'
    },
    'goal-bonus': {
      'title': 'Metas - Bonificação',
      'titleSettings': 'Configurações das Metas para bonificação'
    },
    'goal-tier': {
      'title': 'Metas x Tier',
      'titleSettings': 'Configurações das Metas por tier'
    },
    'currencyquote': {
      'title': 'Cotação Cambial',
      'titleSettings': 'Cotações de moedas estrangeiras'
    },
    'tariffimport': {
      'title': 'Importação de Tarifário',
      'titleSettings': 'Upload de Arquivos de Tarifário',
      'titleFile': 'Agente de cargas que enviou o arquivo',
      'agent': 'Agente'
    },
    'tarifflcl': {
      'title': 'Tarifário LCL'
    },
    'movementTypes': {
      'title': 'Tipos de Movimentações',
      'titleSettings': 'Tipos de Movimentações'
    }
  },
  'schedules': {
    'commoditie': 'Material',
    'title': 'Agendamentos',
    'tariffs': 'Envio de tarifários',
    'contacts': 'Contatos',
    'approach': 'Abordagem',
    'equipment': 'Tipo de Equipamento',
    'frequency': 'Frequência de envio',
    'description': 'Descrição',
    'origin': 'Origens',
    'originsDestinations': 'Origens X Destinos',
    'destination': 'Destinos',
    'shipowners': 'Armadores',
    'shipowners_accept': 'Aceito trabalhar com',
    'shipowners_refuse': 'Me recuso a trabalhar com',
    'frequencyType': {
      'never': 'Nunca',
      'at': 'Em',
      'in': 'Após'
    }
  },
  'transport_company': {
    'name': 'Nome'
  },
  'origin_destination': {
    'name': 'Nome',
    'nickname': 'Apelido',
    'email': 'E-mail',
    'phone': 'Telefone'
  },
  'kpi': {
    'title': 'KPI',
    'description': 'Obtenha um olhar estratégico para a sua tomada de decisão por meio dos dados de processos.',
    'section': {
      'offers': 'Propostas',
      'process': 'Processos',
      'teus': 'Teus',
      'tons': 'Tons',
      'volume': 'Volumes',
      'origin_destination': 'Origens/Destinos'
    },
    'insight': {
      'title': 'Insight',
      'description': 'Obtenha diversos insights para soluções positivas através dos dados.'
    },
    'process': {
      'title': 'Processos',
      'description': 'Veja a situação, datas, documentação e outras informações sobre seus processos.',
      'historical_number_processes': 'Quantidade histórica de processos',
      'total_processes_per_modal': 'Total de processos por modal',
      'insured_processes': 'Processos assegurados',
      'total_participation_processes_by_modal': 'Total de participação de processos por modal',
      'total_processes_toBoard_boarded_by_modal': 'Total de processos embarcados e à embarcar por modal'
    },
    'offers': {
      'title': 'Eficiência de Cotações'
    },
    'proposal': {
      'description': 'Encontre a melhor opção para o que você precisa e realize uma pré-reserva agora.'
    },
    'finantial_position': {
      'title': 'Financeiro',
      'subtitle': 'Descrição das informações / uso do card',
      'description': 'Acompanhe informações sobre suas faturas.',
      'invoices': 'Todas Faturas'
    },
    'invoices': {
      'title': 'Faturas',
      'status': {
        'pending': 'Pendentes',
        'pay': 'Pagos',
        'parcial': 'Parciais',
        'today': 'Vence Hoje',
        'fiveD': '5 dias para vencimento',
        'tenD': '10 dias para vencimento',
        'overdue': 'Vencidos'
      }
    },
    'freight_evolution': {
      'title': 'Evolução do frete'
    },
    'analyst': {
      'title': 'Analista',
      'operatinalList': 'Processos',
      'embarques': 'Confirmações de Embarque',
      'activities': 'Atividades',
      'transfers': 'Transferências',
      'cancels': 'Cancelamentos'
    },
    'volume': {
      'title': 'Volumes',
      'description': 'Informações de todos os processos, volumes, principais origens e destinos',
      'total_offers_approved': 'Ofertas aprovadas',
      'historical_number_processes': 'Quantidade histórica de volumes',
      'total_volumes_per_modal': 'Total volumes por modal',
      'total_participation_volumes_by_modal': 'Total de participação de volumes por modal',
      'total_offers': 'Número de ofertas',
      'period': 'Período',
      'total_geral_aprovados': 'Total Geral Aprovados',
      'total_geral_abertos': 'Total Geral Abertos',
      'opened': 'Abertos',
      'toBoard': 'Abertos',
      'boarded': 'Embarcados',
      'teusToBoardChartLabel': 'À embarcar: %value% TEUS',
      'teusBoardedChartLabel': 'Embarcados: %value% TEUS',
      'tonsToBoardChartLabel': 'À embarcar: %value% TONS',
      'tonsBoardedChartLabel': 'Embarcados: %value% TONS'
    },
    'value_freight': {
      'title': 'Valores de Frete',
      'Evolution': 'Evolução',
      'Value_by_Origin_Destination_Process_Offer': 'Valor por Origem/Destino/Processo/Oferta',
      'offer': 'Oferta',
      'proccess': 'Processo',
      'Supply_of_freight_process': 'Valor do frete da oferta x processo',
      'Average_per_origin_and_destination': 'Média por origem e destino',
      'Origin': 'Origem',
      'Destiny': 'Destino',
      'equipment': 'Equipamento',
      'Average_price': 'Preço médio',
      'Average_price_all_in': 'Preço médio All in',
      'offers': 'Ofertas',
      'processes': 'Processos',
      'Transit_time': 'Tempo de Trânsito'
    },
    'payment_time': {
      'title': 'Tempo de Pagamento de Faturas',
      'Average_time_to_Pay_Invoices': 'Tempo médio para pagamento das faturas',
      'Invoice_payment_day': 'Dia de vencimento X pagamento de faturas',
      'paid': 'Pago',
      'not_paid': 'Não pago',
      'Range_of_days': 'Faixa de dias',
      'Number_of_invoices': 'Número de faturas'
    },
    'insight_time_release': {
      'title': 'Tempo de Liberação',
      'Average_load_release_time': 'Tempo Médio de Liberação de Cargas'
    },
    'origin_destination': {
      'name': 'Nome',
      'title': 'Origens/Destinos',
      'files': 'Processos',
      'teus': 'TEUS',
      'kg': 'KG',
      'others': 'Outros',
      'expo_impo': 'Principais Importadores/Exportadores',
      'top_origins': 'Principais Origens',
      'top_destinations': 'Principais Destinos'
    },
    'consignee_shipper': {
      'title': 'Importadores/Exportadores',
      'others': 'Outros',
      'top_consignee': 'Principais Importadores',
      'top_shipper': 'Principais Exportadores'
    },
    'major_carriers': {
      'title': 'Principais Transportadoras'
    }
  },
  'report': {
    'financeiro': {
      'title': 'Financeiro'
    },
    'indicadores': {
      'title': 'Indicadores'
    },
    'bonificacao': {
      'title': 'Bonificação/Comissão'
    },
    'vendedor': 'Vendedor',
    'analista': 'Analista',
    'proposal': {
      'title': 'Cotações Online'
    },
    'bonus-status': {
      'title': 'Acompanhamento Bonificação'
    },
    'indicators': {
      'title': 'Indicadores',
      'sections': {
        'operational': 'Operacional',
        'commercial': 'Comercial',
        'financial': 'Financeiro',
        'it': 'TI',
        'Talent & Culture': 'Talento & Cultura',
        'endomarketing': 'Endomarketing'
      }
    },
    'responsavel': 'Responsável',
    'title': 'Relatórios',
    'alertsProcessControl': {
      'title': 'Controle de Processos',
      'ReportAlertsProcessControlProcessesWithoutDispatch': 'Processos sem prontidão',
      'ReportAlertsProcessControlProcessesWithoutReservationNumber': 'Processos com previsão de prontidão sem reserva',
      'ReportAlertsProcessControlProcessesReadyWithoutReservationNumber': 'Processos com prontidão sem reserva',
      'ReportAlertsProcessControlProcessesDelayed': 'Processos com atraso'
    },
    'alertsMonitoring': {
      'title': 'Monitoria',
      'ReportAlertsMonitoringProcessesWithoutAnswerables': 'Aviso de pendência de preenchimento de responsáveis',
      'ReportAlertsMonitoringProcessesCheckFiles': 'Aviso de pendência de arquivos',
      'ReportAlertsMonitoringProcessesCheckCargoValues': 'Aviso de pendência de valor de mercadoria',
      'ReportAlertsMonitoringProcessesCheckInsurance': 'Aviso de pendência de apólice de seguro',
      'ReportAlertsMonitoringProcessesCheckSell': 'Aviso de divergência de venda',
      'ReportAlertsMonitoringProcessesCheckDebitNote': 'Aviso de divergência de debit note',
      'ReportAlertsMonitoringProcessesCheckAirInfo': 'Aviso de divergência de dados de embarque',
      'ReportAlertsMonitoringProcessesCheckFclContract': 'Aviso de divergência de contrado de frete',
      'ReportAlertsMonitoringProcessesCheckExchangeValue': 'Aviso de divergência de valor cambial',
      'ReportAlertsMonitoringProcessesCheckDtaTaxes': 'Aviso de divergência de DTA',
      'ReportAlertsMonitoringProcessesCheckCollectFee': 'Aviso de divergência de Collect Fee'
    },
    'recompra': {
      'title': 'Recompra',
      'recompra_hc': 'Head Cargo',
      'feedback': 'Feedback',
      'recompra': 'Recompra',
      'statusList': {
        'pending': 'Aguardando Feedback',
        'rejected': 'Recusado',
        'accepted': 'Aprovado',
        'underNegotiation': 'Em negociação',
        'all': 'Todos'
      }
    },
    'alerts': {
      'pricing': {
        'title': 'Pricing',
        'solicitacoes_pendentes': '01.Solicitações Pendentes',
        'solicitacoes_pendentes_sem_data_solicitacao': '02.Solicitações Pendentes sem "Data de solicitação cliente"'
      },
      'routing-order': {
        'title': 'Routing Order',
        'processos_sem_remessa_agente': '1.Processos sem "REMESSA AGENTE"',
        'processos_taxas_remessa_agente_sem_faturamento': '2.Processos com taxas "REMESSA AGENTE" "SEM FATURAMENTO"'
      },
      'operacionais': {
        'title': 'Operacionais',
        'aviso_pendencia_seguro': '01.Aviso de pendência de seguro',
        'aviso_ce_pendente': '02.Aviso CE pendente',
        'aviso_hbl_prepaid_vat': '03.Aviso HBL Prepaid/VAT',
        'aviso_pendencia_redestinacao': '04.Aviso pendência redestinação',
        'mbl_courier_nao_recebido': '05.MBL ou courier não recebido',
        'thc_vazio': '06.THC vazio',
        'processos_atencao': '07.Processos atenção',
        'processos_sem_arquivo': '08.Processos sem arquivo',
        'processos_sem_acompanhamento': '09.Processos sem acompanhamento',
        'processos_pendencia_taxas': '10.Processo com pendência de taxas',
        'aviso_divergencia_debit_note': '11.Aviso Divergência Débit Note',
        'processos_sem_remessa_agente': '12.Processos sem "REMESSA AGENTE"',
        'processos_taxas_remessa_agente_sem_faturamento': '13.Processos com taxas "REMESSA AGENTE" "SEM FATURAMENTO"',
        'faturas_abertas_em_aereo': '14.Faturas abertas EM e Aereo',
        'processos_criticos': '15. Processos críticos',
        'processos_pendencia_envio_cct': '16. Processos sem envio de CCT',
        'processos_embarque_desembarque_futuro': '17.Processos com data de embarque ou desembarque no futuro',
        'processos_sem_liberacao': '18.Processos sem liberação',
        'processos_pendencia_mrcu': '19.Aviso de MRUC não lançado',
        'processos_pendentes': '20.Processos pendentes no sistema',
        'processos_pendencia_mrcu_file': '21.Aviso de MRUC arquivo não anexado'
      },
      'controle_dead_lines': {
        'title': 'Controle de Dead Lines',
        'atraso_recebimento_draft': '01.Atraso de recebimento de Draft - EM',
        'atraso_envio_draft_armador': '02.Atraso de envio de draft para armador - EM',
        'envio_vgm': '03.Envio do VGM'
      },
      'financeiros': {
        'title': 'Financeiros',
        'demurrage': '01.Processos Demurrage',
        'prejuizo': '02.Processos no Prejuízo',
        'faturas_abertas_armador': '03.Faturas abertas armador',
        'iof_zerado': '04.Processos com IOF Zerado'
      },
      'contratos': {
        'title': 'Contratos',
        'processos-x-contratos': 'Processos X Contratos'
      },
      'comerciais': {
        'title': 'Comerciais',
        'free_time_compra_menor_venda': '01.Free Time de compra é menor que Free Time de venda',
        'sem_free_time_divergente': '02.Processos sem FT e/ou divergente ',
        'divergencia_taxas_locais': '03.Processos com divergência de "TAXAS LOCAIS"',
        'taxas_locais_sem_cobranca': '04.Processos com recebimento de taxas do cliente “Sem cobrança”',
        'solicitacoes_revisao_is_pendente': '05.Propostas Aguardando Revisão do time Comercial',
        'processos_cmf_frete_prejuizo': '06.Processos com taxa CMF com frete menor que a compra'
      },
      'title': 'Alertas',
      'ReportAlertsProcessesCheckLocalTariffs': 'Processos com divergência de "TAXAS LOCAIS"',
      'ReportAlertsProcessesCheckTaxesWithoutReceipt': 'Processos com recebimento de taxas do cliente “Sem cobrança”'
    },
    'audit': {
      'title': 'Auditoria',
      'processos': {
        'title': 'Processos'
      }
    },
    'eroyal': {
      'title': 'eRoyal - Indicadores',
      'portalAccess': {
        'title': 'Acessos do portal'
      }
    },
    'report_comercial': {
      'title': 'Relatório Comercial',
      'general': 'Geral',
      'activities': 'Atividades',
      'opened processes RO': 'Relatório Semanal Fechamento RO ',
      'insight': 'Insight Vendas'
    },
    'opened_processes_by_seller': {
      'general': 'Geral por agentes',
      'answerable': 'Geral por responsável',
      'general_product': 'Geral por produto',
      'details_product': 'Geral por produto e agente',
      'details': 'Detalhado',
      'weekly': 'Resumo'
    },
    'financial': {
      'title': 'Relatório Financeiro'
    },
    'seller': {
      'title': 'Acompanhamento de metas',
      'monthly': {
        'title': 'Acompanhamento de metas / Mensal'
      },
      'quarter': {
        'title': 'Acompanhamento de metas / Trimestral'
      }
    },
    'statement': {
      'commercial': {
        'title': 'Extrato Comercial'
      },
      'financial': {
        'title': 'Extrato Financeiro'
      }
    },
    'internal': {
      'title': 'Indicadores Internos'
    },
    'report_system': {
      'title': 'Utilização do Sistema',
      'period': 'Período (gráficos)',
      'customer_label': 'Cliente (tabela)',
      'historical_usage': 'Usuários no período',
      'user_usage': '15 usuários com mais acessos no período',
      'user_usage_request': '15 usuários mais ativos (por request)',
      'customer_usage': '15 clientes com mais acessos no período',
      'customer_usage_request': '15 clientes  mais ativos (por request)',
      'customer_last_access': 'Últimos acessos (todos os clientes, este ano)'
    },
    'tariff_indicators': {
      'title': 'Utilização do envio de tarifários',
      'sent': 'Tarifários que estão sendo enviados',
      'quantity-sent-by-seller': 'Quantidade cadastrada no período - por vendedor responsável',
      'quantity-sent-by-user': 'Quantidade cadastrada no período - por usuário do sistema',
      'not-sent-by-values': 'Não enviados por falta de valores',
      'short-sent': 'Sendo enviados',
      'short-sent-by-seller': 'Cadastrados (vendedor)',
      'short-sent-by-user': 'Cadastrados (usuário)',
      'short-not-sent-by-values': 'Não enviados'
    }
  },
  'dashboard': {
    'title': 'Dashboard',
    'carousel': {
      'title': 'Carrossel',
      'new-edit': 'Registro de Carrossel'
    },
    'filters': {
      'weekly': 'Semanal',
      'monthly': 'Mensal',
      'Quarter': 'Trimestral',
      'yearly': 'Anual'
    },
    'id': 'ID',
    'image': 'Imagem',
    'titulo': 'Título',
    'tela-inicial': 'Tela Inicial',
    'status': 'Situação',
    'inativo': 'Inativo',
    'ativo': 'Ativo'
  },
  'date': {
    'month': {
      'Jan': 'Jan',
      'Feb': 'Fev',
      'Mar': 'Mar',
      'Apr': 'Abr',
      'May': 'Maio',
      'Jun': 'Jun',
      'Jul': 'Jul',
      'Aug': 'Ago',
      'Sep': 'Set',
      'Oct': 'Out',
      'Nov': 'Nov',
      'Dec': 'Dez',
      'January': 'Janeiro',
      'February': 'Fevereiro',
      'March': 'Março',
      'April': 'Abril',
      // 'May': 'Maio',
      'June': 'Junho',
      'July': 'Julho',
      'August': 'Agosto',
      'September': 'Setembro',
      'October': 'Outubro',
      'November': 'Novembro',
      'December': 'Dezembro'
    }
  },
  'gender': {
    'male': 'Masculino',
    'female': 'Feminino'
  },
  'filter': {
    'apply': 'Filtrar',
    'reset': 'Limpar',
    'Filter_by': 'Filtrar por',
    'enter_text': 'Digite para filtrar',
    'Receipt_date': 'Data de Recebimento',
    'Start': 'Inicial',
    'End': 'Final',
    'Apply_filter': 'Aplicar filtro'
  },
  'form': {
    'tier_index': {
      'percentualAcrescimo': 'Percentual Acréscimo'
    },
    'indiceFilaImportacao': {
      'tradeLaneOrigem': 'Trade Lane Origem',
      'tradeLaneDestino': 'Trade Lane Destino'
    },
    'tradeLane': {
      'label': 'Trade Lane'
    },
    'tarifarioCotacao': {
      'numeroContrato': 'Número Contrato',
      'criadoPor': 'Criado Por',
      'criadoEm': 'Criado Em'
    },
    'oportunidade': {
      'tipoCarga': 'Tipo Carga',
      'numeroOportunidade': 'Número Oportunidade',
      'numeroContrato': 'Número Contrato',
      'responsavel': 'Responsável',
      'solicitante': 'Solicitante',
      'produto': 'Produto',
      'cliente': 'Cliente',
      'origem': 'Origem',
      'destino': 'Destino',
      'idmoeda': 'Moeda',
      'mercadoria': 'Mercadoria',
      'validade_target': 'Validade Target',
      'volumeTotal': 'Volume Total',
      'volumePotencial': 'Volume Potencial',
      'unidadeMedida': 'Unidade Medida',
      'idunidadeMedidaVolume': 'Unidade',
      'status': 'Status',
      'frequencia': 'Frequência',
      'target': 'Target',
      'idunidadeMedidaTarget': 'Unidade Target',
      'statusList': {
        'aguardando': 'Aguardando',
        'trabalhando': 'Trabalhando',
        'disponivel': 'Opção encontrada',
        'indisponivel': 'Nenhuma opção encontrada',
        'expirado': 'Expirado'
      }
    },
    'tarifario_lcl': {
      'section': {
        'rota': 'Por Rota',
        'taxas': 'Taxas'
      },
      'numero': 'Número',
      'tipo_operacao': 'Operação',
      'moeda': 'Moeda',
      'numero_contrato': 'Número Contrato',
      'armador': 'Armador',
      'ativo': 'Ativo',
      'visivel': 'Visível',
      'data_inicio_vigencia': 'Dt. Inicio Vigência',
      'data_validade': 'Dt. Validade'
    },
    'integrations': {
      'title': 'Integração',
      'api': {
        'title': 'API Key'
      },
      'webhooks': {
        'title': 'Webhooks',
        'token': 'API Key',
        'descricao': 'Descrição',
        'webhook': 'Webhook',
        'endpoint': 'Endpoint'
      },
      'tax': {
        'nome': 'Nome',
        'taxa': 'Taxa'
      }
    },
    'CadCompanhiaTransporte': {
      'label': 'Armador'
    },
    'dePara': {
      'de': 'De',
      'para': 'Para',
      'tipo': 'Tipo',
      'campo': 'Campo'
    },
    'tools': {
      'internal_code': 'Processo'
    },
    'filaprocesso': {
      'destinoTradeLane': 'Destino Trade Lane',
      'origemTradeLane': 'Origem Trade Lane',
      'dataSolicitacao': 'Data Solicitação',
      'nomeArquivoOriginal': 'Arquivo',
      'tipoArquivo': 'Tipo',
      'dataInicio': 'Data Início',
      'dataTermino': 'Data Termino',
      'solicitadoPor': 'Solicitado por',
      'situacao': {
        'label': 'Situação',
        'waiting': 'Aguardando',
        'running': 'Processando',
        'success': 'Concluído',
        'cancel': 'Cancelado',
        'stopped': 'Abortado',
        'error': 'Erro'
      }
    },
    'proposal': {
      'data_inicio_vigencia': 'Data Início vigência',
      'data_validade': 'Data Validade',
      'cbm': 'CBM',
      'peso_bruto': 'Peso Bruto',
      'cliente': 'Cliente',
      'desconto': 'Desconto',
      'customer': 'Cliente',
      'freeTime': 'Free Time',
      'valido_ate': 'Validade para embarque até',
      'valido_de_ate': 'Validade para embarque de <strong>{from}</strong> até <strong>{to}</strong>',
      'trasitTime': 'Transit Time Aprox.',
      'equipamento': 'Carga',
      'buscar': 'Buscar Valores',
      'status': {
        'draft': 'Rascunho',
        'waiting': 'Pré-Reserva',
        'confirmed': 'Confirmada',
        'not_confirmed': 'Não Confirmada',
        'cancel': 'Cancelada',
        'expired': 'Expirada'
      },
      'step': {
        'carga': 'Carga',
        'detalhes_carga': 'Detalhes da Carga',
        'exportador': 'Exportador',
        'importador': 'Importador',
        'notify': 'Notify',
        'notify_helper': 'Insira os dados da pessoa responsável por receber atualizações do processo.',
        'adicionais': 'Adicionais',
        'adicionais_descricao': 'Para deixar o seu embarque completo, insira os serviços extras disponíveis. Selecione abaixo as opções de interesse e entraremos em contato após o envio da pré-reserva:',
        'adicionais_complementar_help': 'Insira aqui observações gerais',
        'confirmacao': 'Confirmação',
        'confirmacao_descricao': 'Por favor, assegure-se de que os dados abaixo estão corretos. Confirme a Solicitação de Reserva quando estiver tudo ok.'
      },
      'contract_number': 'Número Contrato',
      'date_begin': 'Data Inicio da Vigência',
      'date_end': 'Data Validade',
      'shipowner': 'Armador',
      'origin': 'Origem',
      'destination': 'Destino',
      'modality': 'Modalidade',
      'incoterm': 'Incoterm',
      'operation': 'Operação',
      'commoditie': 'Mercadoria',
      'price': 'Preço',
      'sort by': 'Ordenar por',
      'equipments': 'Equipamento',
      'quantity': 'Quantidade',
      'date': 'Previsão de Embarque',
      'created_at': 'Data de Criação',
      'route': 'Rota',
      'route_direct': 'Direta',
      'shipperOwner': 'Cia. de Transporte',
      'preco_total': 'Preço total',
      'valor_total': 'Frete Total',
      'valor_cotacao': 'Valor da Cotação',
      'preco_estimado': 'Preço Aprox.',
      'preco_frete': 'Preço Frete Internacional',
      'total': 'Total',
      'referencia': 'Referência',
      'referencia_cliente': 'Sua Referência',
      'referencia_cliente_opcional': 'Sua Referência (Opcional)',
      'referencia_cliente_help': 'Utilize o código de registro do seu sistema ou crie uma identificação para o processo',
      'carga': {
        'mercadoria': 'Mercadoria',
        'ncm': 'NCM (Nomenclatura Comum do Mercosul)',
        'descricao': 'Descrição da Mercadoria',
        'quantidade': 'Quantidade de Volumes',
        'tipo_volume': 'Tipo de Volumes',
        'peso_bruto': 'Peso Bruto (kg)',
        'cbm': 'CBM',
        'peso_bruto_help': 'Mercadorias pesadas podem ter a cobrança de OWS (Overweight Surcharge)',
        'segura_naopessoal': 'Minha carga não tem temperatura controlada e não inclui qualquer mercadoria pessoal.'
      },
      'adicionais': {
        'complementar': {
          'nome': 'Informações Adicionais'
        },
        'seguro': {
          'nome': 'Seguro',
          'descricao': 'Descrição sobre como funciona o seguro',
          'valor_mercadoria': 'Valor da Mercadoria ({moeda})',
          'valor_frete': 'Valor de Frete ({moeda})',
          'valor_seguro': 'Valor do Seguro ({moeda})',
          'impostos': 'Impostos',
          'impostos_helper': 'Adicional de 10% do valor da mercadoria. Reembolso de impostos pagos pelo embarque.',
          'outras_despesas': 'Outras Despesas',
          'outras_despesas_helper': 'Adicional de 10% do valor da mercadoria. Reembolso por outras despesas previstas para o embarque.'
        }
      }
    },
    'task-campaign-delivery-restriction': {
      'contacts': 'Contatos',
      'customers': 'Clientes',
      'tipoDisparador': 'Tipo de Disparador'
    },
    'task-campaign-type': {
      'nome': 'Nome',
      'verificarCadencia': 'Verificar cadência',
      'ativo': 'Ativo'
    },
    'mail-group': {
      'nome': 'Nome',
      'email': 'E-mail',
      'ativo': 'Ativo'
    },
    'cad_pessoa': {
      'tier_frete': 'Tier Frete',
      'tier_local': 'Tier Local',
      'tier_local_expo': 'Tier Locais Expo',
      'tier_local_impo': 'Tier Locais Impo',
      'tier_frete_expo': 'Tier Frete Expo',
      'tier_frete_impo': 'Tier Frete Impo',
      'tier_local_lcl_expo': 'Tier Locais LCL Expo',
      'tier_local_lcl_impo': 'Tier Locais LCL Impo',
      'tier_frete_lcl_expo': 'Tier Frete LCL Expo',
      'tier_frete_lcl_impo': 'Tier Frete LCL Impo'
    },
    'request-access': {
      'id': 'Cód.',
      'data_solicitacao': 'Data Solicitação',
      'title': 'Aprovações Pendentes',
      'status': 'Situação',
      'nome': 'Nome',
      'email': 'E-mail Corporativo',
      'id_responsavel': 'Responsável',
      'nome_fantasia': 'Empresa',
      'statusList': {
        'pending': 'Aguardando Aprovação',
        'accepted': 'Aprovado',
        'refused': 'Recusado'
      },
      'fluxoAprovacao': 'Fluxo de Aprovação',
      'fluxoAprovacaoList': {
        'novo_prospecto': 'Novo Prospect',
        'novo_prospecto_descricao': 'Cadastramento de novo cliente',
        'novo_contato_prospecto': 'Novo Contato Prospect',
        'novo_contato_prospecto_descricao': 'Cadastramento de um contato que já exista como um tipo diferente de cliente',
        'solicitacao_acesso': 'Solicitação de Acesso',
        'solicitacao_acesso_descricao': 'Cadastramento de um novo contato para um cliente já existente'
      }
    },
    'messageAlert': {
      'action': 'Ação',
      'date': 'Data',
      'message': 'Mensagem',
      'customer': 'Cliente',
      'process': 'Processo'
    },
    'announcement': {
      'label': 'Informativo',
      'id': 'Cód.',
      'description': 'Descrição',
      'beginAt': 'Início',
      'finishAt': 'Término',
      'content_html': 'Conteúdo',
      'show_only_to': 'Exibir apenas para'
    },
    'mail': {
      'id': 'ID',
      'type': 'Tipo',
      'subject': 'Assunto',
      'email': 'E-mail',
      'created_at': 'Data',
      'toSendDate': 'Enviar às',
      'sentDate': 'Enviado às',
      'status': {
        'title': 'Status',
        'waiting': 'Aguardando',
        'sent-email-queue': 'Adicionado no disparador de e-mail',
        'sent': 'Enviado',
        'error': 'Erro',
        'active': 'Ativo',
        'inactive': 'Inativo'
      },
      'typeList': {
        'sender': 'De',
        'recipient': 'Para',
        'cc': 'Cc',
        'cco': 'Cco'
      },
      'statusList': {
        'pending': 'Pendente',
        'processed': 'Processado',
        'dropped': 'Dropped',
        'delivered': 'Entregre',
        'error': 'Erro',
        'bounce': 'Bounce',
        'blocked': 'Blocked',
        'open': 'Aberto',
        'click': 'Click',
        'spamreport': 'Spam Report',
        'unsubscribe': 'Unsubscribe',
        'group_unsubscribe': 'Group Unsubscribe',
        'group_resubscribe': 'Group Resubscribe',
        'deferred': 'Deferred'
      }
    },
    'person': {
      'cnpj_vatnumber': 'CNPJ/VAT Number',
      'nome': 'Nome da Empresa',
      'pais': 'País',
      'estado': 'Estado',
      'cidade': 'Cidade',
      'cepZipcode': 'CEP/ZIP Code',
      'logradouro': 'Endereço',
      'contato_nome': 'Nome',
      'email': 'E-mail',
      'telefone': 'Telefone'
    },
    'customer': {
      'label': 'Empresa',
      'name': 'Nome',
      'cpfCnpj': 'CNPJ/CPF'
    },
    'employee': {
      'label': 'Colaborador',
      'name': 'Nome'
    },
    'choice': {
      'select': 'Selecione...'
    },
    'organization': {
      'name': 'Empresa',
      'email': 'E-mail Corporativo',
      'setor': 'Setor',
      'cargo': 'Cargo',
      'phone': 'Telefone',
      'ramal': 'Ramal',
      'address': 'Endereço',
      'website': 'Website'
    },
    'billingPrefenrece': {
      'codigo': 'Código',
      'nome': 'Nome',
      'personDefault': 'Pessoal Padrão',
      'movementType': 'Tipo de Movimentação',
      'personDefaultRod': 'Pessoal Padrão Rodoviário',
      'movementTypeRod': 'Tipo de Movimentação Rodoviário',
      'isTaxPrefered': 'Taxa Preferida'
    },
    'movementType': {
      'taxes': 'Taxas',
      'variableField': {
        'numberProcess': 'Número do Processo',
        'hawbOrhbl': 'Label HAWB ou HBL',
        'referenceTaker': 'Referência do Cliente',
        'numberHBL': 'HAWB/HBL',
        'billNumber': 'Número da Nota Fiscal',
        'serie': 'Série',
        'emissionDateTime': 'Data de Emissão',
        'competence': 'Competência',
        'takerId': 'ID do Tomador',
        'customerId': 'ID do Cliente',
        'takerType': 'Tipo do Tomador',
        'takerDoc': 'CNPJ/CPF',
        'takerName': 'Nome do Tomador',
        'takerAddress': 'Endereço do Tomador',
        'takerZipCode': 'CEP do Tomador',
        'takerMunicipalReg': 'Municipio do Tomador',
        'takerStateReg': 'Estado do Tomador',
        'takerPhone': 'Telefone do Tomador',
        'takerDistrict': 'Bairro do Tomador',
        'takerCity': 'Cidade do Tomador',
        'takerState': 'Estado do Tomador',
        'takerEmail': 'E-mail do Tomador',
        'totalAmount': 'Total',
        'servicosDesc': 'Descrição',
        'servicoCode': 'Código do Serviço',
        'servicoDesc': 'Descrição do Serviço',
        'operationNatureCode': 'Código da Operação',
        'operationNatureDesc': 'Descrição da Operação',
        'placeCity': 'Cidade',
        'placeState': 'Estado',
        'valueServices': 'Valor do Serviço',
        'calculationBasis': 'Base de Calculo',
        'ISSrate': 'Aliquota ISS',
        'ISSvalueWithheld': 'Valor ISS Retido',
        'ISSvalue': 'Valor do ISS',
        'unconditionalDiscount': 'Desconto Incondicional',
        'conditionalDiscount': 'Desconto Condicional',
        'PISvalue': 'Valor PIS',
        'COFINSvalue': 'Valor COFINS',
        'INSSamount': 'Valor INSS',
        'IRvalue': 'Valor IR',
        'CSLLamount': 'Valor CSLL',
        'otherWithholdings': 'Outras Retenções',
        'deductionsAmount': 'Total de Retenção',
        'netValue': 'Net Value',
        'complementaryInfo': 'Informações Complementares',
        'otherInfo': 'Outras Informações',
        'status': 'Situação'
      },
      'calculationBasis': 'Base de Calculo',
      'ISSaliquot': 'Aliquote ISS',
      'deductionAmount': 'Valor Dedução',
      'PISCalculationBase': 'Base Calculo PIS',
      'COFINSCalculationBase': 'Base Calculo COFINS',
      'CSLLCalculationBase': 'Base Calculo CSLL',
      'INSSCalculationBase': 'Base Calculo INSS',
      'IRCalculationBase': 'Base Calculo IR',
      'otherWithholdings': 'Outras Retenções',
      'unconditionalDiscount': 'Desconto Incondicional',
      'ISSvalueWithheld': 'Valor ISS retido',
      'ISSWithheld': 'ISS Retido',
      'PISAliquot': 'Aliquota PIS',
      'COFINSAliquot': 'Aliquota COFINS',
      'CSLLAliquot': 'Aliquota CSLL',
      'INSSAliquot': 'Aliquota INSS',
      'IRAliquot': 'Aliquota IR',
      'totalRetention': 'Total Retenção',
      'conditionalDiscount': 'Desconto Condicional',
      'ISSValue': 'Valor ISS',
      'ISSAmountRetained': 'Valor ISS Retido',
      'PISValue': 'Valor PIS',
      'COFINSValue': 'Valor COFINS',
      'CSLLAmount': 'Valor CSLL',
      'INSSAmount': 'Valor INSS',
      'IRValue': 'Valor IR',
      'buttonInsertField': 'Inserir campo',
      'status': {
        'active': 'Ativos',
        'inactive': 'Inativos'
      },
      'component': 'Componentes de Cálculo',
      'actions': 'Ações',
      'id': 'ID',
      'description': 'Descrição',
      'isSIMPLES': 'Optante Simples Nacional',
      'isCultural': 'Incentivador Cultural',
      'id_servico': 'Serviço',
      'id_natureza_operacao': 'Natureza da Operação',
      'id_regime_tributario': 'Regime Tributário',
      'personDefault': 'Pessoa Padrão',
      'allowGrouping': 'Permitir Agrupamento',
      'descriptionInvoice': 'Descritivo por fatura processo Marítimo/Rodoviário para a Nota Fiscal',
      'descriptionAirInvoice': 'Descritivo por fatura processo Aéreo para a Nota Fiscal',
      'descriptionFixedInvoice': 'Descritivo fixo para a Nota Fiscal',
      'service': 'Serviço',
      'taxsDeafult': 'Taxas Padrões',
      'taxDeafult': 'Taxa Padrões'
    },
    'contact': {
      'name': 'Nome',
      'subject': 'Assunto',
      'message': 'Mensagem',
      'taxes': 'Taxas'
    },
    'invoices': {
      'actions': 'Ações',
      'id': 'ID',
      'bill_number': 'Número Nf-s',
      'serie': 'Serie Nf-s',
      'internal_code': 'Processo',
      'reference_code': 'Referencia',
      'customer_reference': 'Referência Cliente',
      'customer': 'Cliente',
      'customer_invoice': 'Pessoa da Fatura',
      'customer_pay_slip': 'Pessoa do Recibo',
      'movement_type': 'Tipo de Movimentação',
      'invoice_status': 'Situação',
      'invoice_date': 'Data da Baixa',
      'process_finished_date': 'Data Fechamento',
      'invoice_transmission': 'Data da Emissão',
      'invoice_number': 'Número NF-s',
      'bl_house': 'HBL',
      'period_time_arrival': 'Período de Embarque',
      'actual_time_arrival': 'Embarque',
      'period_time_departure': 'Período de Desembarque',
      'actual_time_departure': 'Desembarque',
      'estimated_due_date': 'Vencimento',
      'currency_initials': 'Moeda',
      'estimated_value': 'Valor previsto',
      'amount_real': 'Valor Total',
      'status': {
        'title': 'Status',
        'paid': 'Paga',
        'finish': 'Finalizada',
        'cancel': 'Cancelado',
        'parcial': 'Parcialmente Quitado',
        'overdue': 'Vencida',
        'opened': 'Pendente',
        'morethanfive': 'Menos de 10 dias para pagamento',
        'morethanone': 'Menos de 5 dias para pagamento',
        'embarcado': 'Embarcado',
        'aguardando_embarque': 'Aguardando Embarque',
        'today': 'Vence hoje',
        'AguardandoAutorizacao': 'Aguardando Autorização',
        'SolicitandoAutorizacao': 'Solicitando Autorização',
        'AutorizacaoSolicitada': 'Autorização Solicitada',
        'EmProcessoDeAutorizacao': 'Em Processo de Autorização',
        'AutorizadaAguardandoGeracaoPDF': 'Autorizada Aguardando Geração de PDF',
        'EmProcessoDeCancelamento': 'Em Processo de Cancelamento',
        'CanceladaPortal': 'Cancelada (Portal)',
        'Autorizada': 'Autorizada',
        'Negada': 'Negada',
        'Emitida': 'Emitida',
        'Cancelada': 'Cancelada',
        'Erro': 'Erro'
      }
    },
    'task': {
      'is_task_tariff_bulk': 'Criado em massa?',
      'customer': 'Cliente',
      'created_by': 'Criado Por',
      'updated_at': 'Última alteração',
      'seller': 'Vendedor',
      'mailType': 'Tipo',
      'isTestSend': 'Período de testes',
      'status': {
        'title': 'Status',
        'active': 'Ativo',
        'inactive': 'Inativo',
        'delivered': 'Entregue',
        'expired': 'Expirado',
        'pending': 'Aguardando',
        'opened': 'Aberto',
        'bounced': 'Bounced',
        'in progress': 'Em andamento',
        'complete': 'Concluído',
        'error': 'Erro'
      }
    },
    'taskRun': {
      'date': 'Data',
      'created_at': 'Data Criação',
      'finished_at': 'Data Execução',
      'files': 'Arquivos'
    },
    'taskWhatsapp': {
      'name': 'Nome',
      'phone': 'Telefone',
      'sent_at': 'Enviado',
      'received_at': 'Recebido',
      'read_at': 'Lido',
      'answered': 'Respondido'
    },
    'taskSend': {
      'contact_name': 'Contato',
      'contact_email': 'E-mail',
      'customer_name': 'Empresa',
      'send_date': 'Enviado em',
      'delivery_date': 'Recebido em',
      'opened_date': 'Lido em',
      'status': 'Situação'
    },
    'process': {
      'agenciamentoCarga': {
        'status': {
          'processo_aberto': 'Processo Aberto',
          'em_andamento': 'Em andamento',
          'liberado_faturamento': 'Liberado Faturamento',
          'faturado': 'Faturado',
          'finalizado': 'Finalizado',
          'auditado': 'Auditado',
          'cancelado': 'Cancelado'
        }
      },
      'id': 'ID',
      'internal_code': 'Processo',
      'reference_code': 'Referencia',
      'customer_name': 'Cliente',
      'customer_reference': 'Referência Cliente',
      'bl_house': 'HBL',
      'period_time_arrival': 'Período de Embarque',
      'actual_time_arrival': 'ETA/ATA',
      'data_abertura': 'Data de Abertura',
      'diferenca_transit_time': 'Diferença Transit Time',
      'armador': 'Armador',
      'eta': 'ETA',
      'etd': 'ETD',
      'lucro_momento': 'Lucro Momento',
      'data_embarque': 'Data de Embarque',
      'data_desembarque': 'Data de Desembarque',
      'possui_prazo': 'Possui Prazo',
      'data_recebimento_local': 'Data Recebimento local',
      'period_time_departure': 'Período de Desembarque',
      'actual_time_departure': 'ETD/ATD',
      'estimated_due_date': 'Vencimento',
      'currency_initials': 'Moeda',
      'total_caontainers': 'Total de containers',
      'estimated_value': 'Valor previsto',
      'boarding_status': 'Situação do Embarque',
      'status': {
        'title': 'Status',
        'opened': 'Pendente',
        'paid': 'Paga',
        'overdue': 'Atraso',
        'open': 'Abertos',
        'awaiting_shipping': 'Aguardando Embarque',
        'toBoard': 'À embarcar',
        'boarded': 'Embarcados',
        'landed': 'Desembarcados',
        'paided': 'Pagos',
        'finalized': 'Finalizados'
      }
    },
    'users': {
      'id': 'ID',
      'bio': 'Biografia',
      'name': 'Nome',
      'email': 'E-mail',
      'customer': 'Empresa',
      'status': 'Ativo',
      'phone': 'Telefone',
      'address': 'Endereço',
      'is_moderator': 'Moderador',
      'birthdate': 'Data de Nascimento',
      'last_login': 'Último acesso',
      'last_name': 'Sobrenome',
      'gender': 'Sexo'
    },
    'mailQueue': {
      'id': 'ID',
      'status': 'Status',
      'type': 'Tipo',
      'send_now': 'Enviar',
      'toSendDate': 'Enviar em',
      'sentDate': 'Enviado às'
    },
    'template': {
      'type': 'Tipo de template',
      'idiom': 'Idioma',
      'description': 'Descrição',
      'subject': 'Assunto',
      'content_html': 'Conteúdo (Html)',
      'content_text': 'Conteúdo (Text)'
    },
    'frequency': {
      'name': 'Nome',
      'intervals': 'Número de intervalos',
      'description': 'Descrição'
    },
    'typecargo': {
      'name': 'Nome',
      'description': 'Descrição'
    },
    'typeoperation': {
      'name': 'Nome',
      'description': 'Descrição'
    },
    'tier': {
      'code': 'Código',
      'value': 'Valor',
      'currency': 'Moeda',
      'typeList': {
        'title': 'Tipo',
        '1': 'Fixo',
        '2': 'Percentual',
        '3': 'LCL - Faixa'
      },
      'type': 'Tipo',
      'idOrigemDestinoGrupo': 'Origem/Destino Grupo',
      'ranges': 'Valor por Faixa',
      'tipoCarga': 'Tipo Carga'
    },
    'tierLocal': {
      'section': {
        'general': 'Geral',
        'taxes': 'Taxas',
        'desconsolidacao': 'Desconsolidação'
      },
      'taxa': {
        'nome': 'Nome',
        'valor': 'Valor'
      },
      'codigo': 'Código',
      'tipoOperacao': 'Operação',
      'tipoCarga': 'Tipo Carga',
      'tipoOperacaoList': {
        '1': 'Exportação',
        '2': 'Importação'
      },
      'tipoCargaList': {
        '3': 'FCL',
        '4': 'LCL'
      },
      'desconsolidacaoMoeda': 'Moeda',
      'desconsolidacaoValor': 'Valor'
    },
    'tierArmador': {
      'section': {
        'general': 'Geral',
        'frete': 'Frete'
      },
      'codigo': 'Código',
      'tipoOperacao': 'Operação',
      'modalidadeProcesso': 'Modalidade',
      'tipoOperacaoList': {
        '1': 'Exportação',
        '2': 'Importação'
      },
      'freteMoeda': 'Moeda',
      'freteValor': 'Valor'
    },
    'currency': {
      'name': 'Name',
      'moeda': 'Moeda'
    },
    'indicator': {
      'name': 'Nome',
      'description': 'Descrição'
    },
    'goal': {
      'percentage': 'Porcentagem',
      'employee': 'Vendedor',
      'idTypeOperation': 'Operação',
      'modal': 'Modal',
      'period': 'Período',
      'value': 'Valor',
      'idIndicator': 'Indicador',
      'idTypeCargo': 'Tipo de Carga',
      'section': {
        'T-': 'Rodoviário Impo & Expo',
        'NT-': 'Rodoviário Nacional',
        'NM-': 'Cabotagem',
        'IA-Aereo': 'Aéreo Impo',
        'EA-Aereo': 'Aéreo Expo',
        'A-Aereo': 'Aéreo Impo & Expo',
        'EM-FCL': 'FCL Expo',
        'IM-FCL': 'FCL Impo',
        'M-LCL': 'LCL Impo & Expo',
        '-': 'Geral'
      },
      'idFrequency': {
        'label': 'Frequência',
        'yearly': {
          '1': 'Total (Ano)'
        },
        'quarter': {
          '1': 'Q1',
          '2': 'Q2',
          '3': 'Q3',
          '4': 'Q4'
        },
        'monthly': {
          '1': 'Jan',
          '2': 'Fev',
          '3': 'Mar',
          '4': 'Abr',
          '5': 'Mai',
          '6': 'Jun',
          '7': 'Jul',
          '8': 'Ago',
          '9': 'Set',
          '10': 'Out',
          '11': 'Nov',
          '12': 'Dez'
        }
      },
      'year': 'Ano',
      'valueYear': 'Valor Anual',
      'modal_options': {
        'aereo': 'Aéreo',
        'maritimo': 'Marítimo',
        'terrestre': 'Terrestre'
      },
      'frequencyGoals': 'Períodos',
      'level': 'Nível',
      'commission': 'Comissão',
      'type': 'Tipo'
    },
    'frequencyGoals': {
      'period': 'Período',
      'value_period': 'Valor',
      'percent_period': 'Percentual(%)'
    },
    'register': {
      'customer': 'Empresa',
      'name': 'Nome',
      'last_name': 'Sobrenome',
      'accept-terms': 'Eu li e aceito os termos'
    },
    'resetting': {
      'new-password': 'Nova senha',
      'confirm-new-password': 'Confirmar nova senha'
    },
    'report': {
      'year': 'Ano',
      'month': 'Mês',
      'week': 'Semana',
      'quarter': 'Trimestre',
      'seller': 'Vendedor',
      'employee': 'Colaborador',
      'type': 'Tipo'
    },
    'cupom': {
      'dataInicioVigencia': 'Início Vigência',
      'dataValidade': 'Data Término',
      'tipoCupom': 'Campanha',
      'moeda': 'Moeda',
      'cliente': 'Cliente',
      'valor': 'Valor',
      'section': {
        'cliente': 'Cliente',
        'periodo': 'Período',
        'cupom': 'Cupom'
      }
    },
    'configuracao': {
      'title': 'Configurações'
    },
    'tipo-cupom': {
      'section': {
        'configuracoes': 'Configurações'
      },
      'titulo': 'Título',
      'descricao': 'Descrição',
      'automatico': 'Automático',
      'ativo': 'Ativo',
      'duracaoDias': 'Duração em dias',
      'dataInicioVigencia': 'Data início da Campanha',
      'dataValidade': 'Data Término da Campanha',
      'tipoCliente': 'Tipo Cliente',
      'cliente': 'Cliente',
      'exportador': 'Exportador',
      'importador': 'Importador',
      'setor': 'Segmentação'
    },
    'lcl': {
      'numeroContrato': 'Contrato',
      'dataInicioVigencia': 'Início vigência',
      'dataValidade': 'Validade'
    },
    'booking-control': {
      'id': 'ID',
      'armador': 'Armador',
      'cliente': 'Cliente',
      'vendedor': 'Vendedor',
      'solicitante': 'Solicitante',
      'referencia_interna': 'Referência Interna',
      'numero': 'Número',
      'origem': 'Origem',
      'destino': 'Destino',
      'navio': 'Navio',
      'mercadoria': 'Mercadoria',
      'situacao': 'Situação',
      'grupo': 'Grupo',
      'processo': 'Processo',
      'contrato': 'Contrato',
      'contratos': 'Contratos',
      'semana': 'Semana',
      'etd': 'ETD',
      'ddl': 'Deadline',
      'previsao-prontidao-carga': 'Previsão Prontidão',
      'prontidao-carga': 'Prontidão Carga',
      'data-abertura-processo': 'Abertura do Processo',
      'data-inclusao-processo': 'Inclusão do Processo',
      'data_criacao': 'Data criação',
      'data_solicitacao': 'Data Solicitação',
      'equipamentos': 'Equipamentos',
      'teus_contratados': 'TEUs Contratados',
      'teus_disponiveis': 'TEUs Disponíveis',
      'teus_utilizados': 'TEUs Utilizados',
      'opcoes': 'Opções',
      'status': {
        'solicitar': 'Solicitar',
        'solicitado': 'Solicitados',
        'disponivel': 'Disponíveis',
        'confirmado': 'Confirmados',
        'rejeitado': 'Rejeitados',
        'utilizando': 'Utilizando',
        'utilizado': 'Utilizados',
        'cancelado': 'Cancelados',
        'solicitacoes': 'Solicitações',
        'meus-pedidos': 'Meus Pedidos',
        'overbooking': 'Overbookings',
        'stillavailable': 'Disponíveis',
        'completed': 'Completos'
      }
    },
    'valuation': {
      'id': 'Id',
      'name': 'Nome',
      'type': 'Tipo',
      'status': 'Situação',
      'date': 'Data',
      'creating-date': 'Data de criação',
      'origin': 'De',
      'target': 'Para',
      'teams': 'Equipes',
      'protagonists': 'Protagonistas',
      'weight': 'Peso',
      'defaultWorth': 'Peso Padrão',
      'description': 'Descrição'
    },
    'team': {
      'id': 'Id',
      'name': 'Nome',
      'description': 'Descrição',
      'moderator': 'Moderador',
      'moderators': 'Moderadores',
      'status': 'Situação'
    }
  },
  'mail_error': {
    'email': {
      'subject': 'Erro ao realizar o envio do %type%!',
      'message': '| Olá,' +
      'Não consegui realizar o envio do e-mail %type% ID: %id% solicitado em %toSendDate%' +
      'Você pode verificar em %url%' +
      'Atenciosamente.'
    }
  },
  'mail_alert': {
    'email': {
      'subject': 'Envio do %type% ainda não foi realizado',
      'message': '|' +
        'Olá,' +
        'O envio do e-mail %type% ID: %id% solicitado em %toSendDate% ainda não foi realizado!' +
        'Está tudo bem?' +
        'Você pode verificar em %url%' +
        'Atenciosamente.'
    }
  },
  'follow-up': {
    'opening_date': {
      'title': 'Abertura',
      'message': 'Processo aberto.'
    },
    'estimate_time_arrival': {
      'title': 'Aguardando embarque',
      'message': 'Processo aguardando embarque.'
    },
    'actual_time_arrival': {
      'title': 'Embarcado',
      'message': 'Processo embarcado.'
    },
    'actual_time_departure': {
      'title': 'Desembarque',
      'message': 'Desembarque realizado.'
    },
    'siscarga_release_time': {
      'title': 'Siscarga',
      'message': 'Data de Liberação no Siscarga.'
    },
    'pay_date': {
      'title': 'Pagamento',
      'message': 'Pagamento realizado.'
    },
    'finish_date': {
      'title': 'Processo encerrado',
      'message': 'Processo finalizado com sucesso!'
    }
  },
  'taskCampaign': {
    'fields': {
      'Processos_Abertos_Modalidade_Processo': 'Modalidade',
      'Processos_Abertos_Tipo_Operacao': 'Tipo Operação',
      'Propostas_Abertas_Modalidade_Processo': 'Modalidade',
      'Propostas_Abertas_Tipo_Operacao': 'Tipo Operação',
      'Processos_Abertos_Origem': 'Origem',
      'Processos_Abertos_Destino': 'Destino',
      'Processos_Abertos': 'Processos Abertos',
      'Situacao_Processo': 'Há Processos Abertos?',
      'Propostas_Abertas_Origem': 'Origem',
      'Propostas_Abertas_Destino': 'Destino',
      'Propostas_Abertas': 'Propostas Abertas',
      'Situacao_Proposta': 'Há Propostas Abertas?',
      'Destino_Processos_Abertos': 'Destino',
      'Origem_Processos_Abertos': 'Origem',
      'Historico_Origem': 'Origem',
      'Historico_Origem_Pais': 'País Origem',
      'Historico_Destino_Pais': 'País Destino',
      'Processos_Abertos_Origem_Pais': 'País Origem',
      'Processos_Abertos_Destino_Pais': 'País Destino',
      'Propostas_Abertas_Origem_Pais': 'País Origem',
      'Propostas_Abertas_Destino_Pais': 'País Destino',
      'Historico_Destino': 'Destino',
      'Dias_ultimo_contato_automatico': 'Dias último Contato Automático',
      'Data_Ultimo_Tarifario_Automatico_Enviado': 'Data último Tarifário Automático',
      'Dias_Ultimo_Tarifario_Automatico_Enviado': 'Dias último Tarifário Automático',
      'Fez_alguma_cotacao_online': 'Fez alguma cotação online',
      'Usuario_ativo_portal': 'Usuário ativo eRoyal',
      'Usuario_cotacao_ativa': 'Cotação online ativa',
      'Dias_Criacao_Cliente': 'Dias criação cliente',
      'Data_Criacao_Cliente': 'Data criação cliente',
      'Dias_Ult_Acm': 'Dias último acompanhamento',
      'Data_Ult_Acm': 'Data último acompanhamento',
      'Fornecedor_Inteligencia_Logistica': 'Fornecedor Inteligência Logística',
      'Importador': 'Importador',
      'Exportador': 'Exportador',
      'Decisor_Frete': 'Decisor de Frete',
      'nulo': 'Nulo',
      'Contato_primeiro_nome': 'Contato primeiro nome',
      'hoje': 'Data do envio',
      'dia_mes': 'Dia/mês do envio',
      'Municipio': 'Município',
      'UF': 'Estado',
      'Pais': 'País',
      'Qualificacao': 'Qualificação',
      'Grupo_Tarefa': 'Grupo de Tarefa',
      'Tipo': 'Tipo - informações CRM ',
      'Vendedor_Responsavel_id': 'Vendedor ID',
      'Mercadoria': 'Mercadoria',
      'Volume': 'Volume',
      'Segmentacao': 'Segmentação',
      'Tipo_Cliente': 'Tipo de Cliente',
      'CRM_Modal': 'Modal',
      'Origem': 'Origem',
      'Destino': 'Destino',
      'Empresa_Sistema_id': 'Id Empresa',
      'Empresa_Sistema_Nome': 'Empresa',
      'Vendedor_Responsavel_nome': 'Vendedor',
      'Cliente_Id': 'Código do cliente',
      'Cliente_idioma': 'Idioma do cliente',
      'Cliente_Nome': 'Nome do cliente',
      'Contato_id': 'Contato id',
      'Contato_Nome': 'Contato nome',
      'Contato_fone': 'Contato telefone',
      'Contato_email': 'Contato e-mail',
      'Contato_data_nascimento': 'Contato Nascimento',
      'Contato_cargo_nome': 'Cargo',
      'Contato_cargo_id': 'Contato id do Cargo',
      'Embarcados_Ano_Anterior': 'Processo embarcados no ano anterior',
      'Embarcados_Ano_Vigente': 'Processo embarcados no ano vigente',
      'Percentual_Embarcados': 'Processo embarcados(%)',
      'Abertos_Ano_Vigente': 'Processos abertos no ano vigente',
      'Lucro_Momento_Ano': 'Lucro no Ano',
      'Valor_Demurrage_Aberto': 'Valor de demurrage em aberto',
      'Vendedor_Interno': 'Vendedor interno',
      'Usuario_Abertura': 'Usuário abertura',
      'Cliente': 'Cliente',
      'Data_Ultima_Visita': 'Data última visita',
      'Dias_Ultima_Visita': 'Dias última visita',
      'Data_Ultimo_Contato': 'Data último contato',
      'Dias_Ultimo_Contato': 'Dias último contato',
      'Data_Ultima_Proposta': 'Data última proposta',
      'Dias_Ultima_Proposta': 'Dias última proposta',
      'Data_Ultimo_Processo': 'Data do último processo',
      'Dias_Ultimo_Processo': 'Dias do último processo',
      'Data_Ultima_Cotacao_Online': 'Data da última cotação online',
      'Dias_Ultima_Cotacao_Online': 'Dias da última cotação online',
      'Indicacao': 'Indicação',
      'Qtd_Cotacoes': 'Quantidade de cotações',
      'Qtd_Processos_Andamento': 'Quantidade de processos em andamento',
      'Cotacoes_Aprovadas': 'Cotações aprovadas',
      'Titulo_Ult_Acm': 'Último acompanhamento Título',
      'Descricao_Ult_Acm': 'Último acompanhamento Descrição',
      'Responsavel_Acom': 'Responsável pelo último acompanhamento',
      'Data_Retorno': 'Data de retorno',
      'PosVendaTrabalhando': 'Pós-venda trabalhando',
      'PreVendaTrabalhando': 'Pré-venda trabalhando'
    },
    'step': {
      'general': 'Configurações Gerais',
      'select recipients': 'Seleção de destinatários',
      'message': 'Mensagem',
      'confirm': 'Confirmação'
    },
    'select contact rules': 'Regras de seleção de contatos',
    'select duplicated contact rules': 'Regra contatos duplicados',
    'when': 'Quando',
    'title': 'Nome do disparador',
    'event': 'Evento',
    'status': 'Ativo',
    'groupMails': 'Agrupar E-mails',
    'description': 'Descrição',
    'content': 'Conteúdo da mensagem',
    'template': 'Template',
    'tipoDisparador': 'Tipo de Disparador',
    'customer': 'Cliente',
    'contacts': 'Contatos',
    'csv file': 'Arquivo CSV'
  },
  'tools-network': {
    'title': 'Está passando por algum problema para acessar o portal?',
    'subtitle': 'Frase para informar do que se trata a ferramenta',
    'begin': 'Iniciar diagnóstico',
    'restart': 'Repetir diagnóstico'

  },
  'booking': {
    'description': 'Solicite e controle as reservas de espaço para sua carga',
    'new-edit_booking': 'Registro de Booking',
    'new-edit_request': 'Requisição de Booking',
    'new-edit_contract': 'Registro de Semana de Contrato',
    'new-edit_group': 'Registro de Grupo de Contrato',
    'form': {
      'equipment': 'Equipamento'
    },
    'containers-20': 'Containers 20\'',
    'containers-40': 'Containers 40\'',
    'total-teus': 'Total TEUS'
  },
  'valuation': {
    'aside_menu': {
      'title': 'Elogios / Críticas',
      'new': 'Informar',
      'moderate': 'Moderação',
      'config': 'Configurações'
    },
    'protagonist': 'Protagonista',
    'team': 'Equipe',
    'teams': 'Equipes',
    'new': 'Registro de relacionamento',
    'config': 'Configurações de relacionamento'
  },
  'valuation-reason': {
    'new': 'Registro de motivo de avaliação',
    'config': 'Configurações de motivo de avaliação'
  },
  'team': {
    'new': 'Registro de Equipe'
  },
  'modalidadeProcessoList': {
    '1': 'Aéreo',
    '2': 'Marítimo',
    '3': 'Rodoviário'
  },
  'tipoOperacaoList': {
    '1': 'Exportação',
    '2': 'Importação',
    '3': 'Nacional'
  },
  'soa': {
    'send-soa': {
      'title': 'Enviar SOA'
    }
  },
  'edi': {
    'menu': 'EDIs',
    'title': 'Relatórios Financeiros - EDI',
    'download': 'Download'
  }
}
