/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const KPIRouter = {
  path: '/kpi/',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'KpiIndex',
      component: () => import('@/views/Kpi/Kpi/Index'),
      hidden: true,
      meta: {
        titleHeader: false,
        titleText: 'KPI'
      }
    },
    {
      path: 'processes',
      name: 'KpiProcesses',
      component: () => import('@/views/Kpi/Kpi/Processes'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('kpi.process.title'), disabled: true }
        ]
      }
    },
    {
      path: 'volumes',
      name: 'KpiVolumes',
      component: () => import('@/views/Kpi/Kpi/Volumes'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('kpi.volume.title'), disabled: true }
        ]
      }
    },
    {
      path: 'origin-destination',
      name: 'KpiOriginDestination',
      component: () => import('@/views/Kpi/Kpi/OriginDestination'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('kpi.origin_destination.title'), disabled: true }
        ]
      }
    },
    {
      path: 'consignee-shipper',
      name: 'KpiConsigneeShipper',
      component: () => import('@/views/Kpi/Kpi/ConsigneeShipper'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('kpi.consignee_shipper.title'), disabled: true }
        ]
      }
    },
    {
      path: 'volume',
      name: 'FinantialVolume',
      component: () => import('@/views/Kpi/Finantial/Volume'),
      hidden: true,
      meta: {
        titleHeader: false,
        breadcrumbs: [
          // { text: i18n.t('kpi.finantial_position.title'), disabled: false, href: 'FinantialIndex' },
          { text: i18n.t('kpi.freight_evolution.title'), disabled: true }
        ]
      }
    },
    {
      path: 'value-freight',
      name: 'FinantialValueFreight',
      component: () => import('@/views/Kpi/Finantial/ValorFrete'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.finantial_position.title'), disabled: false, href: 'FinantialIndex' },
          { text: i18n.t('kpi.value_freight.title'), disabled: true }
        ]
      }
    },
    {
      path: 'major-carriers',
      name: 'KpiMajorCarriers',
      component: () => import('@/views/Kpi/Kpi/MajorCarriers'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('kpi.major_carriers.title'), disabled: true }
        ]
      }
    },
    {
      path: 'analyst',
      name: 'analyst',
      component: () => import('@/views/Kpi/Operational/Analyst'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.finantial_position.title'), disabled: false, href: 'FinantialIndex' },
          // { text: i18n.t('kpi.analyst.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('kpi.analyst.title'), href: 'analyst', active: true },
          { text: i18n.t('kpi.analyst.operatinalList'), href: 'operationalList', active: false },
          { text: i18n.t('kpi.analyst.embarques'), href: 'operationalEmbarques', active: false },
          { text: i18n.t('kpi.analyst.activities'), href: 'operationalActivities', active: false },
          { text: i18n.t('kpi.analyst.transfers'), href: 'operationalTransfers', active: false },
          { text: i18n.t('kpi.analyst.cancels'), href: 'operationalCancels', active: false }
        ]
      }
    },
    {
      path: 'operationalList',
      name: 'operationalList',
      component: () => import('@/views/Kpi/Operational/Analysts'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.finantial_position.title'), disabled: false, href: 'FinantialIndex' },
          // { text: i18n.t('kpi.analyst.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('kpi.analyst.title'), href: 'analyst', active: false },
          { text: i18n.t('kpi.analyst.operatinalList'), href: 'operationalList', active: true },
          { text: i18n.t('kpi.analyst.embarques'), href: 'operationalEmbarques', active: false },
          { text: i18n.t('kpi.analyst.activities'), href: 'operationalActivities', active: false },
          { text: i18n.t('kpi.analyst.transfers'), href: 'operationalTransfers', active: false },
          { text: i18n.t('kpi.analyst.cancels'), href: 'operationalCancels', active: false }
        ]
      }
    },
    {
      path: 'operationalEmbarques',
      name: 'operationalEmbarques',
      component: () => import('@/views/Kpi/Operational/Embarques'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.finantial_position.title'), disabled: false, href: 'FinantialIndex' },
          // { text: i18n.t('kpi.analyst.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('kpi.analyst.title'), href: 'analyst', active: false },
          { text: i18n.t('kpi.analyst.operatinalList'), href: 'operationalList', active: false },
          { text: i18n.t('kpi.analyst.embarques'), href: 'operationalEmbarques', active: true },
          { text: i18n.t('kpi.analyst.activities'), href: 'operationalActivities', active: false },
          { text: i18n.t('kpi.analyst.transfers'), href: 'operationalTransfers', active: false },
          { text: i18n.t('kpi.analyst.cancels'), href: 'operationalCancels', active: false }
        ]
      }
    },
    {
      path: 'operationalActivities',
      name: 'operationalActivities',
      component: () => import('@/views/Kpi/Operational/Activities'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.finantial_position.title'), disabled: false, href: 'FinantialIndex' },
          // { text: i18n.t('kpi.analyst.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('kpi.analyst.title'), href: 'analyst', active: false },
          { text: i18n.t('kpi.analyst.operatinalList'), href: 'operationalList', active: false },
          { text: i18n.t('kpi.analyst.embarques'), href: 'operationalEmbarques', active: false },
          { text: i18n.t('kpi.analyst.activities'), href: 'operationalActivities', active: true },
          { text: i18n.t('kpi.analyst.transfers'), href: 'operationalTransfers', active: false },
          { text: i18n.t('kpi.analyst.cancels'), href: 'operationalCancels', active: false }
        ]
      }
    },
    {
      path: 'operationalTransfers',
      name: 'operationalTransfers',
      component: () => import('@/views/Kpi/Operational/Transfer'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.finantial_position.title'), disabled: false, href: 'FinantialIndex' },
          // { text: i18n.t('kpi.analyst.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('kpi.analyst.title'), href: 'analyst', active: false },
          { text: i18n.t('kpi.analyst.operatinalList'), href: 'operationalList', active: false },
          { text: i18n.t('kpi.analyst.embarques'), href: 'operationalEmbarques', active: false },
          { text: i18n.t('kpi.analyst.activities'), href: 'operationalActivities', active: false },
          { text: i18n.t('kpi.analyst.transfers'), href: 'operationalTransfers', active: true },
          { text: i18n.t('kpi.analyst.cancels'), href: 'operationalCancels', active: false }
        ]
      }
    },
    {
      path: 'operationalCancels',
      name: 'operationalCancels',
      component: () => import('@/views/Kpi/Operational/Cancels'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'KPI',
        breadcrumbs: [
          // { text: i18n.t('kpi.finantial_position.title'), disabled: false, href: 'FinantialIndex' },
          // { text: i18n.t('kpi.analyst.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('kpi.analyst.title'), href: 'analyst', active: false },
          { text: i18n.t('kpi.analyst.operatinalList'), href: 'operationalList', active: false },
          { text: i18n.t('kpi.analyst.embarques'), href: 'operationalEmbarques', active: false },
          { text: i18n.t('kpi.analyst.activities'), href: 'operationalActivities', active: false },
          { text: i18n.t('kpi.analyst.transfers'), href: 'operationalTransfers', active: false },
          { text: i18n.t('kpi.analyst.cancels'), href: 'operationalCancels', active: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default KPIRouter
