<template>
  <div class="page-content page-cupom" v-if="cupons.length > 0">
    <div class="row justify-content-md-center">
      <div class="col-12">
        <div v-for="(cupom, index) in cupons" v-bind:key="index" class="card cupom " >
          <div class="card-body">
            <div class="row">
              <div class="col-12">
              <table style="height: 100px;">
                  <tbody>
                  <tr>
                    <td class="align-middle center-orientation" style="width: 100px">
                      <img src="/static/images/ticket_icon.png" alt="">
                    </td>
                    <td class="align-middle">
                      <h1 class="title" v-html="cupom.titulo"></h1>
                      <h2 class="subtitle">
                        {{ $t(cupom.descricao) }}
                      </h2>
                    </td>
                  </tr>
                  </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CupomService from '@/services/CupomService'

export default {
  name: 'CupomAlerts',
  data () {
    return {
      cupons: []
    }
  },
  mounted () {
    if (this.user) {
      this.getUserCupoms()
    }
  },
  methods: {
    getUserCupoms () {
      this.getAvailableCupons()
    },
    getAvailableCupons () {
      let _this = this
      if (this.$store.getters.userInfo) {
        CupomService.getUserCupons().then(res => {
          _this.cupons = res.data.data
          _this.schedulerCupons()
        })
      }
    },
    schedulerCupons (timeInterval) {
      timeInterval = timeInterval || 241000
      let me = this
      setTimeout(function () {
        me.getUserCupoms()
      }, timeInterval)
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    }
  },
  watch: {
    user: function () {
      this.getUserCupoms()
    }
  }
}
</script>

<style scoped>
  /* Title */
  .page-cupom {
    font-size: 0.88rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .cupom .title {
    font-size: 100%;
    min-height: 15px;
  }

  .cupom .subtitle {
    font-size: 80%;
  }

</style>
