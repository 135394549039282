import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const ToolsRouter = {
  path: '/tools',
  component: Layout,
  children: [
    {
      path: 'nfse-internacional',
      name: 'ToolsNfsInternacional',
      component: () => import('@/views/Tools/NfseInternacional'),
      hidden: true,
      meta: {
        titleHeader: false,
        titleText: i18n.t('invoice.title')
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default ToolsRouter
