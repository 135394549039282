/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const TariffRouter = {
  path: '/tariff',
  component: Layout,
  children: [
    {
      path: 'lcl',
      name: 'TariffLclIndex',
      component: () => import('@/views/Tariff/LCL/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('tariff.tariffLcl'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('tariff.tariffLcl'), disabled: true }
        ]
      }
    },
    {
      path: 'lcl/:id/edit',
      name: 'TariffLclEdit',
      component: () => import('@/views/Tariff/LCL/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('schedules.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('schedules.tariffs'), disabled: true }
        ]
      }
    },
    {
      path: 'lcl/:id/view',
      name: 'TariffLclView',
      component: () => import('@/views/Tariff/LCL/view'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('schedules.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('schedules.tariffs'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default TariffRouter
