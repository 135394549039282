import Http from '@/services/Http'
import store from '@/store'
import Cookies from 'js-cookie'
import { util } from '@/utils/utils.js'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const loginService = {
  login (data, cb) {
    let json = { username: data.username, password: data.password, remember_me: data.rememberMe }
    return new Promise(function (resolve, reject) {
      Http.post(urlAPI + 'v2/login_check', json).then(function (res) {
        var blLogin = false
        if (res.data.hasOwnProperty('token')) {
          blLogin = true
          const authUser = {}
          authUser.token = res.data.token
          global.instanceApp.$root.$store.dispatch('SetAuthUser', {
            authUser: authUser
          })

          global.instanceApp.$Cookie.remove('_proposal_last_search_')

          // Remember-me
          if (data.rememberMe) {
            global.instanceApp.$Cookie.set('_userAuth_', authUser.token, { expires: 7 })
            global.instanceApp.$Cookie.set('_userRememberMe_', true, { expires: 365 })
          } else {
            global.instanceApp.$Cookie.set('_userAuth_', authUser.token)
          }

          if (res.data.hasOwnProperty('firstLogin')) {
            blLogin = 'redirect'
          }

          let redirectSecure = global.instanceApp.$Cookie.get('_request_secure_url_')
          if (redirectSecure !== undefined) {
            global.instanceApp.$Cookie.remove('_request_secure_url_')
            blLogin = JSON.parse(redirectSecure)
          }
        } else {
          blLogin = false
          global.instanceApp.$alertSwal.modalAlert('Erro', global.instanceApp.$t(res.data.message), 'warning')
        }
        resolve(blLogin)
      }).catch(function (err) {
        reject(err)
      })
    })
  },
  logout () {
    return new Promise(function (resolve, reject) {
      localStorage.removeItem('_userAuth_')
      global.instanceApp.$Cookie.remove('_userAuth_')
      global.instanceApp.$Cookie.remove('_userRememberMe_')
      global.instanceApp.$Cookie.remove('_proposal_last_search_')

      localStorage.removeItem('_userStoredFilters_')
      global.instanceApp.$Cookie.remove('_userStoredFilters_')
      resolve(true)
    })
  },
  passwordResetRequest (data, cb) {
    let json = { username: data.username }

    return new Promise(function (resolve, reject) {
      Http.post(urlAPI + 'v2/password/reset/request', json).then(function (res) {
        if (res.data.hasOwnProperty('code') && res.data.code === 200) {
          let message = global.instanceApp.$t(res.data.message)

          // Replace message params (values)
          if (res.data.hasOwnProperty('params')) {
            for (var propertyName in res.data.params) {
              message = util.replaceAll(message, '%' + propertyName + '%', res.data.params[propertyName])
            }
          }

          global.instanceApp.$alertSwal.modalAlert('', message, 'warning', '', { showCancelButton: false, showConfirmButton: true, showCloseButton: false }).then((result) => {
            resolve(true)
          })
        } else {
          global.instanceApp.$alertSwal.modalAlert('', global.instanceApp.$t(res.data.message), 'warning')

          resolve(false)
        }
      }).catch(function (err) {
        global.instanceApp.$alertSwal.modalAlert('', global.instanceApp.$t(err.message), 'warning')
        resolve(false)
      })
    })
  },
  passwordReset (token, password) {
    let json = { token: token, password: password }

    return new Promise(function (resolve, reject) {
      Http.post(urlAPI + 'v2/password/reset', json).then(function (res) {
        if (res.data.hasOwnProperty('code') && res.data.code === 200) {
          let message = global.instanceApp.$t(res.data.message)

          // Replace message params (values)
          if (res.data.hasOwnProperty('params')) {
            for (var propertyName in res.data.params) {
              message = util.replaceAll(message, '%' + propertyName + '%', res.data.params[propertyName])
            }
          }

          global.instanceApp.$alertSwal.modalAlert('', message, 'success', '', { showCancelButton: false, showConfirmButton: true, showCloseButton: false }).then((result) => {
            resolve(true)
          })
        } else {
          global.instanceApp.$alertSwal.modalAlert('', global.instanceApp.$t(res.data.message), 'warning')

          resolve(false)
        }
      }).catch(function (err) {
        global.instanceApp.$alertSwal.modalAlert('', global.instanceApp.$t(err.message), 'warning').then(res => {
          resolve(false)
        })
      })
    })
  },
  getUserData () {
    return new Promise(function (resolve, reject) {
      Http.get(urlAPI + 'v2/user/info').then(function (res) {
        const authUser = {}
        // if (BaseService.validateResponseReturn(res)) {
        if (res.data.hasOwnProperty('token')) {
          authUser.userInfo = res.data.data
          global.instanceApp.$root.$store.dispatch('setAuthInfo', {
            userInfo: authUser.userInfo
          })

          if (global.instanceApp.$Cookie.get('_userRememberMe_')) {
            global.instanceApp.$Cookie.set('_userAuth_', res.data.token, { expires: 7 })
            global.instanceApp.$Cookie.set('_userRememberMe_', true, { expires: 365 })
          } else {
            global.instanceApp.$Cookie.set('_userAuth_', res.data.token)
          }

          if (!global.instanceApp.$Cookie.get('_userStoredFilters_') && global.instanceApp.$Cookie.get('_consent_') !== 1) {
            global.instanceApp.$Cookie.set('_userStoredFilters_', global.instanceApp.$root.$store.getters.aplliedFilters)
          }
          resolve(authUser.userInfo)
        } else {
          global.instanceApp.$alertSwal.modalAlert('Erro', global.instanceApp.$t(res.data.message), 'warning')
          resolve(false)
        }
      }).catch(function (err) {
        if (!err.message) {
          global.instanceApp.$alertSwal.modalAlert('Aviso', 'Usuário não identificado, faça login novamente', 'error', '', { showCancelButton: false, showConfirmButton: true, showCloseButton: false }).then((result) => {
            Cookies.remove('_userAuth_')
            Cookies.remove('_userRememberMe_')
            store.dispatch('SetAuthUser', {
              authUser: null
            })
            global.instanceApp.$router.push({ name: 'Login' })
          })
        } else if (err.message.toLowerCase() === 'expired jwt token' || err.message.toLowerCase() === 'invalid jwt token') {
          global.instanceApp.$alertSwal.modalAlert('Aviso', 'A sua sessão expirou. Faça login novamente!', 'error', '', { showCancelButton: false, showConfirmButton: true, showCloseButton: false }).then((result) => {
            Cookies.remove('_userAuth_')
            Cookies.remove('_userRememberMe_')
            store.dispatch('SetAuthUser', {
              authUser: null
            })
            global.instanceApp.$router.push({ name: 'Login' })
          })
        } else {
          global.instanceApp.$alertSwal.modalAlert('Erro', global.instanceApp.$t(err.message), 'error')
        }
        return false
      })
    })
  }
}

export default loginService
