/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor manutabilidade **/
import i18n from '@/i18n'
import BlueTopLayout from '@/views/_shared/_BlueTopLayout'

const BookingRouter = {
  path: '/booking',
  component: BlueTopLayout,
  children: [
    {
      path: '/',
      name: 'BookingIndex',
      component: () => import('@/views/Booking/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.booking'), disabled: true }
        ]
      }
    },
    {
      path: 'new',
      name: 'BookingNew',
      component: () => import('@/views/Booking/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: ':id/create',
      name: 'BookingCreate',
      component: () => import('@/views/Booking/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.bookings'),
        breadcrumbs: [
          { text: i18n.t('actions.list'), disabled: false, href: 'BookingIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: ':id/view',
      name: 'BookingView',
      component: () => import('@/views/Booking/DetailFull'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'search',
      name: 'BookingSearch',
      component: () => import('@/views/Proposal/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingIndex' },
          { text: i18n.t('actions.search'), disabled: true }
        ]
      }
    },
    {
      path: 'request',
      name: 'BookingRequest',
      component: () => import('@/views/Booking/Request'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingIndex' },
          { text: i18n.t('actions.request'), disabled: true }
        ]
      }
    },
    {
      path: ':id/edit',
      name: 'BookingEdit',
      component: () => import('@/views/Booking/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: ':id/unsubscribe',
      name: 'BookingUnfollow',
      component: () => import('@/views/Booking/Unfollow'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'config',
      name: 'BookingConfig',
      component: () => import('@/views/Booking/Config'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: ':id/followers',
      name: 'BookingFollowers',
      component: () => import('@/views/Booking/Followers'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'contract',
      name: 'BookingContractIndex',
      component: () => import('@/views/Booking/Contracts/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking_contracts'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.booking'), disabled: true }
        ]
      }
    },
    {
      path: 'contract/new',
      name: 'BookingContractNew',
      component: () => import('@/views/Booking/Contracts/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.bookings'),
        breadcrumbs: [
          { text: i18n.t('actions.list'), disabled: false, href: 'BookingContractIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: 'contract/:id/view',
      name: 'BookingContractView',
      component: () => import('@/views/Booking/Contracts/Detail'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingContractIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'contract/:id/edit',
      name: 'BookingContractEdit',
      component: () => import('@/views/Booking/Contracts/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.bookings'),
        breadcrumbs: [
          { text: i18n.t('actions.list'), disabled: false, href: 'BookingContractIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: 'contract/group',
      name: 'BookingContractGroupIndex',
      component: () => import('@/views/Booking/Contracts/Group/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking_contracts'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.booking'), disabled: true }
        ]
      }
    },
    {
      path: 'contract/group/new',
      name: 'BookingContractGroupNew',
      component: () => import('@/views/Booking/Contracts/Group/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.bookings'),
        breadcrumbs: [
          { text: i18n.t('actions.list'), disabled: false, href: 'BookingContractGroupIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: 'contract/group/:id/view',
      name: 'BookingContractGroupView',
      component: () => import('@/views/Booking/Contracts/Group/Detail'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.booking'),
        breadcrumbs: [
          { text: i18n.t('sidebar.booking'), disabled: false, href: 'BookingContractGroupIndex' },
          { text: i18n.t('actions.view'), disabled: true }
        ]
      }
    },
    {
      path: 'contract/group/:id/edit',
      name: 'BookingContractGroupEdit',
      component: () => import('@/views/Booking/Contracts/Group/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.bookings'),
        breadcrumbs: [
          { text: i18n.t('actions.list'), disabled: false, href: 'BookingContractGroupIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default BookingRouter
