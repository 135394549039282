/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const IntegrationsRouter = {
  path: '/integrations',
  component: Layout,
  children: [
    // Webhooks
    {
      path: 'webhooks',
      name: 'WebhookIndex',
      component: () => import('@/views/Integrations/Webhooks/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('form.integrations.webhooks.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('form.integrations.title'), disabled: true },
          { text: i18n.t('form.integrations.webhooks.title'), disabled: true }
        ]
      }
    },
    // Webhooks Edit
    {
      path: 'webhooks/:id/edit',
      name: 'WebhooksEdit',
      component: () => import('@/views/Integrations/Webhooks/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('form.integrations.webhooks.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('form.integrations.title'), disabled: true },
          { text: i18n.t('form.integrations.webhooks.title'), disabled: true }
        ]
      }
    },
    // Webhooks New
    {
      path: 'webhooks/new',
      name: 'WebhooksNew',
      component: () => import('@/views/Integrations/Webhooks/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('form.integrations.webhooks.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('form.integrations.title'), disabled: true },
          { text: i18n.t('form.integrations.webhooks.title'), disabled: true }
        ]
      }
    },
    // Webhooks Log
    {
      path: 'webhooks/logs',
      name: 'WebhookLogsIndex',
      component: () => import('@/views/Integrations/Webhooks/Logs/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('form.integrations.webhooks.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('form.integrations.title'), disabled: true },
          { text: i18n.t('form.integrations.webhooks.title'), disabled: true }
        ]
      }
    },
    // Log
    {
      path: 'webhooks/:id/log',
      name: 'WebhooksLog',
      component: () => import('@/views/Integrations/Webhooks/Logs/Log'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('form.integrations.webhooks.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('form.integrations.title'), disabled: true },
          { text: i18n.t('form.integrations.webhooks.title'), disabled: true }
        ]
      }
    },
    // De Para
    {
      path: 'webhooks/de-para',
      name: 'WebhookDeParaIndex',
      component: () => import('@/views/Integrations/Webhooks/DePara/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('form.integrations.webhooks.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('form.integrations.title'), disabled: true },
          { text: i18n.t('form.integrations.webhooks.title'), disabled: true }
        ]
      }
    },
    {
      path: 'webhooks/:id/de-para',
      name: 'WebhookDeParaEdit',
      component: () => import('@/views/Integrations/Webhooks/DePara/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('form.integrations.webhooks.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('form.integrations.title'), disabled: true },
          { text: i18n.t('form.integrations.webhooks.title'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default IntegrationsRouter
