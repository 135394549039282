<template>
  <div class="page-wrapper" :class="{'isDemo': showDemoSection}">
    <div class="top-alert-bar" v-if="showDemoSection">
      <div class="alert alert-warning text-center" role="alert">
        <i class="fa fa-info-circle"></i>
        {{ $t('Atenção! Este é um ambiente de demonstração') }}
      </div>
    </div>
    <!-- <router-render :key="key" /> -->
    <router-view :key="key"></router-view>
    <modals-container/>
  </div>
</template>

<script>
// import RouterRender from '@/views/_shared/_RouterRender.vue'

import '@fortawesome/fontawesome-free/css/all.min.css'
// import '@/assets/css/fonts/stylesheet.css'
import '@/assets/vendor/icons/icons-style.css'
import '@/assets/vendor/line-awesome/css/line-awesome.min.css'
import '@/assets/css/app.css'
import '@/assets/css/pages/timeline.css'
import '@/assets/css/royal-cargo.css'

export default {
  name: 'LoginLayout',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Portal - Royal Cargo do Brasil'
  },
  data () {
    return {
    }
  },
  components: {
    // RouterRender
  },
  computed: {
    key () {
      return Math.random().toString().substr(2)
    },
    showDemoSection () {
      return process.env.VUE_APP_DEMO || false
    }
  }
}
</script>
