/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import Layout from '@/views/_shared/_Layout'

const RegisterRouter = {
  path: '/register',
  component: Layout,
  children: [
    {
      path: 'request-access/validation/:token/:action?',
      name: 'RequestAccessValidation',
      component: function (resolve) { require(['@/views/User/AccessRequest/RequestAccessValidation.vue'], resolve) },
      hidden: true
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default RegisterRouter
