<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <a class="nav-link dropdown-toggle navbar-icon" data-toggle="dropdown" href="#">
      <i class="ft-bell position-relative"></i>
      <span v-if="messages.length > 0 && totalUnread > 0 " class="notify-signal bg-danger"></span>
    </a>
    <div class="dropdown-menu dropdown-menu-right pt-0 pb-0">
      <div class="py-4 px-3 text-center text-white mb-1 dropdown-title" >
        <h5 v-if="messages.length > 0" class="m-0" v-html="showTitle()" ></h5>
        <h5 v-else class="m-0">Nenhuma Mensagem</h5>
      </div>
      <div v-if="messages.length > 0" class="custom-scroll position-relative mb-3">
        <div class="list-group list-group-flush list-messages" style="max-height:320px;">
          <!-- :to="{name: 'ProcessList', params: { process: msgAlert.numero_processo }}" -->
          <a v-for="(msgAlert, index) in messages" :key="index"  v-on:click.stop="markkRead(msgAlert.id,msgAlert)" class="list-group-item list-group-item-action alert-message px-4 py-3">
            <div class="media align-items-center" v-bind:class="{'read': (msgAlert.date_read != null)}">
              <i class="text-center font-30 text-primary mr-3 fa-check-circle" v-bind:class="getIconClass(msgAlert)"  style="width: 40px"></i>
              <div class="media-body">
                <div class="flexbox">
                  <h6 class="mb-0 font-weight-bold">{{ $t('alert_messages.title.'+msgAlert.title) }}</h6>
                  <!--<div class="text-muted font-13">{{ alertDate(msgAlert.date_create) }}</div>-->
                </div>
                <div class="message-content mt-2" v-html="showMessage(msgAlert)" ></div>
              </div>
            </div>
          </a>
        </div>
        <div class="mark-all">
          <router-link :to="{name: 'NotificationIndex'}">
            Ver todos
          </router-link>
<!--          <a href="javascript:;" @click="markAllRead()">{{ $t('notifications.mark_all_as_read') }}</a>-->
        </div>
      </div>
      <!--<div v-if="messages.length > 0" class="px-3 py-1 text-center">-->
        <!--<a class="hover-link font-13" href="javascript:;">{{ $t('actions.all') }}</a>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
import MessageAlertsService from '@/services/MessageAlertsService'
import AnnouncementService from '@/services/AnnouncementService'
import AnnouncementView from '@/views/Announcement/View.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'MessageAlerts',
  data () {
    return {
      isLoading: false,
      fullPage: true,
      messages: [],
      totalUnread: 0,
      totalUnreadAnnouncements: 0
    }
  },
  components: {
    Loading
  },
  mounted () {
    if (this.user) {
      this.getUserMessageAlerts()
    }
  },
  methods: {
    showAnnouncement () {
      let me = this
      AnnouncementService.getMostRecentAnnouncement().then(res => {
        me.$store.dispatch('setModalData', res.data)
        me.$modal.show(AnnouncementView, {}, {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        })
      })

      me.schedulerMessages()
    },
    getIconClass (message) {
      let classIcon

      if (message.alert_status === 1 || message.alert_status === 7) {
        classIcon = {
          'ft-check-circle': true
        }
      } else if (message.alert_status === 2 || message.alert_status === 3) {
        classIcon = {
          'fas fa-calendar': true
        }
      } else if (message.alert_status === 4 || message.alert_status === 5) {
        classIcon = {
          'ft-map-pin': true
        }
      } else if (message.alert_status === 6) {
        classIcon = {
          'rc-Ativo-38': true
        }
      }

      return classIcon
    },
    inArray (needle, haystack) {
      var length = haystack.length
      for (let i = 0; i < length; i++) {
        if (haystack[i] === needle) {
          return true
        }
      }
      return false
    },
    getUserMessageAlerts () {
      if (process.env.NODE_ENV !== 'developments') {
        let me = this

        if (this.$store.getters.userInfo) {
          MessageAlertsService.getUserMessageAlerts(this.$store.getters.userInfo.id).then(res => {
            me.messages = res.data.data
            me.totalUnread = res.data.info.unread_messages
            me.totalUnreadAnnouncements = res.data.info.unread_announcements
            me.schedulerMessages()
          })
        }
      }
    },
    schedulerMessages (timeInterval) {
      timeInterval = timeInterval || 120000
      let me = this
      setTimeout(function () {
        me.getUserMessageAlerts()
      }, timeInterval)
    },
    alertDate (dateTime) {
      let date = new Date(dateTime)
      let locale = process.env.VUE_APP_locale
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }
      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    showTitle () {
      let message = ''

      if (this.totalUnread === 0) {
        message = 'notifications.total_item_no_message'
      } else if (this.totalUnread === 1) {
        message = 'notifications.total_item_message'
      } else {
        message = 'notifications.total_itens_message'
      }

      message = this.$i18n.t(message)

      return message.replace('%totalNotifications%', this.totalUnread)
    },
    showMessage (message) {
      let translatedMessage = this.$i18n.t('alert_messages.message.' + message.message)

      if (message.args) {
        try {
          let args = JSON.parse(message.args)
          let date = new Date(args.event_date.date)

          // TODO: Change do args iterated in a for function
          translatedMessage = translatedMessage.replace('%indicator%', args.indicator)
          translatedMessage = translatedMessage.replace('%date%', this.alertDate(date))
          if (args.name) {
            translatedMessage = translatedMessage.replace('%name%', args.name)
          }
        } catch (e) {
          // return false;
        }
      }
      return translatedMessage
    },
    markkRead (idMessage, msgAlert) {
      this.isLoading = true
      let me = this
      MessageAlertsService.setMessageRead(idMessage, this.$store.getters.userInfo.id).then(res => {
        me.isLoading = false
        me.messages = res.data.data
        me.totalUnread = res.data.info.unread_messages
        me.schedulerMessages()
        me.$router.push({ path: `/processdetail?${msgAlert.id_process}` })
      }).finally(() => {
        me.isLoading = false
      })
    },
    markAllRead () {
      let me = this
      this.$alertSwal.modalConfirm('', 'Esta ação irá marcar todos os alertac omo lido, está certo disso?', 'warning', 'Sim', 'Não', (e) => MessageAlertsService.setAllMessagesRead(this.$store.getters.userInfo.id)).then((res) => {
        if (res.value) {
          // this.$alertSwal.toast('Registro Deletado com sucesso!')
          me.messages = res.data.data
          me.totalUnread = res.data.info.unread_messages
          me.schedulerMessages()
        }
      })
      // MessageAlertsService.setAllMessagesRead(this.$store.getters.userInfo.id).then(res => {
      //   me.messages = res.data.data
      //   me.totalUnread = res.data.info.unread_messages
      //   me.schedulerMessages()
      // })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    }
  },
  watch: {
    user: function () {
      this.getUserMessageAlerts()
    },
    totalUnreadAnnouncements: function (value, oldValue) {
      if (value > 0) {
        this.showAnnouncement()
      }
    }
  }
}
</script>

<style scoped>
  /* Title */
  .alert-message .media .media-body .flexbox h6 {
    font-size: 0.88rem;
  }

  .alert-message .media .media-body .message-content {
    font-size: 0.86rem;
    font-family: "Pluto Sans Medium";
  }

  .alert-message .media.read .media-body .message-content {
    font-size: 0.80rem;
    font-family: "Pluto Sans";
  }

  .list-messages {
    max-height: 350px;
    overflow-y: auto;
  }

  .mark-all {
    text-align: center;
    margin: 10px;
    font-size: 0.86rem;
    font-family: "Pluto Sans Medium";
  }
  .mark-all > a {
    color: #877953;
  }
  .mark-all > a:hover, .mark-all > a:active {
    font-weight: 600;
  }

</style>
