<template>
  <ol class="breadcrumb" v-if="items" :key="key">
    <li class="breadcrumb-item" v-if="showBreadcrumbHome" >
      <router-link :to="mountLink('Dashboard')"><i class="la la-home font-20"></i></router-link>
    </li>
    <li v-for="(item, index) in items" :key="index" class="breadcrumb-item">
      <router-link :to="mountLink(item.href)" v-if="!item.disabled">{{ item.text }}</router-link>
      <span v-else :class="{'v-breadcrumbs__item--disabled': true}">{{ item.text }}</span>
    </li>
  </ol>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  data () {
    return {
    }
  },
  props: {
    items: {
      type: Array,
      default: null
    },
    showBreadcrumbHome: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    mountLink (itemName) {
      return this.$router.resolve({ name: itemName }).href
    }
  },
  computed: {
    key () {
      return Math.random().toString().substr(2)
    }
  }
}
</script>
