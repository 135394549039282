import dayjs from 'dayjs'
import Cookies from 'js-cookie'

// const userStoredFilters = Cookies.get('_userStoredFilters_') || null

export const Filters = {
  state: {
    showFilter: false,
    showSearch: false,
    filtersNotEmpty: [
      'situacaoAbertos',
      'situacaoEmbarcados',
      'filPeriodoDtInicio',
      'filPeriodoDtFim'
    ],
    filtersFieldsDate: [
      'filPeriodoDtInicio',
      'filPeriodoDtFim',
      'filPeriodoEmbarqueDtInicio',
      'filPeriodoEmbarqueDtFim',
      'filPeriodoDesembarqueDtInicio',
      'filPeriodoDesembarqueDtFim',
      'filPeriodoDtInicioInvoice',
      'filPeriodoDtFimInvoice',
      'filEtdInicio',
      'filEtdFim',
      'filDdlInicio',
      'filDdlFim'
    ],
    filtersFieldsCheck: [
      'filTipoRequisicaoPortal',
      'filTipoRequisicaoAPI',
      'filTipoOperacaoModalImportacao',
      'filTipoOperacaoModalExportacao',
      'filTipoOperacaoModalNacional',
      'filTipoOperacaoModalMaritimo',
      'filTipoOperacaoModalAereo',
      'filTipoOperacaoModalTerrestre',
      'situacaoAbertos',
      'situacaoEmbarcados',
      'filTypeCargoTeus',
      'filTypeCargoTONS'
    ],
    filtersFieldsMultiple: [
      'filTipoEquipamento',
      'filEquipamento'
    ],
    filtersFieldsObject: [
      'filCliente',
      'filClienteNaoFiltrado',
      'filAgenteOrigem',
      'filEmployees',
      'filUsers',
      'filGoalSection'
    ],
    refreshFilter: false,
    aplliedFilters: {
      // hasFilter: true,
      filProcesso: null,
      filEmployee: [],
      filEmployees: [],
      filUsers: [],
      filGoalSection: null,
      filYear: null,
      filMonth: null,
      filWeek: null,
      filQuarter: null,
      filReferenciaCliente: null,
      filCliente: [],
      filClienteNaoFiltrado: [],
      filAgenteOrigem: [],
      filHBL: null,
      // Embarque
      filPeriodoEmbarqueDtInicio: null,
      filPeriodoEmbarqueDtFim: null,
      // Desembarque
      filPeriodoDesembarqueDtInicio: null,
      filPeriodoDesembarqueDtFim: null,
      // weekly, monthly, yearly
      filPeriodo: 'monthly',
      // Operação
      filTipoOperacaoModalExportacao: 1,
      filTipoOperacaoModalImportacao: 2,
      filTipoOperacaoModalNacional: 3,
      // Tipo Requisição
      filTipoRequisicaoAPI: 2,
      filTipoRequisicaoPortal: 1,
      // Modal
      filTipoOperacaoModalAereo: 1,
      filTipoOperacaoModalMaritimo: 2,
      filTipoOperacaoModalTerrestre: 3,
      // Situação
      situacaoAbertos: null,
      situacaoEmbarcados: 2,
      // Tipo de Carga
      filTypeCargoTeus: 1,
      filTypeCargoTons: null,
      //
      filPeriodoDtInicio: (dayjs().add(-1, 'year').month(0).date(1).hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss'),
      filPeriodoDtFim: dayjs().date(dayjs().daysInMonth()).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'),
      //
      filPeriodoDtInicioInvoice: (dayjs().add(-1, 'week').month(0).date(1).hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss'),
      filPeriodoDtFimInvoice: dayjs().date(dayjs().daysInMonth()).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'),
      // ETD
      filEtdInicio: (dayjs().hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss'),
      filEtdFim: dayjs().add(1, 'month').endOf('month').hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'),
      // DDL
      filDdlInicio: (dayjs().hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss'),
      filDdlFim: dayjs().add(1, 'month').endOf('month').hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'),
      // Origem e destino
      filOrigem: null,
      filDestino: null,
      filTipoEquipamento: null,
      filEquipamento: [],
      filDisparadorNome: null,
      filDisparadorTipo: null
    }
  },
  mutations: {
    SET_SHOW_FILTER: (state, value) => {
      state.showFilter = value
    },
    SET_SHOW_SEARCH: (state, value) => {
      state.showSearch = value
    },
    SET_APPLIED_FILTER: (state, value) => {
      state.aplliedFilters = value
    },
    RESET_APPLIED_FILTER: (state, value) => {
      state.aplliedFilters = value
    },
    SET_RESET_FILTER: (state, value) => {
      state.refreshFilter = value
    }
  },
  actions: {
    SetShowFilter ({ commit }, data) {
      commit('SET_SHOW_FILTER', data)
    },
    setShowSearch ({ commit }, data) {
      commit('SET_SHOW_SEARCH', data)
    },
    setAppliedFilter ({ commit }, data) {
      commit('SET_APPLIED_FILTER', data)
      if (global.instanceApp.$Cookie.get('_consent_') !== 1) {
        Cookies.set('_userStoredFilters_', data)
      }
    },
    resetAppliedFilters ({ commit }, data) {
      let filterResetd = {
        aplliedFilters: {
          // hasFilter: false,
          filProcesso: null,
          filEmployee: [],
          filUsers: [],
          filGoalSection: null,
          filYear: null,
          filMonth: null,
          filWeek: null,
          filQuarter: null,
          filReferenciaCliente: null,
          filCliente: [],
          filClienteNaoFiltrado: [],
          filAgenteOrigem: [],
          filHBL: null,
          filPeriodoEmbarqueDtInicio: null,
          filPeriodoEmbarqueDtFim: null,
          filPeriodoDesembarqueDtInicio: null,
          filPeriodoDesembarqueDtFim: null,
          // weekly, monthly, yearly
          filPeriodo: 'monthly',
          // Tipo Requisição
          filTipoRequisicaoAPI: 2,
          filTipoRequisicaoPortal: 1,
          // Operação
          filTipoOperacaoModalExportacao: 1,
          filTipoOperacaoModalImportacao: 2,
          filTipoOperacaoModalNacional: 3,
          // Modal
          filTipoOperacaoModalAereo: 1,
          filTipoOperacaoModalMaritimo: 2,
          filTipoOperacaoModalTerrestre: 3,
          // Situação
          situacaoAbertos: null,
          situacaoEmbarcados: 2,
          // Tipo de Carga
          filTypeCargoTeus: 1,
          filTypeCargoTons: null,
          // Período
          filPeriodoDtInicio: (dayjs().add(-1, 'year').date(1).hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss'),
          filPeriodoDtFim: dayjs().date(dayjs().daysInMonth()).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'),
          // Período Invoice
          filPeriodoDtInicioInvoice: (dayjs().add(-1, 'week').month(0).date(1).hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss'),
          filPeriodoDtFimInvoice: dayjs().date(dayjs().daysInMonth()).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'),
          // ETD
          filEtdInicio: (dayjs().hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss'),
          filEtdFim: dayjs().add(1, 'month').endOf('month').hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'),
          // DDL
          filDdlInicio: (dayjs().hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss'),
          filDdlFim: dayjs().add(1, 'month').endOf('month').hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'),
          // Origem e destino
          filOrigem: null,
          filDestino: null,
          filTipoEquipamento: null,
          filEquipamento: null,
          filDisparadorNome: null,
          filDisparadorTipo: null
        }
      }
      commit('RESET_APPLIED_FILTER', filterResetd)
    },
    setRefreshFilter ({ commit }, data) {
      commit('SET_RESET_FILTER', data)
    },
    getShowFilter (state) {
      return state.showFilter
    },
    getShowSearch (state) {
      return state.showSearch
    },
    getAppliedFilters (state) {
      if (!Cookies.get('_userStoredFilters_') && global.instanceApp.$Cookie.get('_consent_') !== 1) {
        Cookies.set('_userStoredFilters_', state.aplliedFilters)
      }
      return Cookies.get('_userStoredFilters_')
    },
    getRefreshFilter (state) {
      return state.aplliedFilters
    }
  }
}

export default Filters
