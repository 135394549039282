/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const ReportRouter = {
  path: '/report/monitoring',
  component: Layout,
  children: [
    //  Begin Alerts
    {
      path: 'processes-without-answerables',
      name: 'ReportAlertsMonitoringProcessesWithoutAnswerables',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckAnswerable/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-files',
      name: 'ReportAlertsMonitoringProcessesCheckFiles',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckFiles/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-cargo-values',
      name: 'ReportAlertsMonitoringProcessesCheckCargoValues',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckCargoValues/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-insurance',
      name: 'ReportAlertsMonitoringProcessesCheckInsurance',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckInsurance/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-sell',
      name: 'ReportAlertsMonitoringProcessesCheckSell',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckSell/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-debit-note',
      name: 'ReportAlertsMonitoringProcessesCheckDebitNote',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckDebitNote/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-air-info',
      name: 'ReportAlertsMonitoringProcessesCheckAirInfo',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckAirInfo/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-fcl-contract',
      name: 'ReportAlertsMonitoringProcessesCheckFclContract',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckFclContract/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-exchange-value',
      name: 'ReportAlertsMonitoringProcessesCheckExchangeValue',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckExchangeValue/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-dta-taxes',
      name: 'ReportAlertsMonitoringProcessesCheckDtaTaxes',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckDtaTaxes/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), active: true },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), href: 'ReportAlertsMonitoringProcessesCheckCollectFee', active: false }
        ]
      }
    },
    {
      path: 'processes-check-collect-fee',
      name: 'ReportAlertsMonitoringProcessesCheckCollectFee',
      component: () => import('@/views/Report/Alerts/MonitoringProcessesCheckCollectFee/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesWithoutAnswerables'), href: 'ReportAlertsMonitoringProcessesWithoutAnswerables', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFiles'), href: 'ReportAlertsMonitoringProcessesCheckFiles', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCargoValues'), href: 'ReportAlertsMonitoringProcessesCheckCargoValues', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckInsurance'), href: 'ReportAlertsMonitoringProcessesCheckInsurance', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckSell'), href: 'ReportAlertsMonitoringProcessesCheckSell', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDebitNote'), href: 'ReportAlertsMonitoringProcessesCheckDebitNote', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckAirInfo'), href: 'ReportAlertsMonitoringProcessesCheckAirInfo', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckFclContract'), href: 'ReportAlertsMonitoringProcessesCheckFclContract', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckExchangeValue'), href: 'ReportAlertsMonitoringProcessesCheckExchangeValue', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckDtaTaxes'), href: 'ReportAlertsMonitoringProcessesCheckDtaTaxes', active: false },
          { text: i18n.t('report.alertsMonitoring.ReportAlertsMonitoringProcessesCheckCollectFee'), active: true }
        ]
      }
    }
    //  End Alerts
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}

export default ReportRouter
