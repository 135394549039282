import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const AlertPlugin = {
  showLoading (strMensagem) {
    strMensagem = strMensagem || 'Loading'

    var strProgressHTML = '<div class="rc-preloader-backdrop">' +
      ' <div class="rc-page-preloader">' + global.instanceApp.$t(strMensagem) + '</div>' +
      '</div>'

    Swal.fire({
      title: '',
      background: 'transparent',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      html: strProgressHTML,
      backdrop: `
        rgba(255, 255, 255 , 1)
      `
    })
  },
  hideLoading () {
    Swal.close()
  },
  modalAlert: (title, message, type, customClass, optionsButtons) => {
    optionsButtons = optionsButtons || { showConfirmButton: true }
    optionsButtons.showCancelButton = optionsButtons.showCancelButton || false
    optionsButtons.showConfirmButton = optionsButtons.showConfirmButton || false
    optionsButtons.showCloseButton = optionsButtons.showCloseButton || false
    optionsButtons.confirmButtonText = optionsButtons.confirmButtonText || 'OK'
    optionsButtons.cancelButtonText = optionsButtons.cancelButtonText || 'Cancel'

    return Swal.fire({
      title: title,
      html: message,
      type: type || null,
      customClass: customClass,
      showCancelButton: optionsButtons.showCancelButton,
      showConfirmButton: optionsButtons.showConfirmButton,
      showCloseButton: optionsButtons.showCloseButton,
      confirmButtonText: optionsButtons.confirmButtonText,
      cancelButtonText: optionsButtons.cancelButtonText
    })
  },
  modalConfirm: (title, message, type, okButton, cancelButton, preConfirmFunc) =>
    Swal.fire({
      title: title,
      html: message,
      type: type || 'question',
      showCancelButton: true,
      confirmButtonText: okButton || 'Sim',
      cancelButtonText: cancelButton || 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: !!preConfirmFunc,
      preConfirm: preConfirmFunc
    }),
  toast: (msg, type) => {
    const toast = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 6000
    })
    toast.fire({
      type: type === undefined ? 'success' : type,
      title: msg
    })
  },
  modalConfirm2 (title, message, type, okButton, cancelButton, preConfirmFunc) {
    // let me = preConfirmFunc
    Swal.fire({
      title: title,
      text: message,
      type: type || 'warning',
      showCancelButton: true,
      confirmButtonText: okButton || 'Sim',
      cancelButtonText: cancelButton || 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: preConfirmFunc
    }).then((result) => {
      // codigo
    })
  },
  close () {
    Swal.close()
  }
}

export default {
  install (Vue, options) {
    Vue.prototype.$alertSwal = AlertPlugin
  }
}
