/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import Layout from '@/views/_shared/_Layout'

const FinantialRouter = {
  path: '/kpi/finantial/',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'FinantialIndex',
      component: () => import('@/views/Kpi/Finantial/Index'),
      hidden: true,
      meta: {
        titleHeader: false,
        titleText: 'KPI | Financeiro'
      }
    },
    {
      path: 'invoices',
      name: 'FinantialInvoices',
      component: () => import('@/views/Kpi/Finantial/Invoices'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'Financeiro'
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default FinantialRouter
