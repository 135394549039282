<template>
  <div class="card no-bottom-margin mb-3" v-if="items">
    <div class="section-sub-menu">
      <ul class="nav line-tabs borderless flex-center" :key="key">
        <li v-for="(item, index) in items" :key="index" class="nav-item">
          <router-link :to="mountLink(item.href)" :class="{'active': item.active, 'nav-link':true}">
            {{ item.text }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionMenu',
  data () {
    return {
    }
  },
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  methods: {
    mountLink (itemName) {
      return this.$router.resolve({ name: itemName }).href
    }
  },
  computed: {
    key () {
      return Math.random().toString().substr(2)
    }
  }
}
</script>
