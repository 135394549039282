/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const AnnouncementRouter = {
  path: '/announcement',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'AnnouncementIndex',
      component: () => import('@/views/Announcement/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.announcement'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('sidebar.announcement'), disabled: true }
        ]
      }
    },
    {
      path: 'new',
      name: 'AnnouncementNew',
      component: () => import('@/views/Announcement/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.announcement'),
        breadcrumbs: [
          { text: i18n.t('sidebar.announcement'), disabled: false, href: 'AnnouncementIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: ':id/edit',
      name: 'AnnouncementEdit',
      component: () => import('@/views/Announcement/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('sidebar.announcement'),
        breadcrumbs: [
          { text: i18n.t('sidebar.announcement'), disabled: false, href: 'AnnouncementIndex' },
          { text: i18n.t('actions.edit'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default AnnouncementRouter
