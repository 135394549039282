
export const Modals = {
  state: {
    modalData: null
  },
  mutations: {
    SET_MODAL_DATA: (state, value) => {
      state.modalData = value
    },
    SET_RESET_MODAL_DATA: (state) => {
      state.modalData = null
    }
  },
  actions: {
    resetModalData ({ commit }) {
      commit('SET_RESET_MODAL_DATA')
    },
    setModalData ({ commit }, data) {
      commit('SET_MODAL_DATA', data)
    }
  }
}

export default Modals
