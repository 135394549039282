/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const OportunidadesRouter = {
  path: '/oportunidades',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'OportunidadesIndex',
      component: () => import('@/views/Tools/Oportunidade/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('notifications.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'new',
      name: 'OportinidadeNew',
      component: () => import('@/views/Tools/Oportunidade/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('oportunidades.title'),
        breadcrumbs: [
          { text: i18n.t('oportunidades.title'), disabled: false, href: 'OportunidadesIndex' },
          { text: i18n.t('actions.new'), disabled: true }
        ]
      }
    },
    {
      path: ':id/edit',
      name: 'OportunidadeEdit',
      component: () => import('@/views/Tools/Oportunidade/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('oportunidades.title'),
        breadcrumbs: [
          { text: i18n.t('oportunidades.title'), disabled: false, href: 'OportunidadesIndex' },
          { text: i18n.t('actions.edit'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default OportunidadesRouter
