/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Layout'

const ReportProcessControlRouter = {
  path: '/report/process-control/',
  component: Layout,
  children: [
    //  Begin Alerts
    {
      path: 'processes-without-dispatch',
      name: 'ReportAlertsProcessControlProcessesWithoutDispatch',
      component: () => import('@/views/Report/Alerts/ProcessControl/ProcessesWithoutDispatch/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesWithoutDispatch'), active: true },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesWithoutReservationNumber'), href: 'ReportAlertsProcessControlProcessesWithoutReservationNumber', active: false },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesReadyWithoutReservationNumber'), href: 'ReportAlertsProcessControlProcessesReadyWithoutReservationNumber', active: false },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesDelayed'), href: 'ReportAlertsProcessControlProcessesDelayed', active: false }
        ]
      }
    },
    {
      path: 'processes-without-reservation-number',
      name: 'ReportAlertsProcessControlProcessesWithoutReservationNumber',
      component: () => import('@/views/Report/Alerts/ProcessControl/ProcessesWithoutReservationNumber/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesWithoutDispatch'), href: 'ReportAlertsProcessControlProcessesWithoutDispatch', active: false },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesWithoutReservationNumber'), active: true },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesReadyWithoutReservationNumber'), href: 'ReportAlertsProcessControlProcessesReadyWithoutReservationNumber', active: false },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesDelayed'), href: 'ReportAlertsProcessControlProcessesDelayed', active: false }
        ]
      }
    },
    {
      path: 'processes-ready-without-reservation-number',
      name: 'ReportAlertsProcessControlProcessesReadyWithoutReservationNumber',
      component: () => import('@/views/Report/Alerts/ProcessControl/ProcessesReadyWithoutReservationNumber/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesWithoutDispatch'), href: 'ReportAlertsProcessControlProcessesWithoutDispatch', active: false },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesWithoutReservationNumber'), href: 'ReportAlertsProcessControlProcessesWithoutReservationNumber', active: false },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesReadyWithoutReservationNumber'), active: true },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesDelayed'), href: 'ReportAlertsProcessControlProcessesDelayed', active: false }
        ]
      }
    },
    {
      path: 'processes-delayed',
      name: 'ReportAlertsProcessControlProcessesDelayed',
      component: () => import('@/views/Report/Alerts/ProcessControl/ProcessesDelayed/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('report.alerts.title'),
        breadcrumbs: [
          { text: i18n.t('report.alerts.title'), disabled: true }
        ],
        sectionMenu: [
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesWithoutDispatch'), href: 'ReportAlertsProcessControlProcessesWithoutDispatch', active: false },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesWithoutReservationNumber'), href: 'ReportAlertsProcessControlProcessesWithoutReservationNumber', active: false },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesReadyWithoutReservationNumber'), href: 'ReportAlertsProcessControlProcessesReadyWithoutReservationNumber', active: false },
          { text: i18n.t('report.alertsProcessControl.ReportAlertsProcessControlProcessesDelayed'), active: true }
        ]
      }
    }
    //  End Alerts
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}

export default ReportProcessControlRouter
