<template>
  <!-- START: Footer-->
  <footer class="page-footer text-center">
    <div>{{ new Date().getFullYear() }} &copy; <strong>Royal Cargo do Brasil</strong>. Todos os direitos reservados</div>

    <div class="whatsapp-fixed">
      <a v-if="isMobile" class="desktop" target="_blank" href="https://wa.me/5547991696700">
        <img src="/static/images/whatsapp-short.png" alt="whatsapp-icon">
      </a>
      <a v-else class="desktop" target="_blank"
        href="https://web.whatsapp.com/send?phone=5547991696700">
        <img src="/static/images/whatsapp-short.png" alt="whatsapp-icon">
      </a>
    </div>
  </footer>
  <!-- END: Footer-->
</template>

<script>
export default {
  name: 'PageFooter',
  data () {
    return {
      dialog: false
    }
  },
  methods: {
  },
  computed: {
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
