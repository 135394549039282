/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import Layout from '@/views/_shared/_Layout'

const OperationalRouter = {
  path: '/kpi/operational/',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'OperationalIndex',
      component: () => import('@/views/Kpi/Operational/Index'),
      hidden: true,
      meta: {
        titleHeader: false,
        titleText: 'KPI | Operacional'
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default OperationalRouter
