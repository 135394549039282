/** Caso a Rota seja muito grande é melhor separar em pequenos arquivos para uma melhor matunabilidade **/
import i18n from '@/i18n'
import Layout from '@/views/_shared/_Title_Layout'

const IndexRouter = {
  path: '/index',
  component: Layout,
  children: [
    // View Indices
    {
      path: '/',
      name: 'IndexList',
      component: () => import('@/views/Index/Index/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: 'Indices'
      }
    },
    // De Para
    {
      path: 'de-para',
      name: 'IndexDeParaIndex',
      component: () => import('@/views/Index/DePara/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('index.de-para.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          // { text: i18n.t('basic.tier.title'), disabled: true }
        ]
      }
    },
    {
      path: 'de-para/:id/edit',
      name: 'IndexDeParaEdit',
      component: () => import('@/views/Index/DePara/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.dePara.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('basic.dePara.title'), disabled: false, href: 'IndexDeParaIndex' }
        ]
      }
    },
    {
      path: 'de-para/new',
      name: 'IndexDeParaNew',
      component: () => import('@/views/Index/DePara/form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('basic.dePara.title'),
        breadcrumbs: [
          { text: i18n.t('basic.dePara.title'), disabled: true }
        ]
      }
    },
    // Importação
    {
      path: 'import',
      name: 'IndexImportIndex',
      component: () => import('@/views/Index/Import/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('index.import.title'),
        breadcrumbs: []
      }
    },
    {
      path: 'import/new',
      name: 'IndexImportNew',
      component: () => import('@/views/Index/Import/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('index.import.title'),
        breadcrumbs: []
      }
    },
    // Tier Index
    {
      path: 'tier-index',
      name: 'IndexTierIndex',
      component: () => import('@/views/Index/Tier/List'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('index.tier.title'),
        breadcrumbs: []
      }
    },
    {
      path: 'tier-index/:id/edit',
      name: 'TierIndexEdit',
      component: () => import('@/views/Index/Tier/Form'),
      hidden: true,
      meta: {
        titleHeader: true,
        titleText: i18n.t('index.tier.title'),
        breadcrumbs: [
          // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },
          { text: i18n.t('index.tier.title'), disabled: true }
        ]
      }
    }
  ],
  hidden: true,
  meta: {
    roles: ['admin']
  }
}
export default IndexRouter
